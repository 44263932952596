import Axios from "axios";
import { backendUrl } from "../utils";

let getAllUsersApi = "/dashboard/listUsers";
let createUserApi = "/dashboard/user";
let getsingleUserApi = "/dashboard/user";
let updateUserApi = "/dashboard/user";
let deleteUserApi = "/dashboard/user";
let blockUserApi = "/dashboard/user";
let helpApi = "/api/contact_us/";
let notification = "/api/update_app_notification/";
let getUserApi = '/dashboard/notification_users'


const UsersApi = {
  getAllUsersList(params, token) {
    // const { token } = useContext(AuthContext);
    return Axios({
      url: backendUrl + getAllUsersApi + params,
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  deleteUser(params, token) {
    return Axios({
      url: backendUrl + deleteUserApi + params,
      method: "delete",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  getUsersList(params, token) {
    return Axios({
      url: backendUrl + getUserApi + params,
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  getSingleUser(params, token) {
    return Axios({
      url: backendUrl + getsingleUserApi + params,
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  UpdateUser(id, params, token) {
    return Axios({
      url: backendUrl + updateUserApi + id,
      method: "put",
      data: params,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  sendNotification(params, token) {
    return Axios({
      url: backendUrl + notification,
      method: "post",
      data: params,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },



  BlockUser(id, params, token) {
    return Axios({
      url: backendUrl + blockUserApi + id,
      method: "patch",
      data: params,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  CreateUser(params, token) {
    return Axios({
      url: backendUrl + createUserApi,
      method: "post",
      data: params,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },

  helpSupport(params) {
    return Axios({
      url: backendUrl + helpApi,
      method: "post",
      data: params,
      headers: {
        "content-type": "application/json",
        // Authorization: `token ${token}`,
      },
    }).then((response) => {
      return response.data;
    });
  },
};

export default UsersApi;
