import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import InformationPage from './Pages/InformationPage';
import AccountsPage from './Pages/AccountsPage';
import UserPage from './Pages/InformationPage/UsersPage';
import CouponsPage from './Pages/InformationPage/CouponsPage';
import GiftsPage from './Pages/AccountsPage/GiftPage';
import PaymentsPage from './Pages/AccountsPage/PaymentsPage';
import AffliatesPage from './Pages/AccountsPage/AffilatesPage';
import AdsPage from './Pages/AccountsPage/AdsPage';
import PartnersPage from './Pages/AccountsPage/PartnersPage';
import MyBankPage from './Pages/AccountsPage/MyBankPage';
import Help from './components/Help';
import WalletsPage from './Pages/InformationPage/WalletsPage';
import RewardsPage from './Pages/InformationPage/RewardsPage';
import FaqsPage from './Pages/InformationPage/FaqsPage';
import ProvincesPage from './Pages/InformationPage/ProvincesPage';
import BannerPage from './Pages/BannerPage';
import TestomonialPage from './Pages/AccountsPage/TestomonialPage';
import PageNotFound from './components/PageNotFound';
import HelpPage from './components/helppage';
import GenralSettings from './components/GeneralSettings';
import GeneralSettingPage from './Pages/GeneralSettingPage';
import ExpensesPage from './Pages/AccountsPage/ExpensesPage';
import TotalExpensesPage from './Pages/AccountsPage/TotalExpensesPage';
import UserBankPage from './Pages/AccountsPage/UserBankPage';
import HelpSupport from './components/helpsupport';
import PaymentsReceiptPage from './Pages/AccountsPage/PaymentsReceiptPage';
import UploadVedioPage from './Pages/AccountsPage/UploadVedioPage';

// Private Route Component
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('token'); // Check token existence
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/help" element={<Help />} />
        <Route path="/help&support" element={<HelpSupport />} />
        <Route path="/help/:helpTopic" element={<HelpPage />} />
        <Route path="/generalsetting/:general" element={<PrivateRoute component={GeneralSettingPage} />} />
        <Route path="/accounts" element={<PrivateRoute component={AccountsPage} />} />
        <Route path="/uploadvideo" element={<PrivateRoute component={UploadVedioPage} />} />
        <Route path="/information" element={<PrivateRoute component={InformationPage} />} />
        <Route path="/users" element={<PrivateRoute component={UserPage} />} />
        <Route path="/coupons" element={<PrivateRoute component={CouponsPage} />} />
        <Route path="/gifts" element={<PrivateRoute component={GiftsPage} />} />
        <Route path="/expenses" element={<PrivateRoute component={ExpensesPage} />} />
        <Route path="/totalexpenses" element={<PrivateRoute component={TotalExpensesPage} />} />
        <Route path="/paymentsreceipt" element={<PrivateRoute component={PaymentsReceiptPage} />} />
        <Route path="/payments" element={<PrivateRoute component={PaymentsPage} />} />
        <Route path="/affiliates" element={<PrivateRoute component={AffliatesPage} />} />
        <Route path="/mybank" element={<PrivateRoute component={MyBankPage} />} />
        <Route path="/userbank" element={<PrivateRoute component={UserBankPage} />} />
        <Route path="/partners" element={<PrivateRoute component={PartnersPage} />} />
        <Route path="/walltes" element={<PrivateRoute component={WalletsPage} />} />
        <Route path="/rewards" element={<PrivateRoute component={RewardsPage} />} />
        <Route path="/faqs" element={<PrivateRoute component={FaqsPage} />} />
        <Route path="/provinces" element={<PrivateRoute component={ProvincesPage} />} />
        <Route path="/banner" element={<PrivateRoute component={BannerPage} />} />
        <Route path="/testomonial" element={<PrivateRoute component={TestomonialPage} />} />
        <Route path="/ads" element={<PrivateRoute component={AdsPage} />} />
        <Route path="*" element={<PrivateRoute component={PageNotFound} />} />

      </Routes>
    </div>
  );
}

export default App;
