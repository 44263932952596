import Axios from "axios";
import { backendUrl } from "../utils";


let getTestamonialListApi = "/dashboard/listTestimonials";
let TestamonialApi = "/dashboard/testimonial";
let RejectApi = "/dashboard/reject_testimonial"


const TestomonialApi = {

    getAllTestamonialList(params, token) {
        return Axios({
            url: backendUrl + getTestamonialListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    deleteTestamonial(params, token) {
        return Axios({
            url: backendUrl + TestamonialApi + params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    approveTestamonial(params, token) {
        return Axios({
            url: backendUrl + TestamonialApi + params,
            method: 'patch',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    rejectTestamonial(params, token) {
        return Axios({
            url: backendUrl + RejectApi,
            method: 'patch',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },





}

export default TestomonialApi;
