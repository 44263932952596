import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, MenuItem, Select, Stack, TextField, Typography, styled } from '@mui/material'
import bankimg from '../../../Assests/icons/bankicon.svg'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import BankPageApi from '../../../apis/BankPageApi';
import { useNavigate } from 'react-router-dom';
import { CustomizedTextField } from '../../../style/commonStyle';


const bankdatas = [
    {
        id: 1, pic: bankimg, name: "Royal bank of canada", number: "**** **** 3434",
    },

]

const BankAccountsCard = () => {
    const navigate = useNavigate()
    const [BankData, setBankData] = useState(bankdatas);
    const [bankData, setBankdata] = useState([])
    const [bankDetail, setBankDetail] = useState({})
    const [loading, setLoading] = useState(false)
    const [bankDialogOpen, setBankDialogOpen] = useState(false)
    const token = localStorage.getItem("token")


    const handleBankDetailChange = (event) => {
        const { name, value } = event.target;
        if (value === '' || parseFloat(value) >= 0) {
            setBankDetail((prevBankDetail) => ({
                ...prevBankDetail,
                [name]: value,
            }));
        } else {
            setBankDetail((prevBankDetail) => ({
                ...prevBankDetail,
                [name]: value,
            }));
        }
    };



    const handleClose = () => {
        setBankDialogOpen(false);
        setBankDetail({})
    };

    const addBankDetail = (e) => {
        e.preventDefault()
        setLoading(true);

        if (bankDetail.number === bankDetail.reenternumber) {
            let payload = {
                card_name: bankDetail.name,
                ibn_no: bankDetail.ifscode,
                account_no: bankDetail.number || bankDetail.reenternumber,
                name: bankDetail.accountname
            };

            BankPageApi.addBankDetails(payload, token)
                .then(response => {
                    setBankDetail({});
                    getBankDetails()
                    setBankDialogOpen(false);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        toast.error(error.response.data.detail, { autoClose: 1400 });
                        navigate("/");
                    } else {
                        console.log("Error:", error);
                    }
                });
        } else {
            toast.error("Account numbers don't match", { autoClose: 1400 });
            setLoading(false);
        }
    };


    const getBankDetails = () => {
        setLoading(true);
        BankPageApi.getBankDetail(token)
            .then(response => {
                setBankdata(response);
                setLoading(false);
            })
            .catch(error => {

            })
    };

    useEffect(() => {
        getBankDetails()
    }, [])

    const formatAccountNumber = (accountNo) => {
        const segments = accountNo.match(/.{1,5}/g);
        const maskedSegments = segments.slice(0, -1).map(segment => '****');
        const maskedAccountNo = [...maskedSegments, segments.slice(-1)[0]].join(' ');
        return maskedAccountNo;
    };


    return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
            <Grid container direction={"row"} mt={1.5} mb={3}>
                <Grid item xs={4}>
                    <Typography className='parttnershead'>Bank accounts</Typography>
                </Grid>
            </Grid>

            <Grid container direction={"row"} alignItems={"center"} mt={5}>
                {bankData?.detail?.map((data) => (
                    <React.Fragment key={data.id}>
                        <Grid item xs={12}>
                            <Stack direction={"row"} spacing={2}>
                                <Box sx={{ borderRadius: "50%" }}>
                                    <img width={44} height={44} style={{ borderRadius: "50%" }} src={data.pic} alt={data.pic} />
                                </Box>

                                <Stack direction={"column"} spacing={0}>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#3B3B3B", fontWeight: "600" }}>
                                        {formatAccountNumber(data.account_no)}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                        {data.name}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 2 }} />
                    </React.Fragment>
                ))}

                <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center", mt: 4, width: "100%" }}>
                    <Button sx={{ fontSize: "14px", fontWeight: "500", textTransform: "capitalize", fontFamily: "Poppins" }} variant='outlined' color='error' onClick={() => setBankDialogOpen(true)}>Add bank account</Button>
                </Box>

            </Grid>



            <Dialog
                // fullWidth
                // maxWidth="600px"
                open={bankDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={addBankDetail} >
                    <DialogContent sx={{ width: "450px" }}>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <CloseIcon sx={{ color: "#F96468", cursor: "pointer" }} onClick={handleClose} />
                        </Stack>
                        <DialogContentText id="alert-dialog-description">

                            <Typography sx={{ mb: 3, fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#3B3B3B", textAlign: "center" }}>Bank details</Typography>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                    <CircularProgress sx={{ color: "#F96064" }} />
                                </Box>
                            ) : (
                                <Stack direction={"column"} spacing={2}>
                                    <CustomizedTextField
                                        type='number'
                                        id="outlined-basic"
                                        fullWidth
                                        variant='outlined'
                                        required
                                        value={bankDetail.number}
                                        name='number'
                                        onChange={handleBankDetailChange}
                                        size='small'
                                        InputProps={{ inputProps: { min: 1, max: 100000764664366646 } }}
                                        placeholder='Account number'
                                    />

                                    <CustomizedTextField
                                        type='number'
                                        value={bankDetail.reenternumber}
                                        name='reenternumber'
                                        required
                                        onChange={handleBankDetailChange}
                                        id="outlined-basic"
                                        fullWidth
                                        InputProps={{ inputProps: { min: 1, max: 100000764664366646 } }}
                                        variant='outlined'
                                        size='small'
                                        placeholder='Reenter account number'
                                    />

                                    <CustomizedTextField
                                        value={bankDetail.ifscode}
                                        name='mobile'
                                        type='number'
                                        required
                                        onChange={handleBankDetailChange}
                                        id="outlined-basic"
                                        fullWidth
                                        InputProps={{ inputProps: { min: 1, max: 100000764664366646 } }}
                                        variant='outlined'
                                        size='small'
                                        placeholder='IFSC code'
                                    />

                                    <CustomizedTextField
                                        type='text'
                                        value={bankDetail.name}
                                        name='name'
                                        onChange={handleBankDetailChange}
                                        id="outlined-basic"
                                        fullWidth
                                        required
                                        variant='outlined'
                                        size='small'
                                        placeholder='Bank name'
                                    />

                                    <CustomizedTextField
                                        type='text' // <-- Added type attribute as 'text'
                                        name='accountname'
                                        value={bankDetail.accountname}
                                        required
                                        onChange={handleBankDetailChange}
                                        fullWidth
                                        id="outlined-basic"
                                        variant='outlined'
                                        size='small'
                                        placeholder='Name on account'
                                    />


                                </Stack>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ padding: "20px", mb: "15px" }}>

                        <Button variant='contained'
                            fullWidth
                            sx={{
                                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                color: 'white',
                                textTransform: "capitalize"
                            }} type='submit'>
                            Add bank account
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>


        </div>
    )
}

export default BankAccountsCard