import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge, Box, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TablePagination from '../../TablePagination';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { StyledTableCell, StyledTableRow } from '../../../style/commonStyle';



const TransactionsTable = ({ loading, Page, setPage, rowsPerPage, paymentsList, transactiondate, setTransactionDate }) => {

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleDateChange = (e) => {
        setTransactionDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(18);
        doc.text('Transactions Overview', 10, 10);

        const tableData = paymentsList?.results?.map((row) => [
            row.username,
            row.transaction_type,
            `$${row.amount}`,
            moment(row.created_at).format('MMMM Do YYYY'),
        ]);

        const headers = ['Customer name', 'Transaction Type', 'Payment', 'Date'];

        doc.autoTable({
            head: [headers],
            body: tableData,
            startY: 20,
        });

        doc.save('transactions_overview.pdf');
    };


    const today = new Date().toISOString().slice(0, 10);

    return (
        <div style={{ padding: "10px 20px 20px 20px" }}>


            <Grid container className="graphinner-div">
                <Grid item xs={12} lg={6} md={12}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography className='parttnershead'>Transactions</Typography>
                        <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                            <Badge badgeContent="" variant="dot" color='success' />
                            <span style={{ color: "#949494", fontSize: "12px" }}>{paymentsList?.count} transactions</span>
                        </Stack>
                    </Stack>

                </Grid>
                <Grid item xs={12} lg={6} md={12}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <div onClick={generatePDF} style={{ cursor: "pointer", marginRight: "10px" }}>
                            <img src={pdficon} alt="img" />
                        </div>
                        <div>

                        </div>
                        <TextField size='small' type="date" name='transactiondate' value={transactiondate} onChange={handleDateChange} defaultValue={today} sx={{
                            width: "130px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />
                    </div>
                </Grid>
            </Grid>
            <Box>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                            <Table aria-label="customized table dense">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ paddingLeft: 3 }}>Customer name</StyledTableCell>
                                        <StyledTableCell align="left">Treansaction Type</StyledTableCell>
                                        <StyledTableCell align="left">Payment</StyledTableCell>
                                        <StyledTableCell align="left">Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {paymentsList.results && paymentsList.results.length > 0 ? (
                                        paymentsList.results.map((row, i) => (
                                            <>
                                                <StyledTableRow key={i} sx={{ width: "100%" }}>
                                                    <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, fontWeight: 600 }}>
                                                        <Stack direction={'column'} >
                                                            <Typography className='storename'>{row.username}</Typography>
                                                            <Typography className='partnerdate'> {moment(row.created_at).format('MMMM Do YYYY')}</Typography>
                                                        </Stack>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left"><Typography className='location'>{row.transaction_type}</Typography></StyledTableCell>
                                                    <StyledTableCell >
                                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                            <Typography className='location'>${row.amount}</Typography>
                                                        </Stack>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                                        <IconButton>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu id="long-menu">
                                                            <MenuItem>Edit</MenuItem>
                                                            <MenuItem>Delete</MenuItem>
                                                        </Menu>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>

                            </Table>
                        </TableContainer>
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                            <TablePagination
                                totalItems={paymentsList}
                                itemsPerPage={rowsPerPage}
                                page={Page}
                                onPageChange={handlePageChange}
                            />
                        </Box>


                    </>
                )}

            </Box>
        </div >
    )
}

export default TransactionsTable