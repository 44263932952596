import Axios from "axios";
import { backendUrl } from "../utils";

let getAdsRevenueApi = "/dashboard/adsRevnueGraph";
let getAdsGrowthRateApi = '/dashboard/partnerGrowthRate'
let getUserAdsListApi = '/dashboard/listUserAds'

const AdsApi = {

    getAdsRevenue(params, token) {
        return Axios({
            url: backendUrl + getAdsRevenueApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getAdsGrowthRate(params, token) {
        return Axios({
            url: backendUrl + getAdsGrowthRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getUserAdsList(params, token) {
        return Axios({
            url: backendUrl + getUserAdsListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


}

export default AdsApi;
