import React from 'react'
import Sidebar from '../../../components/Sidbar'
import { Box } from '@mui/material'
import Partners from '../../../components/Accounts/Partners'

const PartnersPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <Partners />
            </Box>
        </div>
    )
}

export default PartnersPage