import Axios from "axios";
import { backendUrl } from "../utils";

let getAffliatesRevenueApi = "/dashboard/affiliateRevenueGraph";
let getGrowthRateApi = '/dashboard/affiliateGrowthrate'

const AffiliatesApi = {

    getAffliatesRevenue(params, token) {
        return Axios({
            url: backendUrl + getAffliatesRevenueApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getGrowthRate(params, token) {
        return Axios({
            url: backendUrl + getGrowthRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


}

export default AffiliatesApi;
