// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addpartner {
    font-family: "Poppins" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #3B3B3B !important;
    text-align: center !important;

}

.otpsent {
    font-family: "Poppins" !important;
    font-size: 12px !important;
    font-weight: 300 !important;
    line-height: 14px !important;
    letter-spacing: 0.005em !important;
    text-align: center !important;

}

.resend {

    font-family: "Poppins" !important;
    font-size: 12px !important;
    font-weight: 300 !important;
    line-height: 14px !important;
    letter-spacing: 0.005em !important;
    text-align: center !important;
    color: linear-gradient(360deg, #F33F41 0%, #FB6D72 95%),
        linear-gradient(0deg, #3B3B3B, #3B3B3B) !important;
    ;

}

.about {
    font-family: "Poppins" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #3B3B3B !important;
}

.areusure {
    font-family: "Poppins" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #3B3B3B !important;

}`, "",{"version":3,"sources":["webpack://./src/components/Accounts/Partners/index.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;IAC3B,yBAAyB;IACzB,6BAA6B;;AAEjC;;AAEA;IACI,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;IAC5B,kCAAkC;IAClC,6BAA6B;;AAEjC;;AAEA;;IAEI,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;IAC5B,kCAAkC;IAClC,6BAA6B;IAC7B;0DACsD;;;AAG1D;;AAEA;IACI,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;IACjC,0BAA0B;IAC1B,2BAA2B;IAC3B,6BAA6B;IAC7B,yBAAyB;;AAE7B","sourcesContent":[".addpartner {\n    font-family: \"Poppins\" !important;\n    font-size: 20px !important;\n    font-weight: 600 !important;\n    color: #3B3B3B !important;\n    text-align: center !important;\n\n}\n\n.otpsent {\n    font-family: \"Poppins\" !important;\n    font-size: 12px !important;\n    font-weight: 300 !important;\n    line-height: 14px !important;\n    letter-spacing: 0.005em !important;\n    text-align: center !important;\n\n}\n\n.resend {\n\n    font-family: \"Poppins\" !important;\n    font-size: 12px !important;\n    font-weight: 300 !important;\n    line-height: 14px !important;\n    letter-spacing: 0.005em !important;\n    text-align: center !important;\n    color: linear-gradient(360deg, #F33F41 0%, #FB6D72 95%),\n        linear-gradient(0deg, #3B3B3B, #3B3B3B) !important;\n    ;\n\n}\n\n.about {\n    font-family: \"Poppins\" !important;\n    font-size: 14px !important;\n    font-weight: 600 !important;\n    color: #3B3B3B !important;\n}\n\n.areusure {\n    font-family: \"Poppins\" !important;\n    font-size: 20px !important;\n    font-weight: 600 !important;\n    text-align: center !important;\n    color: #3B3B3B !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
