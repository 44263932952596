import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import storeimg from '../../../Assests/images/partnerimg.svg'
import locationicon from '../../../Assests/icons/location.svg'
import staricon from '../../../Assests/icons/star.svg'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Badge, Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Pagination, Select, Stack, TextField, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TablePagination from '../../TablePagination';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const StorePartners = ({ loading, storeListData, storedate, setStoreDate, provinces, selectedprovince, setSelectedProvince, storePerPage, setStorePerPage, storelimit }) => {
    const [addDetaildialogOpen, setAddDetailDialogOpen] = useState(false)
    const [blockdialogOpen, setBlockDialog] = useState(false)
    const [blockedDialog, setBlockedDialog] = useState(false)

    const handlePageChange = (event, newPage) => {
        setStorePerPage(newPage);
    };

    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleOpenDetail = (index) => {
        setAddDetailDialogOpen(true)
    }

    const handleClose = () => {
        setAddDetailDialogOpen(false);
        setBlockDialog(false)
        setBlockedDialog(false)
    };


    const handleDateChange = (e) => {
        setStoreDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Store Partner Data', 10, 10);
        const tableData = storeListData?.results?.map(row => [
            row.name,
            row.address,
            row.province,
            row.open_time,
        ]) || [];

        doc.autoTable({
            startY: 20,
            head: [['Store Name', 'Location', 'Province', "Open Time",]],
            body: tableData,
        });

        doc.save('transaction_card_data.pdf');
    };

    return (
        <div style={{ padding: "10px 20px 20px 20px" }}>


            <Grid container className="graphinner-div">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography className='parttnershead'>Store partners</Typography>
                        <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                            <Badge badgeContent="" variant="dot" color='success' />
                            <span style={{ color: "#949494", fontSize: "12px" }}>{storeListData.count} active</span>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <div onClick={generatePDF} style={{ cursor: "pointer", marginRight: "10px" }}>
                            <img width={20} height={20} src={pdficon} alt="img" />
                        </div>
                        <div>
                            <CustomSelect
                                sx={{ width: "110px", mr: 1 }}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedprovince}
                                onChange={handleChangeProvince}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Select a province
                                </MenuItem>
                                {provinces.map((item, index) => (
                                    <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                        <TextField type="date" name='storedate' value={storedate} onChange={handleDateChange} sx={{
                            width: "140px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />

                    </div>
                </Grid>
            </Grid>

            <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                <Table aria-label="customized table dense">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ paddingLeft: 3 }}>Store Name</StyledTableCell>
                            <StyledTableCell align="left">Email</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Province</StyledTableCell>
                            <StyledTableCell align="left">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            storeListData && storeListData.results && storeListData.results.length > 0 ? (
                                storeListData.results.map((row, i) => (
                                    <StyledTableRow key={i} sx={{ width: "100%", cursor: "pointer" }} onClick={() => handleOpenDetail(i)}>
                                        <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, fontWeight: 600 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <img width={32} height={32} src={row.logo} alt={"logo"} />
                                                <Stack direction={'column'} >
                                                    <Typography className='storename'>{row.name}</Typography>
                                                    <Typography className='partnerdate'>{row.open_time}</Typography>
                                                </Stack>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="left"><Typography className='location'>{row.user}</Typography></StyledTableCell>
                                        <StyledTableCell align="left"><Typography className='location'>{row.address}</Typography></StyledTableCell>
                                        <StyledTableCell >
                                            <Typography className='location'>{row.province}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                            <IconButton>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu id="long-menu">
                                                <MenuItem>Edit</MenuItem>
                                                <MenuItem>Delete</MenuItem>
                                            </Menu>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ fontSize: "30px", fontWeight: "600" }} align="center">No data found</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                <TablePagination
                    totalItems={storeListData}
                    itemsPerPage={storelimit}
                    page={storePerPage}
                    onPageChange={handlePageChange}
                />
            </Box>


            <Dialog
                fullWidth
                maxWidth="sm"
                open={addDetaildialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                        <Stack direction={'row'} spacing={2}>
                            <img width={60} height={60} src={storeimg} alt={storeimg} />
                            <Stack direction={"column"} spacing={2} >
                                <Stack direction={"row"} spacing={2}>
                                    <Typography className='storename'>Manraj Sweets</Typography>
                                    <BlockRoundedIcon sx={{ color: "#FA696E" }} onClick={() => setBlockDialog(true)} />
                                </Stack>
                                <Box display={"flex"}>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ mr: 2 }}>
                                        <img src={staricon} alt='staricon' />
                                        <Typography sx={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "500", fontFamily: "Poppins" }}>234 reviews</Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        <img src={locationicon} alt='locationicon' />
                                        <Typography sx={{ color: "#AEAEAE", fontSize: "12px", fontWeight: "500", fontFamily: "Poppins" }}>2.5 Km</Typography>
                                    </Stack>

                                </Box>
                            </Stack>
                        </Stack>
                        <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography className='about' sx={{ mb: 1 }}>About</Typography>
                        <Typography className='aboutdescription'>Indulging in the vibrant, jewel-like seeds of pomegranate offers a myriad of health benefits. Bursting with antioxidants, these ruby-red gems promote.</Typography>

                        <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 3 }} />
                        <Typography className='about' sx={{ mb: 1 }}>Payments</Typography>


                        <Grid container direction={"row"} spacing={2}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div class="totaluser">
                                    <div className="cardtext">
                                        <div>
                                            <h3 className="totaltext">Settled amount</h3>
                                        </div>
                                        <div>
                                            <h2 className="count">$94k</h2>
                                        </div>
                                    </div>
                                    {/* <div className="cardimg">
                                        <img width="85" height="47" src={chartimg} alt="line" />
                                    </div> */}
                                </div>
                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className="provineces">
                                    <div className="cardtext">
                                        <div>
                                            <h3 className="totaltext">Pending amount</h3>
                                        </div>
                                        <div>
                                            <h2 className="count">$94k</h2>
                                        </div>
                                    </div>
                                    {/* <div className="cardimg">
                                        <img width="85" height="47" src={chartimg} alt="line" />
                                    </div> */}
                                </div>


                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className="activeuser">
                                    <div className="cardtext">
                                        <div>
                                            <h3 className="totaltext">Orders</h3>
                                        </div>
                                        <div>
                                            <h2 className="count">23k</h2>
                                        </div>
                                    </div>
                                    {/* <div className="cardimg">
                                        <img width="85" height="47" src={chartimg} alt="line" />
                                    </div> */}

                                </div>
                            </Grid>

                        </Grid>

                        <Typography className='about' sx={{ mb: 1, mt: 2 }}>Contact details</Typography>

                        <Stack direction={"row"} spacing={2} mb={1}>

                            <Box sx={{ border: "1px solid #686868", borderRadius: "5px", padding: "3px 3px" }}>
                                <Stack direction={"row"} spacing={1}>
                                    <LocalPhoneIcon sx={{ color: "#686868" }} />
                                    <Typography>+1 888 444 3434</Typography>
                                </Stack>
                            </Box>

                            <Box sx={{ border: "1px solid #686868", borderRadius: "5px", padding: "3px 3px" }}>
                                <Stack direction={"row"} spacing={1}>
                                    <EmailOutlinedIcon sx={{ color: "#686868" }} />
                                    <Typography>info@manrajsweets.com</Typography>
                                </Stack>
                            </Box>

                        </Stack>

                    </DialogContentText>
                </DialogContent>

            </Dialog>



            <Dialog
                fullWidth
                maxWidth="xs"
                open={blockdialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ padding: "0px 50px" }}>
                        <Typography className='areusure' sx={{ mb: 1 }}>
                            Are you sure you want to
                            block the seller?
                        </Typography>

                        <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
                            <Stack direction={"column"} spacing={2}>
                                <Button sx={{ width: "160px", maxWidth: "200px", display: "flex", justifyContent: "center" }} variant='outlined' color='error' size='small' onClick={() => setBlockedDialog(true)}>Block</Button>
                                <Button size='small' sx={{ width: "160px", maxWidth: "200px", color: "#000000", fontFamily: "Poppins", fontWeight: "500" }} onClick={handleClose}>Cancel</Button>
                            </Stack>
                        </Box>

                    </DialogContentText>
                </DialogContent>

            </Dialog>


            <Dialog
                // fullWidth
                // maxWidth="xs"
                open={blockedDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                </DialogTitle>
                <DialogContent sx={{ maxWidth: "400px" }}>
                    <DialogContentText id="alert-dialog-description" sx={{ padding: "0px 40px", justifyContent: "center", textAlign: "center" }}>
                        <CheckCircleIcon sx={{ color: "#5DC161" }} />
                        <Typography className='areusure' sx={{ mb: 1 }}>
                            Partner blocked
                        </Typography>

                        <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
                            <Stack direction={"column"} spacing={2}>
                                <Button sx={{ width: "160px", maxWidth: "200px", display: "flex", justifyContent: "center" }} variant='contained' color='error' size='small' onClick={handleClose}>Go back</Button>
                            </Stack>
                        </Box>

                    </DialogContentText>
                </DialogContent>

            </Dialog>



        </div>
    )
}

export default StorePartners