import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { MenuItem,  Stack, TextField, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect } from '../../../style/commonStyle';

const AddExpenses = ({ adsExpenseData, setAdsExpenseDate, expenseRateDate, provinces, selectedprovince, setSelectedProvince }) => {

    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const monthsNames = adsExpenseData?.ads_expanse_graph_data?.arr_of_month
    var areaOptions = {
        series: [
            {
                name: 'Desktops',
                data: adsExpenseData?.ads_expanse_graph_data?.arr_of_settled_amount,
            },
            {
                name: 'Laptops',
                data: adsExpenseData?.ads_expanse_graph_data?.arr_of_pending_amount,
            },
        ],

        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#EFCDFF', '#F75659'], 
            width: 3
        },
        grid: {

            show: false
        },
        xaxis: {
            categories: monthsNames,
        },
        tooltip: {
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = areaOptions.stroke.colors[seriesIndex];
                return (
                    '<div class="custom-tooltip" style="color: ' + color + '">' +
                    '<span class="number">' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                    '<div class="tooltip-line" style="background-color: ' + color + '"></div>' +
                    '<span class="units">' + series[seriesIndex][dataPointIndex] + ' </span>' +
                    '</div>'
                );
            },
        }, legend: {
            show: false,
        },
        fill: {
            colors: ['#FF5733', '#3399FF'],
        },

    };

    const handleDateChange = (event) => {
        setAdsExpenseDate(event.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Ads Expense Overview', 10, 10);

        doc.text('Ads Expense Chart:', 10, 20);
        const monthsNames = adsExpenseData?.ads_expanse_graph_data?.arr_of_month;
        const settledAmounts = adsExpenseData?.ads_expanse_graph_data?.arr_of_settled_amount;
        const pendingAmounts = adsExpenseData?.ads_expanse_graph_data?.arr_of_pending_amount;

        const formattedChartData = monthsNames.map((month, index) => ({
            month,
            settled: settledAmounts[index],
            pending: pendingAmounts[index]
        }));

        doc.autoTable({
            head: [['Month', 'Settled Amount', 'Pending Amount']],
            body: formattedChartData.map(({ month, settled, pending }) => [month, settled, pending])
        });

        doc.save('ads_expense_overview.pdf');
    }

    return (
        <div>
            <div className="graph-div">
                <div className="graphinner-div">
                  
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography className='parttnershead'> Ads expenses <span style={{ color: "Red", fontSize: "16px", fontWeight: "600", fontFamily: "Poppins" }}>({adsExpenseData.total_expanse})</span></Typography>
                       
                    </Stack>
                    <div className="right-div">
                        <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                            <img src={pdficon} alt="img" />
                        </div>
                        <div>
                            <CustomSelect
                                sx={{ width: "110px" }}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedprovince}
                                onChange={handleChangeProvince}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Select a province
                                </MenuItem>
                                {provinces.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                ))}
                            </CustomSelect>

                        </div>
                        <TextField type="date" name="expenseRateDate" value={expenseRateDate} onChange={handleDateChange} sx={{
                            width: "130px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />
                    </div>
                </div>
                <div id="linechart">
                    <ReactApexChart options={areaOptions} series={areaOptions.series} type="line" height={390} />
                </div>
            </div>
        </div >
    )
}

export default AddExpenses