import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, OutlinedInput, Paper, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactFileReader from 'react-file-reader';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import { ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import BannerApi from '../../../apis/BannerApi';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useNavigate } from 'react-router-dom';
import { BootstrapDialog, CustomizedTextField, Item, StyledTableCell, StyledTableRow, customTheme } from '../../../style/commonStyle';

const Banner = () => {
  const navigate = useNavigate()
  const [banner, setBanner] = useState([])
  const [bannerData, setBannerData] = useState({})
  const [loading, setLoading] = useState(false)
  const [createBannerOpen, setCreateBannerDialogOpen] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialoge] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [updateFaq, setUpdateFaq] = useState({})
  const [createbanner, setCreateBanner] = useState({
    banner_file: null,
    banner_name: ""
  })

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);





  const handleClose = () => {
    setCreateBannerDialogOpen(false);
  };

  const handleCloseEditDialog = () => {
    setEditDialog(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    getAllBanners()

  }, [rowsPerPage, currentPage])



  const uploadBGImage = (files) => {
    const bgfile = files.fileList[0];
    setCreateBanner((prevState) => ({
      ...prevState,
      banner_name: bgfile.name,
      banner_file: bgfile,
      background_img: URL.createObjectURL(bgfile),
    }));
  };


  const handleCreateChange = (event) => {
    const { name, value } = event.target;
    setCreateBanner(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const token = localStorage.getItem("token")

  const getAllBanners = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    BannerApi.getAllBannerList(payload, token)
      .then(response => {
        setBanner(response.results);
        setBannerData(response)
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      });
  };


  const OpenBannerDialoge = () => {
    setCreateBannerDialogOpen(true)
  }


  // const handleEditFaqsDialog = (id) => {
  //   setEditDialog(true)
  //   let rowid = `?id=${id}`
  //   FaqsApi.getSingleFaq(rowid, token)
  //     .then(response => {
  //       setUpdateFaq(response.detail)
  //     })
  //     .catch(error => {
  //       console.error("Something went wrong:", error);
  //       setLoading(false);
  //     });
  // }

  const handleDeleteBanner = () => {
    setLoadingDelete(true);
    let payload = `?id=${selectedId}`;
    BannerApi.deleteBanner(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllBanners()
        setLoadingDelete(false);
        setDeleteDialoge(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingDelete(false);
      });

  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialoge(false)
  }

  const handleDeleteBannerDiaoge = (id) => {
    setDeleteDialoge(true)
    setSelectedId(id)
  }

  // const handleUpdateFaq = () => {
  //   setLoadingUpdate(true);
  //   let payload = {
  //     question: updateFaq.question,
  //     answer: updateFaq.answer,
  //   };
  //   let rowid = `?id=${selectedId}`;
  //   FaqsApi.UpdateFaq(rowid, payload, token)
  //     .then(response => {
  //       toast.success(response.detail, { autoClose: 1400 });
  //       getAllFaqs()
  //       setLoadingUpdate(false);
  //       setEditDialog(false);
  //     })
  //     .catch(error => {
  //       console.error("Something went wrong:", error);
  //       setLoadingUpdate(false);
  //     });
  // }


  const handleCreateBanner = () => {
    const formData = new FormData();
  
    formData.append("name", createbanner.name)
    formData.append("description", createbanner.description)
    formData.append('image', createbanner.banner_file);
    BannerApi.createBanner(formData, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllBanners()
        setCreateBanner({})
        setLoadingCreate(false);
        setCreateBannerDialogOpen(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }



  const startIndex = (currentPage - 1) * rowsPerPage;

  return (
    <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>

      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
        <Typography className='infromation' >Banners</Typography>

        <Button
          variant='contained'
          sx={{
            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
            color: 'white',
            fontSize: "12px"
          }}
          onClick={OpenBannerDialoge}
        >
          Create Banner
        </Button>
      </Stack>

      <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
        <Grid item xs={12}>
          <Item>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress sx={{ color: "#F96064" }} />
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                <Table aria-label="customized table dense">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                      <StyledTableCell align="left">Name</StyledTableCell>
                      <StyledTableCell align="left">Image</StyledTableCell>
                      <StyledTableCell align="left">Description</StyledTableCell>
                      <StyledTableCell align="left">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {banner ? (
                      banner.length > 0 ? (
                        banner.map((row, i) => (
                          <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                              {startIndex + i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: "25%" }}>{row.name}</StyledTableCell>
                            <StyledTableCell align="left" ><img src={row.image} width={50} height={40} alt="Banner" /></StyledTableCell>
                            <StyledTableCell align="left">{row.description}</StyledTableCell>
                            <StyledTableCell >
                              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Box sx={{ color: "red", cursor: "pointer" }}>
                                  <DeleteOutlineOutlinedIcon onClick={() => handleDeleteBannerDiaoge(row.id)} />
                                </Box>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            Data not found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>

                </Table>
              </TableContainer>
            )}


            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
              <Pagination
                count={Math.ceil(bannerData.count / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Item>
        </Grid>

      </Grid>

      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={createBannerOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Create Banner
          </DialogTitle>
          <DialogContent dividers sx={{ width: "500px" }}>
            <Stack direction={"column"} spacing={2}>
              <CustomizedTextField value={createbanner.name} name='name' onChange={handleCreateChange} fullWidth variant='outlined' size='small' placeholder='name' />
              <CustomizedTextField value={createbanner.description} name='description' onChange={handleCreateChange} fullWidth variant='outlined' size='small' placeholder='description' />
              <FormControl size='small' variant="outlined">
                <OutlinedInput
                  disabled
                  id="outlined-adornment-password"
                  value={createbanner.image}
                  startAdornment={
                    <InputAdornment position="start">
                      <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadBGImage}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                          <Box sx={{ display: "flex" }}>
                            <DriveFolderUploadIcon />
                          </Box>
                          {createbanner.banner_name ? createbanner.banner_name : <Typography className='nofile'>No file chosen</Typography>}
                        </Stack>
                      </ReactFileReader>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"

              }} disabled={loadingCreate} onClick={handleCreateBanner}>
              {loadingCreate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Create"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>




      {/* <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="sm"
          onClose={handleCloseEditDialog}
          open={editDialog}

        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Update Faqs
          </DialogTitle>
          <DialogContent dividers>
            <Stack direction={"column"} spacing={2}>
              <TextField value={updateFaq.question} name='question' onChange={handleUpdateChange} fullWidth variant='outlined' size='small' placeholder='Question' />
              <TextField value={updateFaq.answer} name='answer' onChange={handleUpdateChange} multiline rows={4} fullWidth variant='outlined' size='small' placeholder='Answer...' />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseEditDialog}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }}
              onClick={handleUpdateFaq}
              disabled={loadingUpdate} 
            >
              {loadingUpdate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Update"} 
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider> */}




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="xs"
          onClose={handleCloseDeleteDialog}
          open={deleteDialog}

        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Delete
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you want to delete?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }}
              onClick={handleDeleteBanner}
              disabled={loadingDelete}
            >
              {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>

    </Box>
  )
}

export default Banner