import { Box, Grid, Paper, Typography, styled, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import RewardApi from '../../../apis/reward';
import { Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const Rewards = () => {
    const [loading, setLoading] = useState(false);
    const [rewards, setRewards] = useState([]);
    const [rewardsData, setRewardsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 30;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        getRewards();
    }, [rowsPerPage, currentPage]);

    const token = localStorage.getItem("token");
    const getRewards = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        RewardApi.getAllRewards(payload, token)
            .then(response => {
                setRewards(response.results);
                setRewardsData(response);
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    const startIndex = (currentPage - 1) * rowsPerPage;

    return (
        <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>

            <Typography className='infromation' sx={{ mb: 2 }}>Rewards</Typography>

            <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                <Grid item xs={12}>
                    <Item>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <CircularProgress sx={{ color: "#F96064" }} />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                <Table aria-label="customized table dense">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                            <StyledTableCell align="left">Full Name</StyledTableCell>
                                            <StyledTableCell align="left">Email</StyledTableCell>
                                            <StyledTableCell align="center">Balance</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rewards && rewards.length > 0 ? (
                                            rewards.map((row, i) => (
                                                <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                    <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                        {startIndex + i + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.fullname}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.email}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.balance}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    Data not found
                                                </TableCell>
                                            </TableRow>
                                        )}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                            <Pagination
                                count={Math.ceil(rewardsData.count / rowsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Rewards;
