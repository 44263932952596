import { Button, CircularProgress, Grid, MenuItem, Paper, Select, Stack, Typography, styled } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react'
import './index.css'
import PartnerGrowthRate from './PartnerGrowthRate';
import StoreSignUp from './StoreSignUp';
import StorePartners from './StorePartners';
import PartnersApi from '../../../apis/PartnersApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ProvincesApi from '../../../apis/provincesApi';
import { CustomizedTextField, Item } from '../../../style/commonStyle';

const Partners = () => {
    const navigate = useNavigate()
    const [addPartnerdialogOpen, setAddPartnerdialogOpen] = useState(false);
    const [bankDialogOpen, setBankDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bankDetail, setBankDetail] = useState({})
    const [userslist, setUserList] = useState([])
    const [addPartnerDetail, setAddPartnerDetail] = useState({})
    const [storeListData, setStoreListData] = useState({})
    const [storeSignup, setStoreSignUp] = useState({})
    const [storePerPage, setStorePerPage] = useState(1);
    const storelimit = 10;
    const storesignuplimit = 5;
    const [growthRateData, setGrowthRateData] = useState({})
    const [date, setDate] = useState("")
    const [storedate, setStoreDate] = useState("")
    const [selectedprovince, setSelectedProvince] = useState("")
    const [provinces, setProvinces] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 20;
    const userslimit = 1000;


    useEffect(() => {
        getGrowthRate()
    }, [date])

    useEffect(() => {
        getStoreList()
    }, [storedate, selectedprovince])

    useEffect(() => {
        getStoreList()
        getGrowthRate()
        getAllProvinces()
        getAllUsers();
        getStoreSignUP()
    }, [storePerPage])

    const token = localStorage.getItem("token")

    const handleBankDetailChange = (event) => {
        const { name, value } = event.target;
        setBankDetail((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleAddPartnerDetailChange = (event) => {
        const { name, value } = event.target;
        setAddPartnerDetail((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handlePartnerDialogeOpen = () => {
        setAddPartnerdialogOpen(true);
    };

    const handleClose = () => {
        setAddPartnerdialogOpen(false);
        setBankDialogOpen(false)
    };



    const getStoreList = () => {
        setLoading(true);
        let payload = `?limit=${storelimit}&page=${storePerPage}`
        if (storedate || selectedprovince) {
            payload += `&province=${selectedprovince}&selected_date=${storedate}`
        }
        PartnersApi.getListStorePartners(payload, token)
            .then(response => {
                setStoreListData(response);
                setLoading(false);
            })
            .catch(error => {
                console.log("Error:", error);
            })
    };


    const getStoreSignUP = () => {
        setLoading(true);
        let payload = `?limit=${storesignuplimit}&page=${storePerPage}`
        if (storedate || selectedprovince) {
            payload += `&province=${selectedprovince}&selected_date=${storedate}`
        }
        PartnersApi.getListStorePartners(payload, token)
            .then(response => {
                setStoreSignUp(response);
                setLoading(false);
            })
            .catch(error => {
                console.log("Error:", error);
            })
    };

    const getGrowthRate = () => {
        setLoading(true);
        let paylaod = ""
        if (date) {
            paylaod = `?selected_date=${date}`
        }
        PartnersApi.getPartnerGrowthRate(paylaod, token)
            .then(response => {
                setGrowthRateData(response);
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.detail, { autoClose: 1400 });
                    navigate("/");
                } else {
                    console.log("Error:", error);
                }
            })
    };


    const getAllProvinces = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        ProvincesApi.getAllProvincesList(payload, token)
            .then(response => {
                setProvinces(response.results);
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };


    const handleCreatePartner = () => {
        let payload = {
            user: addPartnerDetail.users,
            name: addPartnerDetail.stote_name,
            address: addPartnerDetail.address,
            contact: addPartnerDetail.contact,
        }
        PartnersApi.addPartner(payload, token)
            .then(response => {
                toast.success("Partner Added Succesfully", { autoClose: 1400 });
                getStoreList()
                setAddPartnerDetail({})
                setAddPartnerdialogOpen(false)
            })
            .catch(error => {
                console.error("Something went wrong:", error);
            });
    }


    const getAllUsers = () => {
        setLoading(true);
        let payload = `?limit=${userslimit}`;
        PartnersApi.getAllUserListList(payload, token)
            .then(response => {
                setUserList(response.results);
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };


    return (
        <div className='maininformation'>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography className='infromation' sx={{ mb: 2 }}>Partners</Typography>
                <Button
                    variant='contained'
                    sx={{
                        backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                        color: 'white',
                    }}
                    onClick={handlePartnerDialogeOpen}
                >
                    Add Partners
                </Button>
            </Stack>

            <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
                    <Grid item xs={12}>
                        <Item>
                            <StorePartners loading={loading} storeListData={storeListData} storedate={storedate} setStoreDate={setStoreDate} provinces={provinces} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} storePerPage={storePerPage} setStorePerPage={setStorePerPage} storelimit={storelimit} />
                        </Item>
                    </Grid>
                </Grid>



                {/* Right Section */}
                <Grid item container xs={12} rowGap={2} sm={6} md={4} >
                    <Grid item xs={12}>
                        <Item> <PartnerGrowthRate growthRateData={growthRateData} date={date} setDate={setDate} />  </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item> <StoreSignUp storeListData={storeSignup} /> </Item>
                    </Grid>

                </Grid>
            </Grid>

            <Dialog
                fullWidth
                maxWidth="xs"
                open={addPartnerdialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography className='addpartner' sx={{ mb: 4 }}>Add Partner</Typography>
                        <Stack direction={"column"} spacing={2}>
                            <Select
                                name='users'
                                value={addPartnerDetail.users}
                                onChange={handleAddPartnerDetailChange}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                placeholder='users'
                                className='province-select'
                                displayEmpty
                            >

                                <MenuItem value="" disabled>Select Users</MenuItem>
                                {userslist.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item.username}</MenuItem>
                                ))}

                            </Select>
                            {/* <CustomizedTextField fullWidth variant='outlined' name='name' value={addPartnerDetail.name} onChange={handleAddPartnerDetailChange} size='small' placeholder='Your Name here' /> */}
                            <CustomizedTextField fullWidth variant='outlined' name='address' value={addPartnerDetail.address} onChange={handleAddPartnerDetailChange} size='small' placeholder='Address here' />
                            <CustomizedTextField fullWidth variant='outlined' name='contact' value={addPartnerDetail.contact} onChange={handleAddPartnerDetailChange} size='small' placeholder='Contact here' />
                            <CustomizedTextField fullWidth variant='outlined' name='stote_name' value={addPartnerDetail.stote_name} onChange={handleAddPartnerDetailChange} size='small' placeholder='Store name' />

                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                    <Button variant='contained' onClick={handleCreatePartner}
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                        }} fullWidth>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>



            {/* 
            <Dialog
                fullWidth
                maxWidth="xs"
                open={bankDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form >
                    <DialogContent>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <CloseIcon sx={{ color: "#F96468", cursor: "pointer" }} onClick={handleClose} />
                        </Stack>
                        <DialogContentText id="alert-dialog-description">

                            <Typography sx={{ mb: 3, fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#3B3B3B", textAlign: "center" }}>Bank details</Typography>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                    <CircularProgress sx={{ color: "#F96064" }} />
                                </Box>
                            ) : (
                                <Stack direction={"column"} spacing={2}>
                                    <CustomizedTextField
                                        type='number'
                                        id="outlined-basic"
                                        fullWidth
                                        variant='outlined'
                                        required
                                        value={bankDetail.name}
                                        name='name'
                                        onChange={handleBankDetailChange}
                                        size='small'
                                        placeholder='Account number'
                                    />

                                    <CustomizedTextField
                                        type='number'
                                        value={bankDetail.email}
                                        name='email'
                                        required
                                        onChange={handleBankDetailChange}
                                        id="outlined-basic"
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        placeholder='Reenter account number'
                                    />

                                    <CustomizedTextField
                                        value={bankDetail.mobile}
                                        name='mobile'
                                        type='number'
                                        required
                                        onChange={handleBankDetailChange}
                                        id="outlined-basic"
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        placeholder='IFSC code'
                                    />

                                    <CustomizedTextField
                                        value={bankDetail.occupation}
                                        name='occupation'
                                        onChange={handleBankDetailChange}
                                        id="outlined-basic"
                                        fullWidth
                                        required
                                        variant='outlined'
                                        size='small'
                                        placeholder='Bank name'
                                    />

                                    <CustomizedTextField
                                        value={bankDetail.password}
                                        name='password'
                                        required
                                        onChange={handleBankDetailChange} fullWidth variant='outlined' size='small' placeholder='Name on account' />


                                </Stack>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ padding: "20px", mb: "15px" }}>

                        <Button variant='contained'
                            fullWidth
                            sx={{
                                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                color: 'white',
                                textTransform: "capitalize"
                            }} type='submit'>
                            Add bank account
                        </Button>
                    </DialogActions>
                </form>
            </Dialog> */}
        </div>
    )
}

export default Partners