import React from 'react'
import '../pages.css'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidbar'
import { Box, Grid } from '@mui/material'
import InformationDashboard from '../../components/Information/informationdashboard'

const InformationPage = () => {
  return (
    <div>

      <Sidebar />
      <Box className="main-box">
        <InformationDashboard />

      </Box>

    </div>
  )
}

export default InformationPage