import Axios from "axios";
import { backendUrl } from "../utils";


let getCouponsListApi = "/dashboard/get_coupans";
let getSlotUsersApi = '/dashboard/get_slot_users';
let getSlotsApi = '/dashboard/slot_list';
let automationStatusApi = '/dashboard/change_bet_setting';
let drawApi = "/dashboard/draw_user_slot";
let createCouponApi = "/dashboard/create_coupans"

const CoupenApi = {

    getAllCouponsList(params, token) {
        return Axios({
            url: backendUrl + getCouponsListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getSlotUsers(params, token) {
        return Axios({
            url: backendUrl + getSlotUsersApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getSlot(params, token) {
        return Axios({
            url: backendUrl + getSlotsApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    automationStatus(id, token) {
        return Axios({
            url: backendUrl + automationStatusApi + id,
            method: 'post',
            // data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    LuckyDraw(id, token) {
        return Axios({
            url: backendUrl + drawApi + id,
            method: 'post',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },



    CreateCoupon(params, token) {
        return Axios({
            url: backendUrl + createCouponApi,
            data: params,
            method: 'post',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },




    // deleteBanner(params, token) {
    //     return Axios({
    //         url: backendUrl + deleteBannerApi + params,
    //         method: 'delete',
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `token ${token}`,
    //         }
    //     }).then(response => {
    //         return response.data
    //     })
    // },

    // createBanner(params, token) {
    //     return Axios({
    //         url: backendUrl + createBannerApi,
    //         method: 'post',
    //         data: params,
    //         headers: {
    //             "content-type": "multipart/form-data",
    //             Authorization: `token ${token}`,
    //         }

    //     }).then(response => {
    //         return response.data
    //     })
    // },


}

export default CoupenApi;
