import Axios from "axios";
import { backendUrl } from "../utils";

let getStorePartnersApi = "/dashboard/listPartners";
let getPartnerGrowthRateApi = '/dashboard/partnerGrowthRateApi'
let AddPartnerApi = '/dashboard/partner'
let getUserListApi = '/dashboard/UserListWithoutStoreInfo'

const PartnersApi = {

    getListStorePartners(params, token) {
        return Axios({
            url: backendUrl + getStorePartnersApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },



    getPartnerGrowthRate(params, token) {
        return Axios({
            url: backendUrl + getPartnerGrowthRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    addPartner(params, token) {
        return Axios({
            url: backendUrl + AddPartnerApi,
            method: 'post',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },


    getAllUserListList(params, token) {
        return Axios({
            url: backendUrl + getUserListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },



}

export default PartnersApi;
