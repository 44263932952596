import React, { useEffect, useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import facebookicon from '../../../Assests/icons/facebookicon.svg'
import insta from '../../../Assests/icons/instagramicon.svg'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'apexcharts';
import { Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material';



const bannersdata = [
    {
        id: 1, pic: facebookicon, name: "Facebook", date: "Date- 01/01/2024,10:24AM", value: 25,
    },

    {
        id: 2, pic: insta, name: "Instagram", date: "Date- 01/01/2024,10:24AM", value: 25,
    },

]


const AdsExpenserateCard = ({ expenseRateData, expenseRateDate, setExpenseRateDate }) => {
    const [expensesData, setExpensesData] = useState(bannersdata)
    const [totalPercentage, setTotalPercentage] = useState(0);


    useEffect(() => {
        if (expenseRateData?.expanxe_rate_data?.expanse_rate_percent) {
            const percentages = expenseRateData.expanxe_rate_data.expanse_rate_percent;
            const total = percentages.reduce((acc, curr) => curr, 0);

            setTotalPercentage(total);
        }
    }, [expenseRateData]);

    const remainingPercentage = totalPercentage;

    var options = {
        chart: {
            type: 'donut'
        },

        plotOptions: {
            pie: {
                expandOnClick: true,

            },

        },

        series: expenseRateData?.expanxe_rate_data?.expanse_rate_percent ?? [],
        chartOptions: {
            labels: []
        },
        tooltip: {
            enabled: false
        },
        legend: {
            show: false
        }

    }

    const handleDateChange = (e) => {
        setExpenseRateDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(18);
        doc.text('Ads expenses rate Report', 10, 10);

        // Add growth rate data table
        const growthRateData = expenseRateData?.expanxe_rate_data?.expanse_rate_percent || [];
        const tableData = growthRateData.map((value, index) => [index + 1, `${value}`]);

        doc.autoTable({
            startY: 20,
            head: [['#', 'Ads expenses rate']],
            body: tableData,
        });

        // Save the PDF
        doc.save('ads_expense_rate.pdf');
    }
    return (
        <div >
            <div className="rightpie-div">
                <div>
                    <h3 className="growthrate">Ads expenses rate</h3>
                </div>
                <div className="calender-div">
                    <div onClick={generatePDF} style={{ marginRight: "10px", cursor: "pointer" }}>
                        <img src={pdficon} alt="img" />
                    </div>
                    <TextField type="date" value={expenseRateDate} name='expenseRateDate' onChange={handleDateChange} sx={{
                        width: "120px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px", // Adjust this value as needed
                        }
                    }} />
                    <i className="fas fa-calendar"></i>
                </div>

            </div>


            <div id="chart">
                <ReactApexChart options={options} series={options.series} type="donut" height={240} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: 'auto' }}>
                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>0%</p>
                </div>

                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>{remainingPercentage}%</p>
                </div>
            </div>

            <h6 class="coupensold">$320  less expenses than yesterday</h6>


            <Grid container direction={"row"} alignItems={"center"} mt={2} ml={2} >
                {expensesData.map((banner) => (
                    <React.Fragment key={banner.id}>
                        <Grid item xs={8}>
                            <Stack direction={"row"} spacing={2}>
                                <Box sx={{ borderRadius: "50%" }}>
                                    <img width={44} height={44} style={{ borderRadius: "50%" }} src={banner.pic} alt="" />
                                </Box>

                                <Stack direction={"column"} spacing={0.5}>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "15px", color: "#000000", fontWeight: "500" }}>
                                        {banner.name}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                        {banner.date}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#F44345", fontWeight: "500" }}>
                                $ - {banner.value}
                            </Typography>
                        </Grid>
                        <Divider orientation='horizontal' sx={{ width: "90%", mt: 1, mb: 2 }} />


                    </React.Fragment>
                ))}
            </Grid>
        </div>
    )
}

export default AdsExpenserateCard