import React, { useState } from 'react'
import { Box, Divider, Grid, MenuItem, Select, Stack, TextField, Typography, styled } from '@mui/material'
import affilateimg from '../../../Assests/images/affliatecard.png'
import pdficon from '../../../Assests/images/pdf.png'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect } from '../../../style/commonStyle';


const myAffliatedata = [
    {
        id: 1, pic: affilateimg, name: "Jack Smith", member: 240, price: 200, orignalprice: 300
    },

    {
        id: 2, pic: affilateimg, name: "Jack Smith", member: 0, price: 200, orignalprice: 300
    },
    {
        id: 3, pic: affilateimg, name: "Jack Smith", member: 100, price: 200, orignalprice: 300
    },
    {
        id: 4, pic: affilateimg, name: "Jack Smith", member: 50, price: 200, orignalprice: 300
    },
    {
        id: 4, pic: affilateimg, name: "Jack Smith", member: 80, price: 200, orignalprice: 300
    },


]


const AffiliatesCard = ({ provinces, selectedprovince, setSelectedProvince }) => {
    const [AffliateData, setAffliateData] = useState(myAffliatedata)

    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(18);
        doc.text('Affiliate Card Data', 10, 10);

        const tableData = AffliateData.map(({ name, member, price, orignalprice }, index) => [name, member, `$${price}`, `$${orignalprice}`]);
        doc.autoTable({
            startY: 20,
            head: [['Name', 'Member', 'Price', 'Original Price']],
            body: tableData,
        });

        doc.save('affiliate_card_data.pdf');
    };

    return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
            <Grid container direction={"row"} mt={1.5} mb={3}>
                <Grid item xs={4}>
                    <Typography className='parttnershead'>Affiliate</Typography>
                </Grid>

                <Grid item xs={8}>

                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <CustomSelect
                            sx={{ width: "110px" }}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedprovince}
                            onChange={handleChangeProvince}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Select a province
                            </MenuItem>
                            {provinces.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                            ))}
                        </CustomSelect>
                        <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                            <img width={20} height={20} src={pdficon} alt='pdf' />
                        </div>
                        <TextField type="date" sx={{
                            width: "140px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />
                    </Stack>
                </Grid>
            </Grid>
            <Grid container direction={"row"} alignItems={"center"}>
                {AffliateData.map((affiliate) => (
                    <React.Fragment key={affiliate.id}>
                        <Grid item xs={6}>
                            <Stack direction={"row"} spacing={2}>
                                <Box sx={{ borderRadius: "50%" }}>
                                    <img width={44} height={44} style={{ borderRadius: "50%" }} src={affiliate.pic} alt="" />
                                </Box>

                                <Stack direction={"column"} spacing={0.5}>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "15px", color: "#000000", fontWeight: "500" }}>
                                        {affiliate.name}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                        {affiliate.member} members
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#45A843", fontWeight: "500" }}>
                                $200 <span style={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494" }}>( $300 )</span>
                            </Typography>
                        </Grid>

                        <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 2 }} />
                    </React.Fragment>
                ))}

            </Grid>


        </div>
    )
}

export default AffiliatesCard