import React, { useEffect, useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { TextField } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AffliateGrowthChart = ({ growthRateData, date, setDate }) => {
    const [totalPercentage, setTotalPercentage] = useState(0);


    useEffect(() => {
        if (growthRateData?.affiliate_growth_rate_data?.growth_rate_count) {
            const percentages = growthRateData.affiliate_growth_rate_data.growth_rate_count;
            const total = percentages.reduce((acc, curr) => curr, 0);

            setTotalPercentage(total);
        }
    }, [growthRateData]);

    const remainingPercentage = totalPercentage;

    var options = {
        chart: {
            type: 'donut'
        },

        plotOptions: {
            pie: {
                expandOnClick: true,

            },

        },

        series: growthRateData?.affiliate_growth_rate_data?.growth_rate_count ?? [],
        chartOptions: {
            labels: []
        },
        tooltip: {
            enabled: false
        },
        legend: {
            show: false
        }

    }

    const handleDateChange = (e) => {
        setDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Growth Rate Report', 10, 10);
        const growthRateDatagraph = growthRateData?.affiliate_growth_rate_data?.growth_rate_count || [];
        const tableData = growthRateDatagraph.map((value, index) => [index + 1, `${value}`]);
        doc.autoTable({
            startY: 20,
            head: [['#', 'Growth Rate']],
            body: tableData,
        });

        doc.save('Growth rate.pdf');
    }
    return (
        <div>
            <div className="rightpie-div">
                <div>
                    <h3 className="growthrate">Growth rate</h3>
                </div>
                <div className="calender-div">
                    <div onClick={generatePDF} style={{ marginRight: "10px", cursor: "pointer" }}>
                        <img width={20} height={20} src={pdficon} alt="img" />
                    </div>
                    <TextField type="date" name='date' value={date} onChange={handleDateChange} sx={{
                        width: "120px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px",
                        }
                    }} />
                </div>

            </div>


            <div id="chart">
                <ReactApexChart options={options} series={options.series} type="donut" height={240} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: 'auto' }}>
                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>0%</p>
                </div>

                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>{remainingPercentage}%</p>
                </div>
            </div>

            <h6 class="coupensold">{growthRateData.growth_rate_str}</h6>
        </div >
    )
}

export default AffliateGrowthChart