import React, { useState } from 'react'
import testomialpic from '../../../Assests/images/testomonialpic.png'
import bannerimg from '../../../Assests/images/bannerimg.png'

import { Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material'

const bannersdata = [
    {
        id: 1, pic: bannerimg, province: "Ontario", banners: 2300, value: 18,
    },

    {
        id: 2, pic: bannerimg, province: "British Columbia", banners: 2300, value: 8,
    },
    {
        id: 3, pic: bannerimg, province: "Alberta", banners: 2300, value: 4,
    },
    {
        id: 4, pic: bannerimg, province: "Winipeg", banners: 2300, value: 13,
    },
    {
        id: 5, pic: bannerimg, province: "Winipeg", banners: 2300, value: 13,
    },

]

const Couponsactivity = () => {
    const [coupounData, setCoupounData] = useState(bannersdata)

    return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
            <div className="testimonialhead">
                <div>
                    <h3 className="growthrate">Coupons activity</h3>
                </div>
                <div>

                    <TextField type="date" sx={{
                        width: "140px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px",
                        }
                    }} />


                </div>
            </div>
            <Grid container direction={"row"} alignItems={"center"}>
                {coupounData.map((banner) => (
                    <React.Fragment key={banner.id}>
                        <Grid item xs={8}>
                            <Stack direction={"row"} spacing={2}>
                                <Box sx={{ borderRadius: "50%" }}>
                                    <img width={44} height={44} style={{ borderRadius: "50%" }} src={banner.pic} alt="" />
                                </Box>

                                <Stack direction={"column"} spacing={0.5}>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "15px", color: "#000000", fontWeight: "500" }}>
                                        {banner.province}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                        {banner.banners} Coupons sold
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#45A843", fontWeight: "500" }}>
                                {banner.value}%
                            </Typography>
                        </Grid>

                        <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 2 }} />
                    </React.Fragment>
                ))}

            </Grid>

        </div>
    )
}

export default Couponsactivity