import Axios from "axios";
import { backendUrl } from "../utils";
let getProvincesListApi = "/dashboard/listProvinces";
let provinceApi = "/dashboard/province";

const ProvincesApi = {

    getAllProvincesList(params, token) {
        return Axios({
            url: backendUrl + getProvincesListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    deleteProvince(params, token) {
        return Axios({
            url: backendUrl + provinceApi + params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getSingleProvince(params, token) {
        return Axios({
            url: backendUrl + provinceApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    UpdateProvince(id, params, token) {
        return Axios({
            url: backendUrl + provinceApi + id,
            method: 'put',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },



    createProvince(params, token) {
        return Axios({
            url: backendUrl + provinceApi,
            method: 'post',
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },


}

export default ProvincesApi;
