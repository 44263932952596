import { Box, CircularProgress, Grid, Paper, Typography, styled, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import ExpensesApi from '../../../apis/ExpensesApi';
import searchicon from '../../../Assests/images/search-normal.png'
import CloseIcon from '@mui/icons-material/Close';
import './expenses.css'
import { Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const TotalExpenses = () => {
    const [totalExpensesList, setTotalExpensesList] = useState([])
    const [totalExpensesListData, setTotalExpensesListData] = useState({})
    const [loading, setLoading] = useState(false)
    const [addExpensesDialogOpen, setAddExpensesDialogOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const rowsPerPage = 10;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };


    const handleClose = () => {
        setAddExpensesDialogOpen(false);
    };

    const startIndex = (currentPage - 1) * rowsPerPage;
    useEffect(() => {
        getAllTotalExpenses()
    }, [rowsPerPage, currentPage, searchQuery])


    let token = localStorage.getItem("token")

    const getAllTotalExpenses = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`

        if (searchQuery) {
            payload += `&email=${searchQuery}`;
        } 
        ExpensesApi.getTotalExpesesList(payload, token)
            .then(response => {
                setTotalExpensesList(response.results)
                setTotalExpensesListData(response)
                if (response.extra_flag.toString() === "true") {
                    setAddExpensesDialogOpen(true)
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    return (
        <>
            <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: "20px !important", width: '100%', overflow: 'auto' }}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>

                    <Typography className='infromation' sx={{ mb: 2 }}>Customer Expense</Typography>
                    <TextField
                        type='text'
                        sx={{ width: { lg: "250px", xs: "160px" }, mr: 1 ,mb:1}}
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img width={18} src={searchicon} alt='locationsvg' />
                                </InputAdornment>
                            ),
                        }}
                        size='small' variant='outlined' color='error'
                    />
                </Stack>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                    <Grid item xs={12}>
                        <Item>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                    <CircularProgress sx={{ color: "#F96064" }} />
                                </Box>
                            ) : (
                                <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                    <Table aria-label="customized table dense">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                                <StyledTableCell align="left">Full Name</StyledTableCell>
                                                <StyledTableCell align="left">Email</StyledTableCell>
                                                <StyledTableCell align="center">Balance</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {totalExpensesList ? (
                                                totalExpensesList.length > 0 ? (
                                                    totalExpensesList.map((row, i) => (
                                                        <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                                {startIndex + i + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.full_name}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.total_price}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={4} align="center">
                                                            Data not found
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center">
                                                        Loading...
                                                    </TableCell>
                                                </TableRow>
                                            )}

                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            )}
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                                <Pagination
                                    count={Math.ceil(totalExpensesListData.count / rowsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        </Item>
                    </Grid>

                </Grid>

            </Box>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={addExpensesDialogOpen}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className='infromation' sx={{ mb: 2 }}>Expense Above or equal 1000</Typography>
                        <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}>
                            <CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} />
                        </Box>

                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <CircularProgress sx={{ color: "#F96064" }} />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                <Table aria-label="customized table dense">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                            <StyledTableCell align="left">Full Name</StyledTableCell>
                                            <StyledTableCell align="left">Email</StyledTableCell>
                                            <StyledTableCell align="center">Balance</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {totalExpensesList ? (
                                            totalExpensesList.length > 0 ? (
                                                totalExpensesList.filter(row => row.total_price >= 1000).length > 0 ? (
                                                    totalExpensesList
                                                        .filter(row => row.total_price >= 1000)
                                                        .map((row, i) => (
                                                            <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                                <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                                    {startIndex + i + 1}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">{row.full_name}</StyledTableCell>
                                                                <StyledTableCell align="left">{row.email}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.total_price}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                ) : (
                                                    <TableRow sx={{ width: "100%", alignItems: "center" }}>
                                                        <TableCell colSpan={4} align="center">
                                                            Data not found
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            ) : (
                                                <TableRow sx={{ width: "100%", alignItems: "center" }}>
                                                    <TableCell colSpan={4} align="center">
                                                        Data not found
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    Loading...
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                    <Button variant='contained' onClick={handleClose}
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                        }} >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </>)

}

export default TotalExpenses