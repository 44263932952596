import React from 'react'
import AccountsDashboard from '../../components/Accounts/Accountdashboard'
import Sidebar from '../../components/Sidbar'
import { Box } from '@mui/material'

const AccountsPage = () => {
  return (
    <div>
      <Sidebar />
      <Box className="main-box">
        <AccountsDashboard />
      </Box>
    </div>
  )
}

export default AccountsPage