import { Box, CircularProgress, Grid, Paper, Typography, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, TextField, InputAdornment, OutlinedInput, IconButton, FormControl, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import ExpensesApi from '../../../apis/ExpensesApi';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import editicon from '../../../Assests/icons/editicon.svg'
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ReactFileReader from 'react-file-reader';
import { toast } from 'react-toastify';
import moment from 'moment';
import './expenses.css'
import { BootstrapDialog, CustomizedTextField, Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';
import jsPDF from 'jspdf';


const Expenses = () => {
    let token = localStorage.getItem("token")
    const [expensesList, setExpensesList] = useState([])
    const [expensesListData, setExpensesListData] = useState({})
    const [loading, setLoading] = useState(false)
    const [deleteDialog, setDeleteDialoge] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [expensesdialogOpen, setExpensisDialogOpen] = useState(false)
    const [updateexpensedialogOpen, setUpdateExpenseDialogeOpen] = useState(false)
    const [updateExpenses, setUpdateExpenses] = useState({});
    const [selectedId, setSelectedId] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [listcountries, setListCountries] = useState([])
    const [country, setCountry] = useState("")
    const [selectedExpenseCategory, setSelectedExpenseCategory] = useState("")
    const [selectedMonth, setSelectedMonth] = useState("")
    const [imageDialoge, setImageDialoge] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showSocialMediaDropdown, setShowSocialMediaDropdown] = useState(false);
    const [selectedSocialMedia, setSelectedSocialMedia] = useState('');




    const [addexpenses, setAddExpenses] = useState({
        invoice_number: "",
        amount: "",
        description: "",
        file_name: "",
        payment_file: null,
        name: "",
        date: "",
        country: "Select Country",
        payment_type: "Select payment Type",
    });


    const rowsPerPage = 30;
    const perpage = 1000;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };


    const handleCategoryChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCategory(selectedValue);
        if (selectedValue === 'advertisement') {
            setShowSocialMediaDropdown(true);
        } else {
            setShowSocialMediaDropdown(false);
        }
    };

    const handleSocialMediaChange = (event) => {
        setSelectedSocialMedia(event.target.value);
    };

    const uploadImage = (files) => {
        const file = files.fileList[0];
        setAddExpenses(prevState => ({
            ...prevState,
            file_name: file.name,
            payment_file: file,
            background_img: URL.createObjectURL(file),
        }));

    }

    const upateduploadImage = (files) => {
        const file = files.fileList[0];
        setUpdateExpenses(prevState => ({
            ...prevState,
            file_name: file.name,
            payment_proof: file,
            background_img: URL.createObjectURL(file),
        }));

    }



    const handleExpensesChange = (event) => {
        const { name, value } = event.target;
        setAddExpenses(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUpdateExpensesChange = (event) => {
        const { name, value } = event.target;
        setUpdateExpenses(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleClickImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setImageDialoge(true);
    };

    const handleCloseImage = () => {
        setImageDialoge(false);
    };



    const startIndex = (currentPage - 1) * rowsPerPage;

    useEffect(() => {
        getAllExpenses()
        getAllCountries()
    }, [rowsPerPage, currentPage, selectedExpenseCategory, country, selectedMonth])

    const handleDeleteDialoge = (id) => {
        setDeleteDialoge(true)
        setSelectedId(id)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialoge(false)
    }

    const OpenCreateExpensesDialoge = () => {
        setExpensisDialogOpen(true)
    }

    const handleClose = () => {
        setExpensisDialogOpen(false);
    };

    const handleCloseExpenseDialog = () => {
        setUpdateExpenseDialogeOpen(false)
    }

    const handleExpenseCategoryChange = (e) => {
        setSelectedExpenseCategory(e.target.value)
    }


    const handleCountryChange = (e) => {
        setCountry(e.target.value)
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value)
    }

    const handleClearFilter = () => {
        setSelectedCategory("")
        setSelectedMonth("")
        setCountry("")
        getAllExpenses()
    }




    const handleEditExpensesDialog = (id) => {
        setSelectedId(id)
        setLoadingUpdate(true)
        setUpdateExpenseDialogeOpen(true)
        let rowid = `?id=${id}`
        ExpensesApi.getSingleExpense(rowid, token)
            .then(response => {
                setUpdateExpenses(response.detail)
                setLoadingUpdate(false)

            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    }



    const getAllCountries = () => {
        setLoading(true);
        let payload = `?limit=${perpage}&page=${currentPage}`;
        ExpensesApi.getListCountries(payload, token)
            .then(response => {
                setListCountries(response.results)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    const getAllExpenses = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        // if (country) {
        //     payload = `?country=${country}`;
        // } else if (selectedExpenseCategory) {
        //     payload = `?category=${selectedExpenseCategory}`
        // }
        if (country) {
            payload += `&country=${country}`;
        } else if (selectedExpenseCategory) {
            payload += `&category=${selectedExpenseCategory}`;
        } else if (selectedMonth) { // Assuming selectedMonth holds the value of the selected month
            payload += `&month=${selectedMonth}`;
        }
        ExpensesApi.getListExpeses(payload, token)
            .then(response => {
                setExpensesList(response.results)
                setExpensesListData(response)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };
    const handleDownLoad = () => {
        // Create a new PDF document
        const doc = new jsPDF();

        // Table data
        const tableData = expensesList.map((expense, index) => [
            index + 1,
            expense.name,

            '', // Empty string as payment proof, as it's not clear how it's represented in the PDF
            expense.category,
            expense.amount,
            expense.description,
            expense.country,
            expense.bank_name,
            expense.invoice_no,
            moment(expense.created_at).format('MMM. D, YYYY, h:mm a')
        ]);

        // Table headers
        const headers = [
            'Sr#',
            'Full Name',
            'Payment Proof',
            'Category',
            'Amount',
            'Description',
            'Country',
            'Bank Name',
            'Invoice No',
            'Date'
        ];

        doc.autoTable({
            head: [headers],
            body: tableData,
            startY: 10,
            styles: {
                font: 'Arial',
                fontSize: 8,
            },
        });

        doc.save('expenses.pdf');
    };
    const handleAddExpenses = (e) => {
        e.preventDefault();
        setLoadingCreate(true)
        const formData = new FormData();
        formData.append("name", addexpenses.name);
        formData.append("amount", addexpenses.amount);
        formData.append("converted_amount", addexpenses.converted_amount);
        formData.append("payment_proof", addexpenses.payment_file);
        formData.append("category", selectedCategory);
        formData.append("sub_category", selectedSocialMedia);
        formData.append("description", addexpenses.description);
        formData.append("country", addexpenses.country);
        formData.append("bank_name", addexpenses.payment_type);
        formData.append("invoice_no", addexpenses.invoice_number);
        formData.append("updated_at", addexpenses.date);
        formData.append("start_date", addexpenses.start_date ? addexpenses.start_date : "");
        formData.append("end_date", addexpenses.end_date ? addexpenses.end_date : "");

        ExpensesApi.addExpenses(formData, token)
            .then(response => {
                toast.success("Expenses Add  Successfully", { autoClose: 1400 });
                getAllExpenses()
                setLoadingCreate(false);
                setExpensisDialogOpen(false);
                setAddExpenses({})
            })
            .catch(error => {
                if (error.response) {
                    setLoadingCreate(false)
                    toast.error("Something went wrong.", { autoClose: 1400 });
                } else {
                    toast.error("Something went wrong.", { autoClose: 1400 });
                    setLoadingCreate(false)

                }
                console.error("Something went wrong:", error);
                setLoadingCreate(false)

            });
    }

    const handleUpdateExpenses = (e) => {
        e.preventDefault();
        setLoadingUpdate(true);
        const formData = new FormData();
        formData.append("name", updateExpenses.name);
        formData.append("amount", updateExpenses.amount);
        // formData.append("payment_proof", updateExpenses.payment_file);
        formData.append("category", updateExpenses.category);
        formData.append("description", updateExpenses.description);
        formData.append("country", updateExpenses.country);
        formData.append("bank_name", updateExpenses.bank_name);
        formData.append("invoice_no", updateExpenses.invoice_no);
        formData.append("updated_at", updateExpenses.created_at);
        formData.append("start_date", updateExpenses.start_date);
        formData.append("end_date", updateExpenses.end_date);
        formData.append("sub_category", updateExpenses.sub_category);
        formData.append("converted_amount", updateExpenses.converted_amount);

        if (updateExpenses.payment_proof instanceof File) {
            formData.append("payment_proof", updateExpenses.payment_proof);
        }
        let rowid = `?id=${selectedId}`
        ExpensesApi.UpdateExpenses(rowid, formData, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                getAllExpenses()
                setLoadingUpdate(false);
                setUpdateExpenseDialogeOpen(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoadingUpdate(false);
            });
    }


    const handleDeleteExpenses = () => {
        setLoadingDelete(true);
        let payload = `?id=${selectedId}`;
        ExpensesApi.deleteExpenses(payload, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                getAllExpenses();
                setLoadingDelete(false);
                setDeleteDialoge(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoadingDelete(false);
            });

    }

    return (
        <>
            <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px", xs: "25px" }, width: '100%', overflow: 'auto' }}>
                <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                        <Typography className='infromation' sx={{ mb: 2 }}>Expenses</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={10} display={"flex"} justifyContent={"flex-end"}>
                        {/* <TextField
                            type='text'
                            sx={{ width: { lg: "250px", xs: "160px" }, mr: 1 }}
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img width={18} src={searchicon} alt='locationsvg' />
                                    </InputAdornment>
                                ),
                            }}
                            size='small' variant='outlined' color='error'
                        /> */}
                        <Stack direction={"row"} spacing={1}>
                            {/* <FormControl sx={{ width: "170px"}}> */}
                            {/* <InputLabel id="demo-simple-select-label">Country</InputLabel> */}

                            <Select
                                name='month'
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                margin='auto'
                                className='province-select'
                                // className='month-select'
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Select Month
                                </MenuItem>
                                <MenuItem value="1">January</MenuItem>
                                <MenuItem value="2">February</MenuItem>
                                <MenuItem value="3">March</MenuItem>
                                <MenuItem value="4">April</MenuItem>
                                <MenuItem value="5">May</MenuItem>
                                <MenuItem value="6">June</MenuItem>
                                <MenuItem value="7">July</MenuItem>
                                <MenuItem value="8">August</MenuItem>
                                <MenuItem value="9">September</MenuItem>
                                <MenuItem value="10">October</MenuItem>
                                <MenuItem value="11">November</MenuItem>
                                <MenuItem value="12">December</MenuItem>
                            </Select>

                            <Select
                                name='country'
                                value={country}
                                onChange={handleCountryChange}
                                // size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // label='Country'
                                size='small'
                                margin='auto'
                                className='province-select'
                                displayEmpty

                            >
                                <MenuItem value="" disabled>
                                    Select Country
                                </MenuItem>
                                <MenuItem value="canada">Canada</MenuItem>
                                <MenuItem value="india">India</MenuItem>
                                <MenuItem value="pakistan">Pakistan</MenuItem>


                            </Select>

                            {/* </FormControl > */}
                            <FormControl sx={{ width: "170px" }}>
                                <Select
                                    size='small'
                                    name='category'
                                    value={selectedExpenseCategory}
                                    onChange={handleExpenseCategoryChange}

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className='province-select'
                                    displayEmpty

                                >
                                    <MenuItem value="" disabled>
                                        Select Category
                                    </MenuItem>
                                    <MenuItem value="office_expenses">Office expenses</MenuItem>
                                    <MenuItem value="salaries">Salaries</MenuItem>
                                    <MenuItem value="traveling">Traveling</MenuItem>
                                    <MenuItem value="miscellaneous">Miscellaneous</MenuItem>
                                    <MenuItem value="advertisement">Advertisement</MenuItem>
                                    <MenuItem value="server">Server</MenuItem>
                                    <MenuItem value="domain">Domain</MenuItem>


                                </Select>
                            </FormControl>
                            <Button
                                variant='contained'
                                sx={{
                                    mb: 1,
                                    textTransform: "capitalize",
                                    width: "130px",
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }}
                                onClick={handleClearFilter}
                            >
                                Clear Filter
                            </Button>
                            <Button
                                variant='contained'
                                sx={{
                                    mb: 1,
                                    textTransform: "capitalize",
                                    // width: "130px",
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }}
                                onClick={handleDownLoad}
                            >
                                Download PDF
                            </Button>
                            <Button
                                variant='contained'
                                sx={{
                                    mb: 1,
                                    textTransform: "capitalize",
                                    width: "130px",
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }}
                                onClick={OpenCreateExpensesDialoge}
                            >
                                Add Expenses
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                    <Grid item xs={12}>
                        <Item>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                    <CircularProgress sx={{ color: "#F96064" }} />
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }}>
                                        <Table aria-label="customized table dense">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                                    <StyledTableCell align="left">Full Name</StyledTableCell>
                                                    <StyledTableCell align="left">Payment Proof</StyledTableCell>
                                                    <StyledTableCell align="center">Category</StyledTableCell>
                                                    <StyledTableCell align="center">Sub Category</StyledTableCell>
                                                    <StyledTableCell align="center">Start Date</StyledTableCell>
                                                    <StyledTableCell align="center">End Date</StyledTableCell>
                                                    <StyledTableCell align="center">Amount</StyledTableCell>
                                                    <StyledTableCell align="center">Description</StyledTableCell>
                                                    <StyledTableCell align="center">Country</StyledTableCell>
                                                    <StyledTableCell align="center">Bank Name</StyledTableCell>
                                                    <StyledTableCell align="center">Invoice No</StyledTableCell>
                                                    <StyledTableCell align="center">Date</StyledTableCell>
                                                    <StyledTableCell align="center">Actions</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {expensesList ? (
                                                    expensesList.length > 0 ? (
                                                        expensesList.map((row, i) => (
                                                            <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                                <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                                    {startIndex + i + 1}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                                <StyledTableCell align="left" onClick={() => handleClickImage(row.payment_proof)}><img src={row.payment_proof} width={50} height={40} alt="Banner" /></StyledTableCell>
                                                                {/* <a href={row.payment_proof} download="generated_file.pdf">Download PDF</a> */}
                                                                <StyledTableCell align="center">{row.category}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.sub_category ? row.sub_category : "N/A"}</StyledTableCell>

                                                                <StyledTableCell align="center"> {moment(row.start_date).format("YYYY-MM-DD")}</StyledTableCell>
                                                                <StyledTableCell align="center"> {row.end_date}</StyledTableCell>
                                                                <StyledTableCell align="center" sx={{ width: "10%" }}>
                                                                    <Stack direction={"column"}>
                                                                        <div> <span style={{ fontSize: "14px", fontWeight: "600" }}>{row.currency} </span>{row.amount}</div>
                                                                        {row.is_convert_amount.toString() === "true" && row.converted_amount ?
                                                                            <span style={{ fontSize: "12px" }}> CA$ ( {row.converted_amount ? row.converted_amount : ""})</span> : null
                                                                        }
                                                                    </Stack>
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center">{row.description}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.country}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.bank_name}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.invoice_no}</StyledTableCell>
                                                                <StyledTableCell align="center"> {row.updated_at}</StyledTableCell>

                                                                <StyledTableCell>
                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                        <Box>
                                                                            <img style={{ cursor: "pointer" }} src={editicon} alt="editicon" onClick={() => handleEditExpensesDialog(row.id)} />
                                                                        </Box>
                                                                        <Box sx={{ color: "red", cursor: "pointer" }}>
                                                                            <DeleteOutlineOutlinedIcon onClick={() => handleDeleteDialoge(row.id)} />
                                                                        </Box>
                                                                    </Stack>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={12} align="center">
                                                                Data not found
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={12} align="center">
                                                            Loading...
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>


                            )}
                            <Stack direction={"row"} justifyContent={"space-between"} mt={1}>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>Total Expenses:</Typography>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}> {expensesListData.currency} {expensesListData.total_cost}</Typography>
                                </Stack>

                                <Stack direction={"row"} spacing={1}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>Monthly Expenses:</Typography>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>{expensesListData.currency} {expensesListData.monthly_cost}</Typography>
                                </Stack>

                            </Stack>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                                <Pagination
                                    count={Math.ceil(expensesListData.count / rowsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>

                        </Item>
                    </Grid>

                </Grid>

            </Box>

            <Dialog
                fullWidth
                maxWidth="xs"
                open={expensesdialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                </DialogTitle>
                {loadingCreate ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <form onSubmit={handleAddExpenses}>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography className='addpartner' sx={{ mb: 4 }}>Add Expenses</Typography>

                                    <Stack direction={"column"} spacing={2}>
                                        <FormControl fullWidth required>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                name='category'
                                                value={selectedCategory}
                                                onChange={handleCategoryChange}
                                                size='small'
                                                label="Category"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className='province-select'
                                                displayEmpty

                                            >
                                                <MenuItem value="select" disabled>
                                                    Select Category
                                                </MenuItem>
                                                <MenuItem value="office_expenses">Office expenses</MenuItem>
                                                <MenuItem value="salaries">Salaries</MenuItem>
                                                <MenuItem value="traveling">Traveling</MenuItem>
                                                <MenuItem value="miscellaneous">Miscellaneous</MenuItem>
                                                <MenuItem value="advertisement">Advertisement</MenuItem>
                                                <MenuItem value="server">Server</MenuItem>
                                                <MenuItem value="domain">Domain</MenuItem>

                                            </Select>
                                            {showSocialMediaDropdown && (
                                                <FormControl fullWidth sx={{ mt: 2 }} required >
                                                    <InputLabel id="social-media-select-label">Social Media</InputLabel>

                                                    <Select

                                                        name='socialMedia'
                                                        value={selectedSocialMedia}
                                                        onChange={handleSocialMediaChange}
                                                        size='small'
                                                        label="Social Media"
                                                        labelId="social-media-select-label"
                                                        id="social-media-select"
                                                        className='province-select'
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="facebook">Facebook</MenuItem>
                                                        <MenuItem value="twitter">Twitter</MenuItem>
                                                        <MenuItem value="instagram">Instagram</MenuItem>
                                                        <MenuItem value="linkedin">LinkedIn</MenuItem>
                                                        <MenuItem value="snapchat">Snapchat</MenuItem>
                                                        <MenuItem value="pinterest">Pinterest</MenuItem>
                                                        <MenuItem value="tiktok">TikTok</MenuItem>
                                                        <MenuItem value="reddit">Reddit</MenuItem>
                                                        <MenuItem value="youtube">YouTube</MenuItem>
                                                        <MenuItem value="whatsapp">WhatsApp</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </FormControl>
                                        <CustomizedTextField required name="name" label="Name" value={addexpenses.name} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Your Name here' />
                                        <CustomizedTextField required name="invoice_number" label="Invoice no" value={addexpenses.invoice_number} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Invoice number' />
                                        <CustomizedTextField required name="amount" label="Amount" value={addexpenses.amount} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Type amount' />
                                        <CustomizedTextField required name="converted_amount" label="Converted Amount" value={addexpenses.converted_amount} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Type Converted Amount' />
                                        <CustomizedTextField required name="description" label="Description" value={addexpenses.description} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Description' />
                                        {selectedCategory === "server" || selectedCategory === "domain" ?
                                            <Stack direction={"row"} spacing={1}>
                                                <CustomizedTextField required name="start_date" label="Start Date" value={addexpenses.start_date || ""} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' type='date' />
                                                <CustomizedTextField required name="end_date" label="End Date" value={addexpenses.end_date || ""} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' type='date' />
                                            </Stack>
                                            : null
                                        }

                                        <CustomizedTextField required name="date" label="Date" value={addexpenses.date || ""} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' type='date' />
                                        <FormControl fullWidth required>
                                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                            <Select
                                                name='country'
                                                value={addexpenses.country}
                                                onChange={handleExpensesChange}
                                                size='small'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label='Country'
                                                className='province-select'
                                                displayEmpty

                                            >
                                                <MenuItem value={addexpenses.country} disabled>
                                                    Select Country
                                                </MenuItem>
                                                <MenuItem value="canada">Canada</MenuItem>
                                                <MenuItem value="india">India</MenuItem>
                                                <MenuItem value="pakistan">Pakistan</MenuItem>


                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth required>
                                            <InputLabel id="demo-simple-select-label">Select Payment Type</InputLabel>
                                            <Select
                                                name='payment_type'
                                                value={addexpenses.payment_type}
                                                onChange={handleExpensesChange}
                                                size='small'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label='Select Payment Type'
                                                className='province-select'
                                                displayEmpty

                                            >
                                                <MenuItem value={addexpenses.payment_type} disabled>
                                                    Select Payment Type
                                                </MenuItem>
                                                <MenuItem value="westrenunion">Western Union</MenuItem>
                                                <MenuItem value="local">Local</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            id="upload-payment"
                                            className='outlinedinput'
                                            value={addexpenses.file_name}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg",".pdf"]} base64={true} handleFiles={uploadImage}>
                                                            <IconButton aria-label="upload payment proof">
                                                                <InsertPhotoOutlinedIcon />
                                                            </IconButton>
                                                        </ReactFileReader>
                                                    </InputAdornment>
                                                ),
                                            }} fullWidth variant='outlined' size='small' label="Upload payment" placeholder='Upload payment proof' />
                                    </Stack>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                                <Button type='submit' variant='contained'
                                    sx={{
                                        backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                        color: 'white',
                                    }} fullWidth>
                                    Add expenses

                                </Button>
                            </DialogActions>
                        </form>
                    </>
                )}

            </Dialog>



            <Dialog
                fullWidth
                maxWidth="xs"
                open={updateexpensedialogOpen}
                onClose={handleCloseExpenseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleCloseExpenseDialog} /></Box>
                </DialogTitle>
                {loadingUpdate ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography className='addpartner' sx={{ mb: 4 }}>Update expenses</Typography>

                                <Stack direction={"column"} spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                        <Select
                                            name='category'
                                            value={updateExpenses.category}
                                            onChange={handleUpdateExpensesChange}
                                            size='small'
                                            label="Category"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value="select" disabled>
                                                Select Category
                                            </MenuItem>
                                            <MenuItem value="office_expenses">Office expenses</MenuItem>
                                            <MenuItem value="salaries">Salaries</MenuItem>
                                            <MenuItem value="traveling">Traveling</MenuItem>
                                            <MenuItem value="miscellaneous">Miscellaneous</MenuItem>
                                            <MenuItem value="advertisement">Advertisement</MenuItem>
                                            <MenuItem value="server">Server</MenuItem>
                                            <MenuItem value="domain">Domain</MenuItem>

                                        </Select>

                                        {updateExpenses.category === "advertisement" && (
                                            <FormControl fullWidth sx={{ mt: 2 }} required >
                                                <InputLabel id="social-media-select-label">Social Media</InputLabel>

                                                <Select
                                                    name='sub_category'
                                                    value={updateExpenses.sub_category}
                                                    onChange={handleUpdateExpensesChange}
                                                    size='small'
                                                    label="Social Media"
                                                    labelId="social-media-select-label"
                                                    id="social-media-select"
                                                    className='province-select'
                                                    displayEmpty
                                                >
                                                    <MenuItem value="facebook">Facebook</MenuItem>
                                                    <MenuItem value="twitter">Twitter</MenuItem>
                                                    <MenuItem value="instagram">Instagram</MenuItem>
                                                    <MenuItem value="linkedin">LinkedIn</MenuItem>
                                                    <MenuItem value="snapchat">Snapchat</MenuItem>
                                                    <MenuItem value="pinterest">Pinterest</MenuItem>
                                                    <MenuItem value="tiktok">TikTok</MenuItem>
                                                    <MenuItem value="reddit">Reddit</MenuItem>
                                                    <MenuItem value="youtube">YouTube</MenuItem>
                                                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    </FormControl>

                                    <CustomizedTextField name="name" label="Name" value={updateExpenses.name} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' placeholder='Your Name here' />
                                    <CustomizedTextField name="invoice_no" label="Invoice no" value={updateExpenses.invoice_no} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' placeholder='Invoice number' />
                                    <CustomizedTextField name="amount" label="Amount" value={updateExpenses.amount} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' placeholder='Type amount' />
                                    <CustomizedTextField required name="converted_amount" label="Converted Amount" value={updateExpenses.converted_amount} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' placeholder='Type Converted Amount' />
                                    <CustomizedTextField name="description" label="Description" value={updateExpenses.description} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' placeholder='Description' />
                                    {updateExpenses.category === "server" || updateExpenses.category === "domain" ?
                                        <Stack direction={"row"} spacing={1}>
                                            <CustomizedTextField required name="start_date" label="Start Date" value={updateExpenses.start_date || ""} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' type='date' />
                                            <CustomizedTextField required name="end_date" label="End Date" value={updateExpenses.end_date || ""} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' type='date' />
                                        </Stack>
                                        : null
                                    }
                                    <CustomizedTextField name="created_at" label="Date" value={moment(updateExpenses.created_at).format('YYYY-MM-DD')} onChange={handleUpdateExpensesChange} fullWidth variant='outlined' size='small' type='date' placeholder='Date' />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                        <Select
                                            name='country'
                                            value={updateExpenses.country}
                                            onChange={handleUpdateExpensesChange}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label='Country'
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value={addexpenses.country} disabled>
                                                Select Country
                                            </MenuItem>
                                            <MenuItem value="canada">Canada</MenuItem>
                                            <MenuItem value="india">India</MenuItem>
                                            <MenuItem value="pakistan">Pakistan</MenuItem>

                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select payment</InputLabel>
                                        <Select
                                            name='bank_name'
                                            value={updateExpenses.bank_name}
                                            onChange={handleUpdateExpensesChange}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label='Select payment'
                                            className='province-select'
                                            displayEmpty
                                        >
                                            <MenuItem value={updateExpenses.bank_name} disabled>
                                                Select Payment Type
                                            </MenuItem>
                                            <MenuItem value="westrenunion">Western Union</MenuItem>
                                            <MenuItem value="local">Local</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="upload payment"
                                        className='outlinedinput'
                                        value={updateExpenses.payment_proof instanceof File ? updateExpenses.file_name : updateExpenses.payment_proof}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={upateduploadImage}>
                                                        <IconButton aria-label="upload payment proof">
                                                            <InsertPhotoOutlinedIcon />
                                                        </IconButton>
                                                    </ReactFileReader>
                                                </InputAdornment>
                                            ),
                                        }} fullWidth variant='outlined' size='small' />
                                </Stack>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                            <Button onClick={handleUpdateExpenses} variant='contained'
                                sx={{
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }} fullWidth>
                                Update expenses

                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>


            <BootstrapDialog
                fullWidth
                maxWidth="xs"
                onClose={handleCloseDeleteDialog}
                open={deleteDialog}

            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
                    Delete
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>Are you sure you want to delete?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
                        Close
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                            textTransform: "capitalize"
                        }}
                        onClick={handleDeleteExpenses}
                        disabled={loadingDelete}
                    >
                        {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <Dialog fullWidth maxWidth="sm" open={imageDialoge} onClose={handleCloseImage}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <DialogTitle>Image Preview</DialogTitle>
                    <Box sx={{ cursor: "pointer", marginRight: "15px" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleCloseImage} /></Box>
                </Stack>
                <DialogContent>
                    {selectedImage && <img width="100%" height={"500"} src={selectedImage} alt="Preview" />}
                </DialogContent>
            </Dialog>

        </>

    )
}

export default Expenses