import Axios from "axios";
import { backendUrl } from "../utils";


let getBannerListApi = "/dashboard/listBanners";
// let deleteFaqApi = "/dashboard/faq";
// let getsingleFaqApi = "/dashboard/faq"
// let updateFaqApi = "/dashboard/faq"
let createBannerApi = "/dashboard/banner"
let deleteBannerApi = "/dashboard/banner"


const BannerApi = {

    getAllBannerList(params, token) {
        return Axios({
            url: backendUrl + getBannerListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    deleteBanner(params, token) {
        return Axios({
            url: backendUrl + deleteBannerApi + params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    // getSingleFaq(params, token) {
    //     return Axios({
    //         url: backendUrl + getsingleFaqApi + params,
    //         method: 'get',
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `token ${token}`,
    //         }
    //     }).then(response => {
    //         return response.data
    //     })
    // },


    // UpdateFaq(id, params, token) {
    //     return Axios({
    //         url: backendUrl + updateFaqApi + id,
    //         method: 'put',
    //         data: params,
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `token ${token}`,
    //         }
    //     }).then(response => {
    //         return response.data
    //     })
    // },



    createBanner(params, token) {
        return Axios({
            url: backendUrl + createBannerApi,
            method: 'post',
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },


}

export default BannerApi;
