import React from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { TextField } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AdRevenueGraph = ({ revenueData, revenueDate, setReveneDate }) => {


    let catagories = revenueData?.ads_graph?.ads_day;

    var areaOptions = {
        series: [{
            name: "Desktops",
            data: revenueData?.ads_graph?.ads_count,

        }],
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 3,
                opacityFrom: 1,
                opacityTo: 2,
                stops: [0, 60],
                colorStops: [
                    {
                        offset: 0,
                        color: '#DDE0FB'
                    },
                    {
                        offset: 80,
                        color: '#FFFFFF'
                    }
                ]
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#ff0000'],
            width: 2

        },
        grid: {
            row: {
                opacity: 0.5
            },
            show: false
        },
        xaxis: {
            categories: catagories,
        },
        tooltip: {
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    '<div class="custom-tooltip">' +
                    '<span class="number">' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                    '<div class="tooltip-line"></div>' +
                    '<span class="units">' + series[seriesIndex][dataPointIndex] + ' banners</span>' +
                    '</div>'
                );
            }
        }

    };

    const HandleDateChange = (e) => {
        setReveneDate(e.target.value)
    }


    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Ads Revenue Report', 10, 10);
        const adsData = revenueData?.ads_graph?.ads_count || [];
        const adsDays = revenueData?.ads_graph?.ads_day || [];
        const tableData = adsData.map((count, index) => [adsDays[index], count]);

        doc.autoTable({
            head: [['Day', 'Ads Count']],
            body: tableData,
        });

        doc.save('ads_revenue_report.pdf');
    }


    return (
        <div>
            <div className="graph-div">
                <div className="graphinner-div">
                    <div className="dstextdiv">
                        <h3 className="growthrate" style={{ margin: "0px" }}>Ads Revenue</h3>
                    </div>
                    <div className="right-div">
                        <div onClick={generatePDF}>
                            <img src={pdficon} alt="img" />
                        </div>
                        <TextField type="date" name='revenueDate' onChange={HandleDateChange} value={revenueDate} sx={{
                            width: "140px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />

                    </div>
                </div>
                <div id="linechart">
                    <ReactApexChart options={areaOptions} series={areaOptions.series} type="area" height={350} />
                </div>
            </div>
        </div>
    )
}

export default AdRevenueGraph