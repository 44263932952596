import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Paper, Stack, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CoupenApi from '../../../apis/couponsApi';
import TablePagination from '../../TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BootstrapDialog, Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const Slots = ({ setShowSlot, userID, slotId }) => {
    const navigate = useNavigate()
    const [slotData, setSlotData] = useState([]);
    const [slot, setSlot] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [loadingDraw, setLoadingDraw] = useState(false)
    const [drawDialog, setDrawDialog] = useState(false)
    const [slotID, setSlotID] = useState(null)
    const rowsPerPage = 20;
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleCloseDrawDialog = () => {
        setDrawDialog(false)
    }

    const handleDrawDialoge = (id) => {
        setDrawDialog(true)
        setSlotID(id);
    }
    const token = localStorage.getItem("token")
    const getAllSlots = () => {
        setLoading(true);
        let payload = `/${slotId}/${userID}?limit=${rowsPerPage}&page=${currentPage}`;
        CoupenApi.getSlot(payload, token)
            .then(response => {
                setSlotData(response.results);
                setSlot(response)
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.detail, { autoClose: 1400 });
                    navigate("/");
                } else {
                    console.log("Error:", error);
                }
            });
    };

    const handleDraw = () => {
        setLoadingDraw(true)
        let rowid = `/${slotID}/`;
        CoupenApi.LuckyDraw(rowid, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                setLoadingDraw(false)
                getAllSlots();
                setDrawDialog(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoadingDraw(false);
            });
    }

    useEffect(() => {
        getAllSlots()
    }, [currentPage, rowsPerPage])

    const startIndex = (currentPage - 1) * rowsPerPage;
    return (
        <Box sx={{ padding: { lg: "25px", xs: "0px" },paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>

            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography className='infromation' sx={{ mb: 2 }}>Slots</Typography>
                <Button variant="outlined" color='error' size='small' sx={{ mb: 2 }} onClick={() => setShowSlot(false)}>Back</Button>
            </Stack>
            <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                <Grid item xs={12}>
                    <Item>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <CircularProgress sx={{ color: "#F96064" }} />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                <Table aria-label="customized table dense">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                            <StyledTableCell align="left">Title(coupon)</StyledTableCell>
                                            <StyledTableCell align="left">Full name</StyledTableCell>
                                            <StyledTableCell align="left">Email</StyledTableCell>
                                            <StyledTableCell align="left">Province</StyledTableCell>
                                            <StyledTableCell align="left">Price</StyledTableCell>
                                            <StyledTableCell align="left">Code</StyledTableCell>
                                            <StyledTableCell align="left">Lucky</StyledTableCell>
                                            <StyledTableCell align="right">Actions</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {slotData && slotData.length > 0 ? (
                                            slotData.map((row, i) => (
                                                <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                    <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                        {startIndex + i + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">{row.bet.title}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.user.full_name}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.user.email}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.province}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.price}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.code}</StyledTableCell>
                                                    <StyledTableCell align="left"><span style={{ backgroundColor: row.is_luckey.toString() === "true" ? "#DAE6D1" : "#FCD0D1", color: row.is_luckey.toString() === "true" ? "#45A843" : "#F33F41", padding: "3px 10px", borderRadius: "4px", fontWeight: "600", fontSize: "12px" }}>{row.is_luckey.toString()}</span></StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {row.is_luckey.toString() === "false" ? (
                                                            <Button variant='text' color='secondary' size='small'>
                                                                <span style={{
                                                                    backgroundColor: "#45A843",
                                                                    color: "#FFFFFF",
                                                                    padding: "3px 10px",
                                                                    borderRadius: "4px",
                                                                    fontSize: "12px",
                                                                    // fontWeight: "600",
                                                                    fontFamily: "Poppins",
                                                                    textTransform: "capitalize"
                                                                }}
                                                                    onClick={() => handleDrawDialoge(row.id)}>
                                                                    Draw
                                                                </span>
                                                            </Button>
                                                        ) : (
                                                            row.is_luckey ? <Typography sx={{
                                                                color: "#45A843", fontSize: "12px",
                                                                fontFamily: "Poppins",
                                                                padding: "3px 10px",
                                                                fontWeight: "600"

                                                            }}>Lucky</Typography> : null
                                                        )}
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <StyledTableCell colSpan={7} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}



                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                            <TablePagination
                                totalItems={slot}
                                itemsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </Box>
                    </Item>
                </Grid>

            </Grid>


            <BootstrapDialog
                fullWidth
                maxWidth="xs"
                onClose={handleCloseDrawDialog}
                open={drawDialog}

            >

                <DialogContent>

                    <Typography sx={{ textAlign: "center", p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }}>Are you sure ?</Typography>

                </DialogContent>
                <DialogActions>
                    <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDrawDialog}>
                        No
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                            textTransform: "capitalize"
                        }}
                        onClick={handleDraw}
                        disabled={loadingDraw}
                    >
                        {loadingDraw ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Change"}
                    </Button>
                </DialogActions>

            </BootstrapDialog>
        </Box>
    )
}

export default Slots