import Axios from "axios";
import { backendUrl } from "../utils";

let getFaqsList = "/dashboard/listFaqs";
let FaqApi = "/dashboard/faq";
let getGeneralApi = '/dashboard/get_general_model';
let postGeneralSettingsApi = '/dashboard/update_general_model/'

const FaqsApi = {

    getAllFaqsList(params, token) {
        return Axios({
            url: backendUrl + getFaqsList + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    deleteFaq(params, token) {
        return Axios({
            url: backendUrl + FaqApi + params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getSingleFaq(params, token) {
        return Axios({
            url: backendUrl + FaqApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    UpdateFaq(id, params, token) {
        return Axios({
            url: backendUrl + FaqApi + id,
            method: 'put',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    PostFaqs(params, token) {
        return Axios({
            url: backendUrl + FaqApi,
            method: 'post',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },

    getGeneralSetting(params) {
        return Axios({
            url: backendUrl + getGeneralApi + params,
            method: 'get',
            // data: params,
            headers: {
                'content-type': 'application/json',
                // Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    PostGeneralSettings(params, token) {
        return Axios({
            url: backendUrl + postGeneralSettingsApi,
            method: 'post',
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },




}

export default FaqsApi;
