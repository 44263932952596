import React, { useEffect, useState } from 'react'
import './index.css'
import { Button, Grid, MenuItem, Paper, Select, Stack, Typography, styled } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TotalExpenses from './TotalExpenses';
import TransactionsCard from './TransactionsTable';
import ExpenseRate from './ExpenseRate';
import ExpensesCard from './ExpensesCard';
import AddExpenses from './AddExpenses';
import AdsExpenserateCard from './AdsExpenserateCard';
import PartnersSettlements from './PartnerSettlements';
import TransactionsTable from './TransactionsTable';
import PaymentsApi from '../../../apis/PaymentsApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProvincesApi from '../../../apis/provincesApi';
import PartnersApi from '../../../apis/PartnersApi';
import { CustomizedTextField, Item } from '../../../style/commonStyle';


const Payments = () => {
  const navigate = useNavigate()
  const [addpartner, setAddPartners] = useState(false)
  const [loading, setLoading] = useState(false)
  const [paymentsList, setPaymentsList] = useState([])
  const [expenseRateData, setExpenseRateData] = useState({})
  const [expenseRateDate, setExpenseRateDate] = useState(null)
  const [totalExpenseData, setTotalExpenseData] = useState({})
  const [date, setDate] = useState("")
  const [transactiondate, setTransactionDate] = useState("")
  const [adsExpenseGrowthGraphData, setAdsExpenseGrowthGraphData] = useState({})
  const [adsExpenseGrowthGraphDate, setAdsExpenseGrowthGraphDate] = useState(null)
  const [adsExpenseData, setAdsExpenseData] = useState({})
  const [adsExpenseDate, setAdsExpenseDate] = useState(null)
  const [selectedprovince, setSelectedProvince] = useState("")
  const [addPartnerDetail, setAddPartnerDetail] = useState({})
  const [addPartnerdialogOpen, setAddPartnerdialogOpen] = useState(false);
  const [provinces, setProvinces] = useState([])
  const [userslist, setUserList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const rowsPerPage = 10;
  const limits = 100;
  const userslimit = 1000;
  const [expenses, setExpenses] = useState("");
  const handleAddPartner = () => {
    setAddPartners(true);
  }

  // useEffect(() => {
  //   getAdsExepenseGrowthGraph()
  //   getTotalExpenses()
  //   getPaymentsLists()
  // }, [date])

  const handleAddPartnerDetailChange = (event) => {
    const { name, value } = event.target;
    setAddPartnerDetail((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handlePartnerDialogeOpen = () => {
    setAddPartnerdialogOpen(true);
  };

  const handleClose = () => {
    setAddPartnerdialogOpen(false);
  };


  useEffect(() => {
    getAdsExpenseGraph()
  }, [adsExpenseDate, selectedprovince])

  useEffect(() => {
    getPaymentsLists()
    getAdsExepenseGrowthGraph()
    getExpenseRate()
    getTotalExpenses()
  }, [date, expenseRateDate, selectedprovince, transactiondate, Page])

  useEffect(() => {
    getAllProvinces()
    getAllUsers()
  }, [])

  const token = localStorage.getItem("token")

  const getAllUsers = () => {
    setLoading(true);
    let payload = `?limit=${userslimit}`;
    PartnersApi.getAllUserListList(payload, token)
      .then(response => {
        setUserList(response.results);
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };

  const getPaymentsLists = () => {
    setLoading(true);

    let payload = `?limit=${rowsPerPage}&page=${Page}`

    if (transactiondate || selectedprovince) {
      payload = `?limit=${rowsPerPage}&selected_date=${transactiondate}&province=${selectedprovince}`
    }
    PaymentsApi.getPaymentsList(payload, token)
      .then(response => {
        setPaymentsList(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Somthing went Wrong", error);
        }
      })
  };

  const getTotalExpenses = () => {
    setLoading(true);
    let paylaod = ""
    if (date || selectedprovince) {
      paylaod = `?selected_date=${date}&province=${selectedprovince}`
    }
    PaymentsApi.getTotalExpense(paylaod, token)
      .then(response => {
        setTotalExpenseData(response);
        setLoading(false);
      })
      .catch(error => {

      })
  };

  const getExpenseRate = () => {
    setLoading(true);
    let paylaod = ""
    if (expenseRateDate) {
      paylaod = `?selected_date=${expenseRateDate}`
    }
    PaymentsApi.getExpenseRate(paylaod, token)
      .then(response => {
        setExpenseRateData(response);
        setLoading(false);
      })
      .catch(error => {

      })
  };

  const getAdsExepenseGrowthGraph = () => {
    setLoading(true);
    let paylaod = ""
    if (adsExpenseGrowthGraphDate || expenses) {
      paylaod = `?selected_date=${adsExpenseGrowthGraphDate}&expanse_type=${expenses}`
    }
    PaymentsApi.getAdsExpenseGrowthApi(paylaod, token)
      .then(response => {
        setAdsExpenseGrowthGraphData(response);
        setLoading(false);
      })
      .catch(error => {

      })
  };


  const getAdsExpenseGraph = () => {
    setLoading(true);
    let paylaod = ""
    if (adsExpenseDate || selectedprovince) {
      paylaod = `?selected_date=${adsExpenseDate}&province=${selectedprovince}`
    }
    PaymentsApi.getAdsExpenseApi(paylaod, token)
      .then(response => {
        setAdsExpenseData(response);
        setLoading(false);
      })
      .catch(error => {

      })
  };


  const getAllProvinces = () => {
    setLoading(true);
    let payload = `?limit=${limits}&page=${currentPage}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };



  const handleCreatePartner = () => {
    let payload = {
      user: addPartnerDetail.users,
      name: addPartnerDetail.stote_name,
      address: addPartnerDetail.address,
      contact: addPartnerDetail.contact,
    }
    PartnersApi.addPartner(payload, token)
      .then(response => {
        toast.success("Partner Added Succesfully", { autoClose: 1400 });
        setAddPartnerDetail({})
        setAddPartnerdialogOpen(false)
      })
      .catch(error => {
        console.error("Something went wrong:", error);
      });
  }


  return (
    <>
      {
        addpartner ?
          <PartnersSettlements addpartner={addpartner} setAddPartners={setAddPartners} />
          :
          <div className='maininformation'>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography className='infromation' sx={{ mb: 2 }}>Payments</Typography>
              <Stack direction={"row"} spacing={2}>
                <Button variant='outlined' color='error' size='small' sx={{ textTransform: "capitalize" }} onClick={handleAddPartner}>Partner settlements </Button>
                <Button variant='contained' color='error' size='small' sx={{ textTransform: "capitalize" }} onClick={handlePartnerDialogeOpen}>Add Partners</Button>
              </Stack>

            </Stack>
            <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>
              <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
                <Grid item xs={12}>
                  <Item>
                    <TotalExpenses totalExpenseData={totalExpenseData} provinces={provinces} date={date} setDate={setDate} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} />
                  </Item>
                </Grid>

                <Grid item xs={12}>
                  <Item>
                    <TransactionsTable loading={loading} provinces={provinces} transactiondate={transactiondate} setTransactionDate={setTransactionDate} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} paymentsList={paymentsList} Page={Page} rowsPerPage={rowsPerPage} setPage={setPage} />
                  </Item>
                </Grid>

                <Grid item xs={12}>
                  <Item>
                    <AddExpenses adsExpenseData={adsExpenseData} setAdsExpenseDate={setAdsExpenseDate} expenseRateDate={expenseRateDate} provinces={provinces} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} />
                  </Item>
                </Grid>

              </Grid>


              {/* Right Section */}
              <Grid item container xs={12} rowGap={2} sm={6} md={4} >
                <Grid item xs={12}>
                  <Item> <ExpenseRate expenses={expenses} setExpenses={setExpenses} adsExpenseGrowthGraphData={adsExpenseGrowthGraphData} adsExpenseGrowthGraphDate={adsExpenseGrowthGraphDate} setAdsExpenseGrowthGraphDate={setAdsExpenseGrowthGraphDate} /> </Item>
                </Grid>

                <Grid item xs={12}>
                  <Item> <ExpensesCard /> </Item>
                </Grid>

                <Grid item xs={12}>
                  <Item> <AdsExpenserateCard expenseRateData={expenseRateData} expenseRateDate={expenseRateDate} setExpenseRateDate={setExpenseRateDate} /> </Item>
                </Grid>

              </Grid>
            </Grid>

            <Dialog
              fullWidth
              maxWidth="xs"
              open={addPartnerdialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography className='addpartner' sx={{ mb: 4 }}>Add Partner</Typography>
                  <Stack direction={"column"} spacing={2}>
                    <Select
                      name='users'
                      value={addPartnerDetail.users}
                      onChange={handleAddPartnerDetailChange}
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder='users'
                      className='province-select'
                      displayEmpty
                    >

                      <MenuItem value="" disabled>Select Users</MenuItem>
                      {userslist.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.username}</MenuItem>
                      ))}

                    </Select>
                    {/* <CustomizedTextField fullWidth variant='outlined' name='name' value={addPartnerDetail.name} onChange={handleAddPartnerDetailChange} size='small' placeholder='Your Name here' /> */}
                    <CustomizedTextField fullWidth variant='outlined' name='address' value={addPartnerDetail.address} onChange={handleAddPartnerDetailChange} size='small' placeholder='Address here' />
                    <CustomizedTextField fullWidth variant='outlined' name='contact' value={addPartnerDetail.contact} onChange={handleAddPartnerDetailChange} size='small' placeholder='Contact here' />
                    <CustomizedTextField fullWidth variant='outlined' name='stote_name' value={addPartnerDetail.stote_name} onChange={handleAddPartnerDetailChange} size='small' placeholder='Store name' />

                  </Stack>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                <Button variant='contained' onClick={handleCreatePartner}
                  sx={{
                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                    color: 'white',
                  }} fullWidth>
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </div >
      }
    </>
  )
}

export default Payments