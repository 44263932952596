import { Dialog, Paper, Select, TableCell, TableRow, TextField, createTheme, styled, tableCellClasses } from "@mui/material";

 export const CustomizedTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'pink',
        },
        '&:hover fieldset': {
            borderColor: '#FA656A',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FA656A',
        },
    },
});

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    boxShadow: '0px 1px 20px rgba(39, 90, 252, 0.1)',
    ...theme.typography.body2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),

    },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: '12px',
        fontWeight: 600,
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '14px',
        fontFamily: "Poppins",
        color: "#686868",
        border: 0,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(odd)': {
        backgroundColor: '#FFF5F5',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
    '&.MuiSelect-root': {
      backgroundColor: "#F8F8F8",
      borderRadius: "0.75rem",
      border: 'none', 
      transition: 'border-color 0.3s ease', 
      '&:hover': {
        borderColor: 'red', 
      },
    },
    '& .MuiInputBase-input': {
      padding: '8px',
    },
    '&.Mui-focused': {
      '&.MuiSelect-root': {
        borderColor: 'transparent', 
        borderLeft: "none", 
      },
    },
  }));


  export const customTheme = createTheme({
    palette: {
      primary: {
        main: '#EEEEEE',
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover': {
              borderColor: '#EEEEEE !important', 
              border: "1px solid red"
            },
          },
          focused: {
            '&:active': {
              backgroundColor: '#EEEEEE !important', 
              border: "1px solid red"
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input': {
              color: '#949494',
              borderColor: "red"
            },
            borderRadius: '10px', 
          },
        },
      },
    },
  });
