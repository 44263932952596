import React from 'react'
import Sidebar from '../../components/Sidbar'
import { Box } from '@mui/material'
import Banner from '../../components/Information/Banner'

const BannerPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <Banner />
            </Box>
        </div>
    )
}

export default BannerPage