import React from 'react'
import Sidebar from '../../../components/Sidbar'
import { Box } from '@mui/material'
import Payments from '../../../components/Accounts/payments'
import Partners from '../../../components/Accounts/Partners'
import MyBank from '../../../components/Accounts/MyBanks'

const MyBankPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <MyBank />
            </Box>
        </div>
    )
}

export default MyBankPage