import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';



function TablePagination({ totalItems, itemsPerPage, onPageChange, page }) {
  console.log(totalItems, itemsPerPage, onPageChange, page)

  return (
    <>
      <Pagination
        count={Math.ceil(totalItems.count / itemsPerPage)}
        page={page}
        onChange={onPageChange}
        variant="outlined"
        shape="rounded"
      />

    </>
  );
}

export default TablePagination;
