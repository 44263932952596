import React, { useState } from 'react'
import { Box, Divider, Grid, Select, Stack, TextField, Typography, styled } from '@mui/material'
import pdficon from '../../../Assests/images/pdf.png'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const StoreSignUp = ({ storeListData }) => {

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Store Partner Data', 10, 10);
        const tableData = storeListData?.results?.map(row => [
            row.name ? row.name : 'N/A',
            row.address,
        ]) || [];

        doc.autoTable({
            startY: 20,
            head: [['Store Name', 'Address',]],
            body: tableData,
        });

        doc.save('transaction_card_data.pdf');
    };

    return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
            <Grid container direction={"row"} mt={1.5} mb={3}>
                <Grid item xs={4}>
                    <Typography className='parttnershead'>Store sign ups</Typography>
                </Grid>

                <Grid item xs={8} display={"flex"} justifyContent={"flex-end"}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                            <img width={20} height={20} src={pdficon} alt='pdf' />
                        </div>

                        <TextField type="date" sx={{
                            width: "130px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />

                    </Stack>
                </Grid>
            </Grid>


            <Grid container direction={"row"} alignItems={"center"}>
                {storeListData?.results?.length > 0 ? (
                    storeListData.results.map((data) => (
                        <React.Fragment key={data.id}>
                            <Grid item xs={6}>
                                <Stack direction={"row"} spacing={2}>
                                    <Box sx={{ borderRadius: "50%" }}>
                                        <img width={44} height={44} style={{ borderRadius: "50%" }} src={data.logo} alt="logo" />
                                    </Box>
                                    <Stack direction={"column"} spacing={0.5}>
                                        <Typography sx={{ fontFamily: "Poppins", fontSize: "15px", color: "#000000", fontWeight: "500" }}>
                                            {data.name}
                                        </Typography>
                                        <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                            {data.address}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 2 }} />
                        </React.Fragment>
                    ))
                ) : (
                    <Typography sx={{ fontFamily: "Poppins", fontSize: "15px", color: "#000000", fontWeight: "500" }}>
                        No data found
                    </Typography>
                )}


            </Grid>


        </div>
    )
}

export default StoreSignUp