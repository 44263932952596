// export const backendUrl = "https://api.mr-corp.ca"


let domain = window.location.hostname; // Assuming you'll assign the domain dynamically

let backendUrl;

if (domain === 'localhost' || domain === 'admin.mr-corp.ca') {
    backendUrl = "https://api.mr-corp.ca";
    // backendUrl = "https://prod-api.mr-corp.ca";

} else {
    backendUrl = "https://prod-api.mr-corp.ca";
    // Default to production URL if domain doesn't match any known patterns
}

export { backendUrl };