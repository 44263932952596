import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Pagination, TextField, ThemeProvider } from '@mui/material';
import TestomonialApi from '../../../apis/TestomonialApi';
import moment from 'moment';
import { BootstrapDialog, customTheme } from '../../../style/commonStyle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


const TestimonialCard = () => {
    const [testomonial, setTestomonial] = useState([])
    const [testomonialData, setTestomonialData] = useState({})
    console.log("???????????????testomonialData", testomonialData)
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [date, setDate] = useState("")
    const [videoId, setVideoId] = useState("")
    const [showVideoDialoge, setShowVideoDialoge] = useState(false)


    const rowsPerPage = 5;

    const token = localStorage.getItem("token")

    useEffect(() => {
        getAllTestamonial();
    }, [date]);

    const handleCloseVideoDialog = () => {
        setShowVideoDialoge(false)
    }


    const HandleDateChange = (e) => {
        setDate(e.target.value)
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const getAllTestamonial = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`
        if (date) {
            payload = `?selected_date=${date}`
        }
        TestomonialApi.getAllTestamonialList(payload, token)
            .then(response => {
                setTestomonial(response.results);
                setTestomonialData(response)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    
    const handleDownloadVideo = () => {
        const link = document.createElement('a');
        link.href = videoId;
        link.download = 'video.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div style={{ padding: "0px 20px 20px 20px" }}>
                <div className="testimonialhead">
                    <h3 className="growthrate">Testimonials</h3>
                    <TextField type="date" name='date' onChange={HandleDateChange} value={date} sx={{
                        width: "130px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px",
                        }
                    }} />
                </div>
                <div className="testimonials">
                    {loading ? (
                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress sx={{ color: "#FFFFFF" }} />
                        </Box>
                    ) : testomonial.length > 0 ? (
                        testomonial.map(testimonial => (
                            <div key={testimonial.id} className="testimonial">
                                <div className="content">
                                    <div style={{ marginRight: '15px' }}>
                                        <img style={{ width: "60px", height: "60px", borderRadius: "10px" }}
                                            onClick={() => { setShowVideoDialoge(true); setVideoId(testimonial.video) }}
                                            src={testimonial.image} alt={`Person ${testimonial.id}`} />
                                    </div>
                                    <div>
                                        <h4 className="testimonialname">{testimonial.first_name}</h4>
                                        <p className="content">{moment(testimonial.created_at).format("llll")}</p>
                                    </div>
                                </div>
                                <div className="rating">
                                    <p className="ratingtext">{5}</p>
                                    <span style={{ fontSize: '10px', color: '#45A843' }}>&#9733;</span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Data not found</p>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                        <Pagination
                            count={Math.ceil(testomonialData.count / rowsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                        />
                    </Box>
                </div>
            </div>

            <ThemeProvider theme={customTheme} >

                <BootstrapDialog
                    fullWidth
                    maxWidth="xs"
                    onClose={handleCloseVideoDialog}
                    open={showVideoDialoge}

                >
                    <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
                        Video
                    </DialogTitle>
                    <DialogContent dividers>
                        <video width="100%" height="300" controls>
                            <source src={videoId} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseVideoDialog}>
                            Close
                        </Button>
                        <Button startIcon={<CloudDownloadIcon />} variant='contained'
                            sx={{
                                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                color: 'white',
                                fontSize: "12px"
                            }} onClick={handleDownloadVideo}>
                            Download
                        </Button>

                    </DialogActions>
                </BootstrapDialog>
            </ThemeProvider>
        </>

    );

};

export default TestimonialCard; // Corrected the component name export
