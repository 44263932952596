import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, Paper, Select, Switch, TextField, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './index.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import { MenuItem } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import editicon from '../../../Assests/icons/editicon.svg'
import Pagination from '@mui/material/Pagination';
import UsersApi from '../../../apis/Users';
import { toast } from 'react-toastify';
import ProvincesApi from '../../../apis/provincesApi';
import searchicon from '../../../Assests/images/search-normal.png'
import { BootstrapDialog, CustomSelect, CustomizedTextField, Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';


const Users = () => {
  const [users, setUser] = useState([])
  const [usersData, setUserData] = useState({})
  const [loading, setLoading] = useState(false)
  const [deleteDialog, setDeleteDialoge] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [createUserdialogOpen, setCreateUserDialogOpen] = useState(false)
  const [updateUserdialogOpen, setUpdateUserDialogeOpen] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [selectedprovince, setSelectedProvince] = useState("")
  const [updateUser, setUpdateUser] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const [createUser, setCreateUser] = useState({
    name: '',
    email: '',
    mobile: '',
    occupation: '',
    province: '',
    password: '',
    role: "",
  });


  const handleChangeProvince = (event) => {
    setSelectedProvince(event.target.value);
  };

  const handleCreateUserChange = (event) => {
    const { name, value } = event.target;
    setCreateUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };


  const handleUpdateUserChange = (event) => {
    const { name, value } = event.target;
    setUpdateUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 40;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    getAllUsers()
  }, [selectedprovince])

  useEffect(() => {
    getAllUsers()
    getAllProvinces()

  }, [rowsPerPage, currentPage, searchQuery])

  const token = localStorage.getItem("token")

  const getAllUsers = () => {
    setLoading(true);
    let payload = null
    if (selectedprovince) {
      payload = `?limit=${rowsPerPage}&province=${selectedprovince}`
    } else {
      payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    }
    if (searchQuery) {
      payload += `&search=${searchQuery}`;
    }
    UsersApi.getAllUsersList(payload, token)
      .then(response => {
        setUser(response.results);
        setUserData(response)
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };

  const getAllProvinces = () => {
    setLoading(true);
    let payload = `?limit=${100}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };

  const handleUpdateUserDialog = (id) => {
    setSelectedId(id)
    setLoadingUpdate(true)
    setUpdateUserDialogeOpen(true)
    let rowid = `?id=${id}`
    UsersApi.getSingleUser(rowid, token)
      .then(response => {
        setUpdateUser(response.detail)
        setLoadingUpdate(false)

      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  }

  const handleUpdateUser = (e) => {
    e.preventDefault();
    setLoadingUpdate(true);
    let payload = {
      username: updateUser.username,
      email: updateUser.email,
      phone_number: updateUser.phone_number,
      occupation: updateUser.occupation,
      province: updateUser.province,

    };
    let rowid = `?id=${selectedId}`
    UsersApi.UpdateUser(rowid, payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllUsers()
        setLoadingUpdate(false);
        setUpdateUserDialogeOpen(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }

  const handleBlockChanged = (id, newBlockStatus) => {
    setLoading(true);

    let payload = {
      status: newBlockStatus
    }

    let rowid = `?id=${id}`;

    UsersApi.BlockUser(rowid, payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllUsers();
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };


  const handleCreateUser = (e) => {
    e.preventDefault();
    setLoadingCreate(true)
    let payload = {
      username: createUser.name,
      email: createUser.email,
      occupation: createUser.occupation,
      province: createUser.province,
      password: createUser.password,
      phone_number: createUser.mobile,
      role: createUser.role,
      full_name:createUser.name

    };
    UsersApi.CreateUser(payload, token)
      .then(response => {
        toast.success("User Created Successfully", { autoClose: 1400 });
        getAllUsers()
        setLoadingCreate(false);
        setCreateUserDialogOpen(false);
        setCreateUser({})
      })
      .catch(error => {
        if (error.response) {
          const { data } = error.response;
          Object.values(data).forEach(messages => {
            messages.forEach(message => {
              toast.error(message, { autoClose: 1400 });
              setLoadingCreate(false);
            });
          });
        } else {
          toast.error("Something went wrong.", { autoClose: 1400 });
          setLoadingCreate(false);

        }
        console.error("Something went wrong:", error);
        setLoadingCreate(false);

      });
  }

  const handleDeleteFaq = () => {
    setLoadingDelete(true);
    let payload = `?id=${selectedId}`;
    UsersApi.deleteUser(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllUsers();
        setLoadingDelete(false);
        setDeleteDialoge(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingDelete(false);
      });

  }

  const handleCloseUpdateDialoge = () => {
    setUpdateUserDialogeOpen(false)
  }


  const OpenCreateUserDialoge = () => {
    setCreateUserDialogOpen(true)
  }


  const handleCloseDeleteDialog = () => {
    setDeleteDialoge(false)
  }

  const handleDeleteUserDiaoge = (id) => {
    setDeleteDialoge(true)
    setSelectedId(id)
  }


  const handleClose = () => {
    setCreateUserDialogOpen(false);
    setCreateUser({})
  };


  const startIndex = (currentPage - 1) * rowsPerPage;

  return (
    <div className='main-div'>
      <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography className='infromation' >Users</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} display={"flex"} justifyContent={"flex-end"}>
          <TextField
            type='text'
            sx={{ width: { lg: "250px", xs: "160px" }, mr: 1 }}
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img width={18} src={searchicon} alt='locationsvg' />
                </InputAdornment>
              ),
            }}
            size='small' variant='outlined' color='error'
          />
          <CustomSelect
            sx={{ width: "100px", mr: 1 }}
            variant='outlined'
            color='error'
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedprovince}
            onChange={handleChangeProvince}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a province
            </MenuItem>
            {provinces.map((item, index) => (
              <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
            ))}
          </CustomSelect>
          <Button
            variant='contained'
            sx={{
              textTransform: "capitalize",
              width: "120px",
              // marginLeft: "10px",
              backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
              color: 'white',
            }}
            onClick={OpenCreateUserDialoge}
          >
            Create User
          </Button>
        </Grid>
      </Grid>

      <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
        <Grid item xs={12}>
          <Item>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress sx={{ color: "#F96064" }} />
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                <Table aria-label="customized table dense">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                      <StyledTableCell align="left">Full Name</StyledTableCell>
                      <StyledTableCell align="left">User Name</StyledTableCell>
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="left">Mobile</StyledTableCell>
                      <StyledTableCell align="left">Occupation</StyledTableCell>
                      <StyledTableCell align="left">Province</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                      <StyledTableCell align="left">Block</StyledTableCell>
                      <StyledTableCell align="left">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users ? (
                      users.length > 0 ? (
                        users.map((row, i) => (
                          <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                              {startIndex + i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.full_name ? row.full_name : "N/A"}</StyledTableCell>
                            <StyledTableCell align="left">{row.username ? row.username : "N/A"}</StyledTableCell>
                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                            <StyledTableCell align="left">{row.phone_number ? row.phone_number : "N/A"}</StyledTableCell>
                            <StyledTableCell align="left">{row.occupation ? row.occupation : "N/A"}</StyledTableCell>
                            <StyledTableCell>{row.province ? row.province : "N/A"}</StyledTableCell>
                            <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                              <span style={{ backgroundColor: "#DAE6D1", color: "#45A843", padding: "3px 10px", borderRadius: "4px", fontWeight: "600", fontSize: "12px" }}>{row.is_active.toString()}</span>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Switch
                                checked={row.is_active}
                                onChange={(e) => handleBlockChanged(row.id, e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{
                                  '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#ff0000',
                                  },
                                  '& .MuiSwitch-track': {
                                    backgroundColor: '#ff0000',
                                  },
                                }}
                              />

                            </StyledTableCell>
                            <StyledTableCell>
                              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Box>
                                  <img style={{ cursor: "pointer" }} src={editicon} alt="editicon" onClick={() => handleUpdateUserDialog(row.id)} />
                                </Box>
                                <Box sx={{ color: "red", cursor: "pointer" }}>
                                  <DeleteOutlineOutlinedIcon onClick={() => handleDeleteUserDiaoge(row.id)} />
                                </Box>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            Data not found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>

                </Table>
              </TableContainer>
            )}


            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
              <Pagination
                count={Math.ceil(usersData.count / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Item>
        </Grid>

      </Grid>

      <Dialog
        open={createUserdialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleCreateUser}>
          <DialogContent sx={{ width: "500px" }}>
            <DialogContentText id="alert-dialog-description">
              <Typography sx={{ mb: 3, fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#3B3B3B" }}>Create User</Typography>
              {loadingCreate ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                  <CircularProgress sx={{ color: "#F96064" }} />
                </Box>
              ) : (
                <Stack direction={"column"} spacing={2}>
                  <CustomizedTextField
                    id="outlined-basic"
                    fullWidth
                    variant='outlined'
                    required
                    value={createUser.name}
                    name='name'
                    onChange={handleCreateUserChange}
                    size='small'
                    placeholder='Full name'
                  />

                  <CustomizedTextField
                    value={createUser.email}
                    name='email'
                    required
                    onChange={handleCreateUserChange}
                    id="outlined-basic"
                    fullWidth
                    variant='outlined'
                    size='small'
                    placeholder='Enter email'
                  />

                  <CustomizedTextField
                    value={createUser.mobile}
                    name='mobile'
                    required
                    onChange={handleCreateUserChange}
                    id="outlined-basic"
                    fullWidth
                    variant='outlined'
                    size='small'
                    placeholder='Mobile No.'
                  />

                  <CustomizedTextField
                    value={createUser.occupation}
                    name='occupation'
                    onChange={handleCreateUserChange}
                    id="outlined-basic"
                    fullWidth
                    required
                    variant='outlined'
                    size='small'
                    placeholder='Occupation'
                  />
                  <Select
                    name='province'
                    value={createUser.province}
                    onChange={handleCreateUserChange}
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder='Province'
                    className='province-select'
                    displayEmpty

                  >
                    <MenuItem value="" disabled>Select province</MenuItem>

                    {provinces.map((item, index) => (
                      <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                    ))}

                  </Select>

                  <Select
                    name='role'
                    value={createUser.role}
                    onChange={handleCreateUserChange}
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder='Province'
                    className='province-select'
                    displayEmpty

                  >
                    <MenuItem value="" disabled>Select Role</MenuItem>
                    <MenuItem value="Staff">Staff</MenuItem>
                    <MenuItem value="HR">HR</MenuItem>



                  </Select>
                  <CustomizedTextField
                    value={createUser.password}
                    name='password'
                    required
                    onChange={handleCreateUserChange} fullWidth variant='outlined' size='small' placeholder='Password' />


                </Stack>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "20px", mb: "15px" }}>
            <Button variant='text'
              sx={{
                color: '#3B3B3B',
                textTransform: "capitalize"
              }} onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }} type='submit'>
              Create user
            </Button>
          </DialogActions>
        </form>
      </Dialog>




      <Dialog
        open={updateUserdialogOpen}
        onClose={handleCloseUpdateDialoge}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleUpdateUser}>
          <DialogContent sx={{ width: "500px" }}>
            <DialogContentText id="alert-dialog-description">
              <Typography sx={{ mb: 3, fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", color: "#3B3B3B" }}>Update User</Typography>
              {loadingUpdate ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                  <CircularProgress sx={{ color: "#F96064" }} />
                </Box>
              ) : (
                <>
                  <Stack direction={"column"} spacing={2}>
                    <CustomizedTextField
                      id="outlined-basic"
                      fullWidth
                      variant='outlined'
                      required
                      value={updateUser.full_name}
                      name='username'
                      onChange={handleUpdateUserChange}
                      size='small'
                      placeholder='Full name'
                    />
                    <CustomizedTextField
                      value={updateUser.email}
                      name='email'
                      required
                      onChange={handleUpdateUserChange}
                      id="outlined-basic"
                      fullWidth
                      variant='outlined'
                      size='small'
                      placeholder='Enter email'
                    />
                    <CustomizedTextField
                      value={updateUser.phone_number}
                      name='phone_number'
                      required
                      onChange={handleUpdateUserChange}
                      id="outlined-basic"
                      fullWidth
                      variant='outlined'
                      size='small'
                      placeholder='Mobile No.'
                    />


                    <CustomizedTextField
                      value={updateUser.occupation}
                      name='occupation'
                      onChange={handleUpdateUserChange}
                      id="outlined-basic"
                      fullWidth
                      required
                      variant='outlined'
                      size='small'
                      placeholder='Occupation'
                    />
                    <Select
                      name='province'
                      value={updateUser?.province || ""}
                      onChange={handleUpdateUserChange}
                      size='small'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder='Province'
                      className='province-select'
                    >
                      {provinces.map((item, index) => (
                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                      ))}

                    </Select>



                  </Stack>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "20px", mb: "15px" }}>
            <Button variant='text'
              sx={{
                color: '#3B3B3B',
                textTransform: "capitalize"
              }} onClick={handleCloseUpdateDialoge}>
              Close
            </Button>
            <Button variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }} type='submit'>
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>


      <BootstrapDialog
        fullWidth
        maxWidth="xs"
        onClose={handleCloseDeleteDialog}
        open={deleteDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
          Delete
        </DialogTitle>
        <DialogContent dividers>
          <Typography>Are you sure you want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
            Close
          </Button>
          <Button
            variant='contained'
            sx={{
              backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
              color: 'white',
              textTransform: "capitalize"
            }}
            onClick={handleDeleteFaq}
            disabled={loadingDelete}
          >
            {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

    </div>
  )
}

export default Users