import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import storeimg from '../../../Assests/images/partnerimg.png'
import { Badge, Box, Pagination, Stack, TextField, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import pdficon from '../../../Assests/images/pdf.png'
import { StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const affliatesDatas = [
    {
        id: 1,
        storeimg: storeimg,
        storename: "Jack Smith",
        email: "Jacksmith@mail.com",
        contact: "+14443334545",
        members: "26"
    },
    {
        id: 2,
        storeimg: storeimg,
        storename: "Jack Smith",
        email: "Jacksmith@mail.com",
        contact: "+14443334545",


        members: "26"

    },
    // Add three more data objects similarly
    {
        id: 3,
        storeimg: storeimg,
        storename: "Jack Smith",
        email: "Jacksmith@mail.com",

        contact: "+14443334545",

        members: "26"

    },
    {
        id: 4,
        storeimg: storeimg,
        storename: "Jack Smith",
        email: "Jacksmith@mail.com",
        contact: "+14443334545",

        members: "26"

    },
    {
        id: 5,
        storeimg: storeimg,
        storename: "Jack Smith",
        email: "Jacksmith@mail.com",
        contact: "+14443334545",

        members: "26"
    }
];

const AffliatesTable = () => {
    const [affliatesData, setAffliatesData] = useState(affliatesDatas)
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };


    return (
        <div>
            <div class="graph-div">
                <div class="graphinner-div">
                    <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div class="dstextdiv">
                            <Stack direction={"row"} spacing={2}>
                                <Typography className='parttnershead'>Affiliates</Typography>
                                <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                    <Badge badgeContent="" variant="dot" color='success' />
                                    <span style={{ color: "#949494", fontSize: "12px" }}>12k members</span>
                                </Stack>
                            </Stack>
                        </div>
                        <div class="right-div">
                            <div>
                                <img src={pdficon} alt="img" />
                            </div>
                         
                                <TextField type="date" sx={{
                                    width: "130px",
                                    border: "none",
                                    outline: "none",
                                    "& .MuiInputBase-input": {
                                        padding: "5px 5px",
                                    }
                                }} />

                        </div>
                    </Box>
                </div>
            </div>


            <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                <Table aria-label="customized table dense">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ paddingLeft: 3 }}>Store Name</StyledTableCell>
                            <StyledTableCell align="left">Contacts</StyledTableCell>
                            <StyledTableCell align="left">Members</StyledTableCell>
                            <StyledTableCell align="left">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {affliatesData?.map((row, i) => (
                            <>
                                <StyledTableRow key={i} >
                                    <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', maxWidth: '150px', paddingLeft: 3, fontWeight: 600 }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <img width={32} height={32} src={row.storeimg} alt={row.storeimg} />
                                            <Stack direction={'column'} >
                                                <Typography className='storename'>{row.storename}</Typography>
                                                <Typography className='partnerdate'>{row.email}</Typography>
                                            </Stack>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left"><Typography className='location'>{row.contact}</Typography></StyledTableCell>
                                    <StyledTableCell >
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>

                                            <Typography className='location'>{row.members}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                        <IconButton
                                        // onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                    
                                        >


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={props.handleEdit}
                                            >Edit</MenuItem>


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={() => handleSelect("delete")}
                                            >Delete</MenuItem>

                                        </Menu>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                <Pagination count={2} variant="outlined" shape="rounded" />
                {/* <TablePagination
                    totalItems={partnerDatas.length}
                    itemsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handlePageChange}
                /> */}
            </Box>

        </div>
    )
}

export default AffliatesTable