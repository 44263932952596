import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { Box, Button, Grid, Select, Stack, TextField, Typography, styled } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const PaymentsChart = ({ paymentGraphData, setProvince, setPaymentStatus, paymentStatus, paymentGraphDate, setPaymentGraphDate }) => {


    const handleChange = (event) => {
        setProvince(event.target.value);
    };

    const handlePaymentStatusChange = (status) => {
        if (status === 'settled') {
            setPaymentStatus({ settled: true, pending: false });
        } else if (status === 'pending') {
            setPaymentStatus({ settled: false, pending: true });
        }
    };

    const HandleDateChange = (e) => {
        setPaymentGraphDate(e.target.value)
    }

    let catagories = paymentGraphData?.payment_graph?.payment_day || [];

    var options = {
        series: [{
            name: 'Payment',
            data: paymentGraphData?.payment_graph?.payment_count,
        }],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }],
        xaxis: {
            categories: catagories,
        },
        fill: {
            opacity: 1
        },
        grid: {
            row: {
                opacity: 0.5
            },
            show: false
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false // Set show to false to hide the legend
        },
        colors: ['#F65256']
    };


    const generatePDF = () => {
        const doc = new jsPDF();


        doc.setFontSize(18);
        doc.text('Payments Overview', 10, 10);

        const categories = paymentGraphData?.payment_graph?.payment_day || [];
        const seriesData = paymentGraphData?.payment_graph?.payment_count || [];

        const formattedChartData = categories.map((category, index) => ({
            category,
            value: seriesData[index]
        }));

        doc.text('Payments Overview Chart:', 10, 20);
        doc.autoTable({
            head: [['Days', 'Payments']],
            body: formattedChartData.map(({ category, value }) => [category, value])
        });

        // Save the PDF
        doc.save('payments_overview.pdf');
    }


    return (
        <div>
            <div class="graph-div">
                {/* <div class="graphinner-div"> */}
                <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between", width: "100%" }}>
                    {/* <div class="dstextdiv"> */}
                    <Grid container direction={"row"} spacing={1}>
                        <Grid item lg={8} md={8} xs={12} sm={12}>
                            <Stack direction={"row"} spacing={2}>
                                <Typography className='parttnershead'>Payments</Typography>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    color={paymentStatus.settled ? "error" : "inherit"}
                                    sx={{ textTransform: "capitalize", fontSize: "12px" }}
                                    name="settled"
                                    value={paymentStatus?.settled}
                                    onClick={() => handlePaymentStatusChange("settled")}
                                >
                                    Settled
                                </Button>
                                <Button size='small' variant="outlined"
                                    name="pending"
                                    color={paymentStatus.pending ? "error" : "inherit"}
                                    sx={{ textTransform: "capitalize" }}
                                    value={paymentStatus?.pending}
                                    onClick={() => handlePaymentStatusChange("pending")}
                                >
                                    Pending
                                </Button>
                            </Stack>
                        </Grid>

                        <Grid item lg={4} md={4} xs={12} sm={12} display={"flex"} justifyContent={"flex-end"}>
                            <Stack direction={"row"} spacing={1.5} alignItems={"center"}>

                                <div class="right-div">
                                    <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                                        <img src={pdficon} alt="img" />
                                    </div>
                                    <TextField type="date" name='paymentGraphDate' onChange={HandleDateChange} value={paymentGraphDate} sx={{
                                        width: "140px",
                                        border: "none",
                                        outline: "none",
                                        "& .MuiInputBase-input": {
                                            padding: "5px 5px",
                                        }
                                    }} />
                                </div>
                            </Stack>

                        </Grid>
                    </Grid>

                    {/* </div> */}

                </Box>
                {/* </div> */}
                <div id="stackedchart">
                    <ReactApexChart options={options} series={options.series} type="bar" height={300} />
                </div>
            </div>
        </div>
    )
}

export default PaymentsChart