import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import editicon from '../../../Assests/icons/editicon.svg'
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FaqsApi from '../../../apis/FaqsApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BootstrapDialog, Item, StyledTableCell, StyledTableRow, customTheme } from '../../../style/commonStyle';

const Faqs = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  const [faqs, setFaqs] = useState([])
  const [faqsData, setFaqsData] = useState({})
  const [loading, setLoading] = useState(false)
  const [createFaqOpen, setCreateFaqDialogOpen] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialoge] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [updateFaq, setUpdateFaq] = useState({})
  const [createfaq, setCreateFaq] = useState({})
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  const rowsPerPage = 40; 
  const handleClose = () => {
    setCreateFaqDialogOpen(false);
  };

  const handleCloseEditDialog = () => {
    setEditDialog(false);
  };


  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    getAllFaqs()

  }, [rowsPerPage, currentPage])


  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setUpdateFaq(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleCreateFaqChange = (event) => {
    const { name, value } = event.target;
    setCreateFaq(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const getAllFaqs = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    FaqsApi.getAllFaqsList(payload, token)
      .then(response => {
        setFaqs(response.results);
        setFaqsData(response)
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");

        } else {
          console.log("Error:", error);
        }
      })
  };


  const OpenFaqDialoge = () => {
    setCreateFaqDialogOpen(true)
  }


  const handleEditFaqsDialog = (id) => {
    setSelectedId(id)
    setEditDialog(true)
    let rowid = `?id=${id}`
    FaqsApi.getSingleFaq(rowid, token)
      .then(response => {
        setUpdateFaq(response.detail)
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  }

  const handleDeleteFaq = () => {
    setLoadingDelete(true);
    let payload = `?id=${selectedId}`;
    FaqsApi.deleteFaq(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllFaqs()
        setLoadingDelete(false);
        setDeleteDialoge(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingDelete(false);
      });

  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialoge(false)
  }

  const handleDeleteFaqDiaoge = (id) => {
    setDeleteDialoge(true)
    setSelectedId(id)
  }

  const handleUpdateFaq = () => {
    setLoadingUpdate(true);
    let payload = {
      question: updateFaq.question,
      answer: updateFaq.answer,
    };
    let rowid = `?id=${selectedId}`;
    FaqsApi.UpdateFaq(rowid, payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllFaqs()
        setLoadingUpdate(false);
        setEditDialog(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }


  const handleCreateFaq = () => {
    let payload = {
      question: createfaq.question,
      answer: createfaq.answer,
    };
    FaqsApi.PostFaqs(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllFaqs()
        setCreateFaqDialogOpen(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }



  const startIndex = (currentPage - 1) * rowsPerPage;

  return (
    <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
        <Typography className='infromation' >Faqs</Typography>

        <Button
          variant='contained'
          sx={{
            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
            color: 'white',
          }}
          onClick={OpenFaqDialoge}
        >
          Add Faq
        </Button>
      </Stack>

      <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
        <Grid item xs={12}>
          <Item>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress sx={{ color: "#F96064" }} />
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                <Table aria-label="customized table dense">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                      <StyledTableCell align="left">Question</StyledTableCell>
                      <StyledTableCell align="left">Answer</StyledTableCell>
                      <StyledTableCell align="left">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {faqs ? (
                      faqs.length > 0 ? (
                        faqs.map((row, i) => (
                          <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                              {startIndex + i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: "25%" }}>{row.question}</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: "50%" }}>{row.answer}</StyledTableCell>
                            <StyledTableCell>
                              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Box>
                                  <img style={{ cursor: "pointer" }} src={editicon} alt="editicon" onClick={() => handleEditFaqsDialog(row.id)} />
                                </Box>
                                <Box sx={{ color: "red", cursor: "pointer" }}>
                                  <DeleteOutlineOutlinedIcon onClick={() => handleDeleteFaqDiaoge(row.id)} />
                                </Box>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            Data not found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>

                </Table>
              </TableContainer>
            )}


            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
              <Pagination
                count={Math.ceil(faqsData.count / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Item>
        </Grid>

      </Grid>

      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={createFaqOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Create Faqs
          </DialogTitle>
          <DialogContent dividers>
            <Stack direction={"column"} spacing={2}>
              <TextField value={createfaq.question} name='question' onChange={handleCreateFaqChange} fullWidth variant='outlined' size='small' placeholder='Question' />
              <TextField multiline value={createfaq.answer} name='answer' onChange={handleCreateFaqChange} rows={4} fullWidth variant='outlined' size='small' placeholder='Answer...' />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"

              }} disabled={loadingCreate} onClick={handleCreateFaq}>
              {loadingCreate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Create"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="sm"
          onClose={handleCloseEditDialog}
          open={editDialog}

        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Update Faqs
          </DialogTitle>
          <DialogContent dividers>
            <Stack direction={"column"} spacing={2}>
              <TextField value={updateFaq.question} name='question' onChange={handleUpdateChange} fullWidth variant='outlined' size='small' placeholder='Question' />
              <TextField value={updateFaq.answer} name='answer' onChange={handleUpdateChange} multiline rows={4} fullWidth variant='outlined' size='small' placeholder='Answer...' />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseEditDialog}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }}
              onClick={handleUpdateFaq}
              disabled={loadingUpdate} // Disable the button when loading
            >
              {loadingUpdate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Update"} {/* Show CircularProgress when loading */}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="xs"
          onClose={handleCloseDeleteDialog}
          open={deleteDialog}

        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Delete
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you want to delete?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }}
              onClick={handleDeleteFaq}
              disabled={loadingDelete} // Disable the button when loading
            >
              {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"} {/* Show CircularProgress when loading */}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>

    </Box>
  )
}

export default Faqs