import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, CircularProgress, Select, Stack, TextField, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TablePagination from '../../TablePagination';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { StyledTableCell, StyledTableRow } from '../../../style/commonStyle';


const BankTransactionCard = ({ setSelectedProvince, loading, transaction, transactionList, transactiondate, setTransactionDate, setPage, Page, rowsPerPage }) => {

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleDateChange = (e) => {
        setTransactionDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Transaction Card Data', 10, 10);
        const tableData = transactionList?.results?.map(row => [
            row.store_data?.name ? row.store_data?.name : 'N/A',
            row.store_data?.address,
            row.amount,
            row.store_data?.open_time,
            row.store_data?.end_time
        ]) || [];

        doc.autoTable({
            startY: 20,
            head: [['Store Name', 'Location', 'Payment', "Open Time", "Close Time"]],
            body: tableData,
        });

        doc.save('transaction.pdf');
    };


    return (
        <div style={{ padding: "10px 20px 20px 20px" }}>


            <div className="graphinner-div">
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography className='parttnershead'>Transactions</Typography>
                    {/* <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                        <Badge badgeContent="" variant="dot" color='success' />
                        <span style={{ color: "#949494", fontSize: "12px" }}>24k members</span>
                    </Stack> */}
                </Stack>
                <div className="right-div">
                    <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                        <img width={20} height={20} src={pdficon} alt="img" />
                    </div>
                    <div>
                        {/* <CustomSelect
                            sx={{ width: "110px" }}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedprovince}
                            onChange={handleChangeProvince}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Select a province
                            </MenuItem>
                            {provinces.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                            ))}
                        </CustomSelect> */}
                    </div>
                    <TextField type="date" name='transactiondate' value={transactiondate} onChange={handleDateChange} sx={{
                        width: "140px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px",
                        }
                    }} />
                </div>
            </div>
            <Box>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                            <Table aria-label="customized table dense">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ paddingLeft: 3 }}>Store Name</StyledTableCell>
                                        <StyledTableCell align="left">Location</StyledTableCell>
                                        <StyledTableCell align="left">Payment</StyledTableCell>
                                        <StyledTableCell align="left">Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transaction.length > 0 ? (
                                        transaction.map((row, i) => (
                                            <>
                                                <StyledTableRow key={i} sx={{ width: "100%" }}>
                                                    <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, fontWeight: 600 }}>
                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                            <img width={32} height={32} style={{borderRadius:"50%"}} src={row.storeimg} alt="store logo" />
                                                            <Stack direction={'column'} >
                                                                <Typography className='storename'>{row.store_data?.name}</Typography>
                                                                <Typography className='partnerdate'>{row.created_at}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left"><Typography className='location'>{row.store_data?.address ? row.store_data?.address : "N/A"}</Typography></StyledTableCell>
                                                    <StyledTableCell >
                                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                            <CheckCircleIcon sx={{ color: "green" }} />
                                                            <Typography className='location'>${row.amount}</Typography>
                                                        </Stack>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                                        <IconButton
                                                        // onClick={handleClick}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="long-menu"
                                                        // anchorEl={anchorEl}
                                                        // open={open}
                                                        // onClose={handleClose}
                                                        >


                                                            <MenuItem className="my_menu_item_imp"
                                                            // onClick={props.handleEdit}
                                                            >Edit</MenuItem>


                                                            <MenuItem className="my_menu_item_imp"
                                                            // onClick={() => handleSelect("delete")}
                                                            >Delete</MenuItem>

                                                        </Menu>
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                            </>
                                        ))
                                    ) : (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={4} sx={{ fontSize: "40px", fontWeight: "600" }} align="center">No data found</StyledTableCell>
                                        </StyledTableRow>
                                    )}

                                </TableBody>

                            </Table>
                        </TableContainer>
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                            <TablePagination
                                totalItems={transactionList}
                                itemsPerPage={rowsPerPage}
                                page={Page}
                                onPageChange={handlePageChange}
                            />
                        </Box>
                    </>
                )}

            </Box>
        </div>
    )
}

export default BankTransactionCard