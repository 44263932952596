import { AppBar, Grid, IconButton, InputAdornment, Stack, TextField, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import searchicon from '../Assests/images/search-normal.png'
import notificationicon from '../Assests/images/notification-bing.png'
import profilepic from '../Assests/images/profile.png'
import moment from 'moment';


const drawerWidth = 220;

const Topbar = ({ setMobileOpen, mobileOpen, isClosing }) => {
    const [currentDate, setCurrentDate] = useState(moment().format("ddd, DD MMMM YYYY"));

    const username = localStorage.getItem("username")

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    return (
        <div>
            <AppBar
                position="fixed"
                className='appbar'
                sx={{
                    // padding: "20px 30px 15px 30px",
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "#F9F9F9",
                    boxShadow: "none"
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ color: "red", mr: 5, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container direction="row">
                        <Grid item xs={12} lg={6} md={6} sm={12}>
                            <Stack direction={"column"} spacing={1}>
                                <Typography class="welconme">Welcome, {username}</Typography>
                                <Typography class="date">{currentDate}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} justifyContent={"flex-end"} display={"flex"} >
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                <div class="notifidiv">
                                    <img width="24" height="24" src={notificationicon} alt="notification" />
                                </div>
                                <div class="profilediv">
                                    <img src={profilepic} alt="profile " />
                                </div>

                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Topbar