import Axios from "axios";
import { backendUrl } from "../utils";


let getGiftList = "/dashboard/listGifts";
let getGiftHistoryList = "/api/gift_history_admin_dashboard";



const GiftApi = {

    getGiftLists(params, token) {
        return Axios({
            url: backendUrl + getGiftList + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getGiftHistory(params, token) {
        return Axios({
            url: backendUrl + getGiftHistoryList +params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


}

export default GiftApi;
