import { Box, Button, CircularProgress, Container, Grid, Paper, Stack, TextField, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import UsersApi from '../apis/Users';
import supportimg from '../Assests/images/support.jpg'
import helpimg from '../Assests/images/help.jpg'
import smsimg from '../Assests/icons/sms.svg'
import { LoadingButton } from '@mui/lab';
import { CustomizedTextField } from '../style/commonStyle';
const HelpSupport = () => {
    const [userdata, setUserData] = useState({});
    const [loading, setLoading] = useState(false);


    const theme = useTheme();
    const isXsOrSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevdata) => ({
            ...prevdata,
            [name]: value,
        }));
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        let payload = {
            username: userdata.name,
            email: userdata.email,
            message: userdata.message
        };
        UsersApi.helpSupport(payload)
            .then(response => {
                setUserData({})
                setLoading(false);
                toast.success("Message sent  Successfully", { autoClose: 1400 });
            })
            .catch(error => {
                console.error("Something went wrong:", error);
            });
    }
    return (
        <Box sx={{ padding: { lg: "10px", xs: "0px" }, paddingTop: { lg: "80px", xs: "0px" } }}>
            {/* <Container > */}
                <Box sx={{ padding: { lg: "40px", xs: "0px" } }}>
                    <Box px={isXsOrSmScreen ? 0 : 0} py={isXsOrSmScreen ? 0 : 0}>
                        {loading ?
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <CircularProgress color="primary" />
                            </Box>
                            :
                            <form onSubmit={handleSubmit}>
                                <Grid container direction={"row"}>
                                    <Grid xs={12} lg={6} md={6} sm={12}>
                                        {isXsOrSmScreen ?
                                            <img
                                                src={helpimg}
                                                style={{
                                                    // width: "340px", 
                                                    maxWidth: "100%",// Sets max width for mobile screens
                                                    maxHeight: '400px', // Optional: Set max height if needed
                                                    // borderRadius: '20px',
                                                }}
                                                alt="Image description" // Provide a descriptive alt attribute for accessibility
                                            />
                                            :
                                            <img
                                                src={helpimg}
                                                style={{
                                                    width: "600px", // Sets max width for mobile screens
                                                    maxHeight: '600px', // Optional: Set max height if needed
                                                    // borderRadius: '20px',
                                                }}
                                                alt="Image description" // Provide a descriptive alt attribute for accessibility
                                            />
                                        }

                                    </Grid>
                                    <Grid xs={12} lg={6} md={6} sm={12} sx={{ padding: { lg: "0px", xs: "10px" } }} >
                                        <Stack direction={"column"} spacing={2}>
                                            <Grid item lg={12} xs={12} sm={12} md={6}>
                                                <Typography sx={{ color: "#00000", fontSize: "35px", fontWeight: "600",fontFamily:"Poppins" ,textAlign:"left"}}>Contact us </Typography>
                                                <Typography sx={{ color: "#00000", fontSize: "18px", fontWeight: "600",fontFamily:"Poppins" ,mb:1}}>Office address:</Typography>
                                                <Typography sx={{ color: "#909090", fontSize: "16px", fontWeight: "600",fontFamily:"Poppins",mb:1 }}>British Columbia Canada</Typography>


                                                <Stack direction={"row"} spacing={1} alignItems={"center"} mb={1}>
                                                    <img src={smsimg} alt='smsicon' />
                                                    <Typography sx={{ color: "#00000", fontSize: "14px", fontWeight: "400",fontFamily:"Poppins" }}>info@mrgiftsolutions.ca</Typography>
                                                </Stack>
                                                <CustomizedTextField
                                                    id="outlined-basic"
                                                    fullWidth
                                                    variant='outlined'
                                                    required
                                                    value={userdata.name}
                                                    name='name'
                                                    onChange={handleChange}
                                                    size='small'
                                                    placeholder='User Name'
                                                />
                                            </Grid>
                                            <Grid item lg={12} xs={12} sm={12} md={6}>
                                                <CustomizedTextField
                                                    id="outlined-basic"
                                                    fullWidth
                                                    required
                                                    variant='outlined'
                                                    value={userdata.email}
                                                    name='email'
                                                    onChange={handleChange}
                                                    size='small'
                                                    placeholder='Email'
                                                />
                                            </Grid>
                                            <Grid item lg={12} xs={12} sm={12} md={12}>
                                                <CustomizedTextField
                                                    id="outlined-basic"
                                                    fullWidth
                                                    variant='outlined'
                                                    multiline
                                                    required
                                                    rows={5}
                                                    value={userdata.message}
                                                    name='message'
                                                    onChange={handleChange}
                                                    size='small'
                                                    placeholder='write message...'
                                                />
                                            </Grid>
                                            <Box sx={{ mt: 2 }}>
                                                <LoadingButton  fullWidth loading={loading} variant='contained' color='error' sx={{ textTransform: "capitalize",borderRadius:"35px",padding:"10px !important" }} type='submit'>Send</LoadingButton>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        }
                    </Box>
                </Box>
            {/* </Container> */}
        </Box >
    )
}

export default HelpSupport