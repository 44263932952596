import React from 'react'
import Testomonial from '../../../components/Information/Testomonial'
import Sidebar from '../../../components/Sidbar'
import { Box } from '@mui/material'

const TestomonialPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <Testomonial />
            </Box>
        </div>
    )
}

export default TestomonialPage