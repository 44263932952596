import { Grid, Paper, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Affliaterevinue from './Affliaterevinue';
import AffliateGrowthChart from './AffliateGrowthChart';
import TransactionCard from './TransactionCard';
import AffiliatesCard from './AffiliatesCard';
import AffiliatesApi from '../../../apis/AffiliatesApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ProvincesApi from '../../../apis/provincesApi';
import PaymentsApi from '../../../apis/PaymentsApi';
import { Item } from '../../../style/commonStyle';


const Affiliates = () => {
  const navigate = useNavigate()
  const [affliatesRevenueData, setAffliateRevenueData] = useState({})
  const [affliatesRevenueDate, setAffliateRevenueDate] = useState()
  const [growthRateData, setGrowthRateData] = useState({})
  const [transactionCardData, setTransactionCardData] = useState([])
  const [transactionCardDate, setTransactionCardDate] = useState("")
  const [date, setDate] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectedprovince, setSelectedProvince] = useState("")
  const [provinces, setProvinces] = useState([])
  const [affliatesCardDate, setAffliatesCardDate] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const limits = 100;
  const rowsPerPage = 10;


  useEffect(() => {
    getAffilatesRevenue()
    getGrowthRate()
    getAllProvinces()
    getPaymentsLists()
  }, [affliatesRevenueDate, date, transactionCardDate, Page])

  const token = localStorage.getItem("token")

  const getAffilatesRevenue = () => {
    setLoading(true);
    let paylaod = ""
    if (affliatesRevenueDate) {
      paylaod = `?selected_date=${affliatesRevenueDate}`
    }

    AffiliatesApi.getAffliatesRevenue(paylaod, token)
      .then(response => {
        setAffliateRevenueData(response);
        setLoading(false);
      })
      .catch(error => {
        console.log("Error:", error);
      })
  };

  const getGrowthRate = () => {
    setLoading(true);
    let paylaod = ""
    if (date) {
      paylaod = `?selected_date=${date}`
    }
    AffiliatesApi.getGrowthRate(paylaod, token)
      .then(response => {
        setGrowthRateData(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      })
  };



  const getAllProvinces = () => {
    let payload = `?limit=${limits}&page=${currentPage}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
      });
  };

  const getPaymentsLists = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${Page}`
    if (transactionCardDate) {
      payload = `?selected_date=${transactionCardDate}&limit=${rowsPerPage}`
    }
    PaymentsApi.getPaymentsList(payload, token)
      .then(response => {
        setTransactionCardData(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Somthing went Wrong", error);
        }
      })
  };


  return (
    <div className='maininformation'>
      <Typography className='infromation' sx={{ mb: 2 }}>Affliates</Typography>

      <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

        <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
          <Grid item xs={12}>
            <Item>
              <Affliaterevinue affliatesRevenueData={affliatesRevenueData} affliatesRevenueDate={affliatesRevenueDate} setAffliateRevenueDate={setAffliateRevenueDate} />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <TransactionCard transactionCardData={transactionCardData} transactionCardDate={transactionCardDate} setTransactionCardDate={setTransactionCardDate} provinces={provinces} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} Page={Page} setPage={setPage} rowsPerPage={rowsPerPage} />
            </Item>
          </Grid>


        </Grid>



        {/* Right Section */}
        <Grid item container xs={12} rowGap={2} sm={6} md={4} >
          <Grid item xs={12}>
            <Item> <AffliateGrowthChart growthRateData={growthRateData} date={date} setDate={setDate} />  </Item>
          </Grid>

          <Grid item xs={12}>
            <Item> <AffiliatesCard affliatesCardDate={affliatesCardDate} setAffliatesCardDate={setAffliatesCardDate} provinces={provinces} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} /> </Item>
          </Grid>

        </Grid>
      </Grid>


    </div>
  )
}

export default Affiliates