import React, { useEffect, useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { MenuItem, Select, TextField, styled } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect } from '../../../style/commonStyle';


const ExpenseRate = ({ expenses, setExpenses, adsExpenseGrowthGraphData, adsExpenseGrowthGraphDate, setAdsExpenseGrowthGraphDate }) => {
    const [totalPercentage, setTotalPercentage] = useState(0);

    useEffect(() => {
        if (adsExpenseGrowthGraphData?.expanxe_rate_data?.expanse_rate_percent) {
            const percentages = adsExpenseGrowthGraphData.expanxe_rate_data.expanse_rate_percent;
            const total = percentages.reduce((acc, curr) => curr, 0);

            setTotalPercentage(total);
        }
    }, [adsExpenseGrowthGraphData]);

    const remainingPercentage = totalPercentage;

    const handleChange = (event) => {
        setExpenses(event.target.value);
    };


    var options = {
        chart: {
            type: 'donut'
        },

        plotOptions: {
            pie: {
                expandOnClick: true,

            },

        },

        series: adsExpenseGrowthGraphData?.expanxe_rate_data?.expanse_rate_percent ?? [],
        chartOptions: {
            labels: []
        },
        tooltip: {
            enabled: true
        },
        legend: {
            show: false
        }

    }

    const handleDateChange = (e) => {
        setAdsExpenseGrowthGraphDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(18);
        doc.text('Expense Rate Report', 10, 10);

        const growthRateData = adsExpenseGrowthGraphData?.expanxe_rate_data?.expanse_rate_percent || [];
        const tableData = growthRateData.map((value, index) => [index + 1, `${value}`]);

        doc.autoTable({
            startY: 20,
            head: [['#', 'Expense Rate']],
            body: tableData,
        });

        // Save the PDF
        doc.save('expense_rate.pdf');
    }

    return (
        <div>
            <div className="rightpie-div">
                <div>
                    <h3 className="growthrate">Expense rate</h3>
                </div>
                <div >
                    <CustomSelect
                        sx={{ width: "120px" }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={expenses}
                        onChange={handleChange}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select an expense
                        </MenuItem>
                        <MenuItem value={"Officeexpenses"}>Office expenses</MenuItem>
                        <MenuItem value={"Salaries"}>Salaries</MenuItem>
                        <MenuItem value={"Traveling"}>Traveling</MenuItem>
                        <MenuItem value={"Miscellaneous"}>Miscellaneous</MenuItem>
                    </CustomSelect>
                </div>
                <div className="calender-div">
                    <div onClick={generatePDF} style={{ marginRight: "5px", cursor: "pointer" }}>
                        <img src={pdficon} alt="img" />
                    </div>

                    <TextField type="date" name='adsExpenseGrowthGraphDate' value={adsExpenseGrowthGraphDate} onChange={handleDateChange} sx={{
                        width: "120px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px", // Adjust this value as needed
                        }
                    }} />
                </div>

            </div>


            <div id="chart">
                <ReactApexChart options={options} series={options.series} type="donut" height={240} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: 'auto' }}>
                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>0%</p>
                </div>

                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>{remainingPercentage}%</p>
                </div>
            </div>

            <h6 class="coupensold">$320  less expenses than yesterday</h6>
        </div >
    )
}

export default ExpenseRate