import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import storeimg from '../../../Assests/images/partnerimg.png'
import { Badge, Box, Stack, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '../../TablePagination';
import { StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const partnerDatas = [
    {
        id: 1,
        storeimg: storeimg,
        storename: "Manraj Sweets",
        date: "10 AM - 11 PM (Today)",
        location: "31776 South Fraser Way",
        rating: "4.8",
        reviews: "(326 reviews)"
    },
    {
        id: 2,
        storeimg: storeimg,
        storename: "Another Store",
        date: "9 AM - 10 PM (Today)",
        location: "123 Main Street",
        rating: "3.5",
        reviews: "(150 reviews)"
    },
    {
        id: 3,
        storeimg: storeimg,
        storename: "Sweet Haven",
        date: "11 AM - 9 PM (Today)",
        location: "456 Oak Avenue",
        rating: "4.5",
        reviews: "(200 reviews)"
    },
    {
        id: 4,
        storeimg: storeimg,
        storename: "Delicious Delights",
        date: "8 AM - 10 PM (Today)",
        location: "789 Maple Street",
        rating: "4.2",
        reviews: "(180 reviews)"
    },
    {
        id: 5,
        storeimg: storeimg,
        storename: "Tasty Treats",
        date: "12 PM - 8 PM (Today)",
        location: "987 Pine Avenue",
        rating: "4.6",
        reviews: "(250 reviews)"
    }
];

const PartnerTable = ({ storeListData, storelimit, storePerPage, setStorePerPage }) => {
    const [partnerData, setPartnerData] = useState(partnerDatas)
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 3;

    const handlePageChange = (event, newPage) => {
        setStorePerPage(newPage);
    };

    return (
        <div style={{ padding: "10px 20px 20px 20px" }}>
            <Stack direction={"row"} spacing={2}>
                <Typography className='parttnershead'>Partners</Typography>
                <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                    <Badge badgeContent="" variant="dot" color='success' />
                    <span style={{ color: "#949494", fontSize: "12px" }}>{storeListData.count} partners</span>
                </Stack>
            </Stack>
            <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                <Table aria-label="customized table dense">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ paddingLeft: 3 }}>Store Name</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Ratings</StyledTableCell>
                            <StyledTableCell align="left">Actions</StyledTableCell>
                            {/* <StyledTableCell sx={{ paddingRight: 0 }} align="left">Inverntory Amount</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* testominalData.slice(0, displayCount) */}
                        {storeListData?.results?.map((row, i) => (
                            <>
                                <StyledTableRow key={i} sx={{ width: "100%" }}>
                                    <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, fontWeight: 600 }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <img width={32} height={32} src={row.logo} alt={"logo"} />
                                            <Stack direction={'column'} >
                                                <Typography className='storename'>{row.name}</Typography>
                                                <Typography className='partnerdate'>{row.date}</Typography>
                                            </Stack>
                                        </Stack>
                                    </StyledTableCell>
                                    {/* <StyledTableCell align="left" sx={{ minWidth: '80px' }}>Rs. {NumberFormat(parseInt(row.total_sale ? row.total_sale : 0))}</StyledTableCell> */}
                                    <StyledTableCell align="left"><Typography className='location'>{row.address}</Typography></StyledTableCell>
                                    <StyledTableCell >
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <div className="partnerrating">
                                                <p>{row.rating}</p>
                                            </div>
                                            <Typography className='location'>{row.reviews}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                        <IconButton
                                        // onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                        // anchorEl={anchorEl}
                                        // open={open}
                                        // onClose={handleClose}
                                        >


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={props.handleEdit}
                                            >Edit</MenuItem>


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={() => handleSelect("delete")}
                                            >Delete</MenuItem>

                                        </Menu>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                {/* <Pagination count={2} variant="outlined" shape="rounded" /> */}
                <TablePagination
                    totalItems={storeListData}
                    itemsPerPage={storelimit}
                    page={storePerPage}
                    onPageChange={handlePageChange}
                />
            </Box>


        </div>
    )
}

export default PartnerTable