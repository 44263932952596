import Axios from "axios";
import { backendUrl } from "../utils";

let getListExpensesList = "/dashboard/listExpanses";
let addexpensesApi = '/dashboard/expanse'
let listcountries = '/dashboard/listCountries'
let totalExpensesApi = "/dashboard/user-slots-summary/"


const ExpensesApi = {

    getListExpeses(params, token) {
        return Axios({
            url: backendUrl + getListExpensesList + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getTotalExpesesList(params, token) {
        return Axios({
            url: backendUrl + totalExpensesApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getListCountries(params, token) {
        return Axios({
            url: backendUrl + listcountries + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    addExpenses(params, token) {
        return Axios({
            url: backendUrl + addexpensesApi,
            method: 'post',
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },

    UpdateExpenses(id, params, token) {
        return Axios({
            url: backendUrl + addexpensesApi + id,
            method: 'put',
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    deleteExpenses(params, token) {
        return Axios({
            url: backendUrl + addexpensesApi + params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getSingleExpense(params, token) {
        return Axios({
            url: backendUrl + addexpensesApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },






}

export default ExpensesApi;
