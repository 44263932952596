import Axios from "axios";
import { backendUrl } from "../utils";

let getStorePartnersApi = "/dashboard/get_upload_receipt/";
let deletepaymentApi = "/dashboard/delete_proof_receipt/"
let paymentproffHistory="/api/create_payment_proof_history/"

const PaymentRecipetApi = {

    getAllPayments(params, token) {
        return Axios({
            url: backendUrl + getStorePartnersApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    deletePayments(params, token) {
        return Axios({
            url: backendUrl + deletepaymentApi + params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    addPaymentProof(params, token) {
        return Axios({
            url: backendUrl + paymentproffHistory ,
            method: 'post',
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },

}

export default PaymentRecipetApi;
