import { Grid, Paper, Typography, styled, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box, TextField, Stack, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BankTransactionCard from './BankTransactionCard';
import BalancePieChart from './BalancePieChart';
import BankAccountsCard from './BankAccountsCard';
import AthenticationDialog from './AthenticationDialog';
import ProvincesApi from '../../../apis/provincesApi';
import BankPageApi from '../../../apis/BankPageApi';
import PaymentsApi from '../../../apis/PaymentsApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Item, customTheme } from '../../../style/commonStyle';

const MyBank = () => {
  const navigate = useNavigate()
  const [addAthenticationOpen, setaddAthenticationOpen] = useState(false);
  const [provinces, setProvinces] = useState([])
  const [balanceGraphData, setBalanceGraphData] = useState({})
  const [paymentList, setPaymentsList] = useState([])
  const [selectedprovince, setSelectedProvince] = useState("")
  const [loading, setLoading] = useState(false)
  const [balanceGrowthDate, setBalanceGrowthDate] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 1000;
  const [transactiondate, setTransactionDate] = useState("")
  const [transactionList, setTransactionList] = useState({})
  const [transaction, setTransaction] = useState([]);
  const [code, setCode] = useState("")

  const [Page, setPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    getPaymentsLists()
    getBalanceGrowthGraph()
  }, [balanceGrowthDate, transactiondate, Page])

  useEffect(() => {
    getAllProvinces()
    getBalanceGrowthGraph()
    sendAdminEmail()
  }, [])

  const handleClose = () => {
    setaddAthenticationOpen(false);
  };


  const handleCodeChange = (e) => {
    setCode(e.target.value)
  }

  const token = localStorage.getItem("token")

  const getAllProvinces = () => {
    setLoading(true);
    let payload = `?limit=${limit}&page=${currentPage}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };


  const getBalanceGrowthGraph = () => {
    setLoading(true);
    let paylaod = ""
    if (balanceGrowthDate) {
      paylaod = `?selected_date=${balanceGrowthDate}`
    }
    BankPageApi.getBalance(paylaod, token)
      .then(response => {
        setBalanceGraphData(response);
        setLoading(false);
      })
      .catch(error => {

      })
  };



  const getPaymentsLists = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${Page}`
    if (transactiondate) {
      payload = `?selected_date=${transactiondate}&limit=${rowsPerPage}`
    }
    PaymentsApi.getPaymentsList(payload, token)
      .then(response => {
        setTransactionList(response);
        setTransaction(response.results)
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Somthing went Wrong", error);
        }
      })
  };



  const sendAdminEmail = () => {
    setLoading(true);
    setaddAthenticationOpen(true); // Assuming this is setting some state for opening a UI component
    BankPageApi.sendAdminEmailCode(token)
      .then(response => {
        if (response.success) {
          toast.success(response.response.message, { autoClose: 1400 });
        } else {
          toast.error("Failed to send verification code", { autoClose: 1400 });
        }
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
        } else {
          console.log("Error:", error);
          toast.error("An error occurred", { autoClose: 1400 });
        }
      });
  };


  const handleSubmitCode = (e) => {
    e.preventDefault()
    setLoading(true);
    let payload = {
      code: code
    }
    BankPageApi.verfyAdminCode(payload, token)
      .then(response => {
        toast.success(response.message, { autoClose: 1400 });
        setaddAthenticationOpen(false);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
        } else {
          console.log("Error:", error);
          toast.error("An error occurred", { autoClose: 1400 });
        }
      });

  }

  return (
    <div className='maininformation'>
      <Typography className='infromation' sx={{ mb: 2 }}>My bank account</Typography>

      <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

        <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
          <Grid item xs={12}>
            <Item>
              <BankTransactionCard loading={loading} transaction={transaction} transactionList={transactionList} transactiondate={transactiondate} setTransactionDate={setTransactionDate} setSelectedProvince={setSelectedProvince} selectedprovince={selectedprovince} provinces={provinces} Page={Page} setPage={setPage} rowsPerPage={rowsPerPage} />
            </Item>
          </Grid>

          {/* <Grid item xs={12}>
            <Item>
              <TransactionCard />
            </Item>
          </Grid> */}


        </Grid>



        {/* Right Section */}
        <Grid item container xs={12} rowGap={2} sm={6} md={4} >
          <Grid item xs={12}>
            <Item> <BalancePieChart balanceGrowthDate={balanceGrowthDate} setBalanceGrowthDate={setBalanceGrowthDate} balanceGraphData={balanceGraphData} />  </Item>
          </Grid>

          <Grid item xs={12}>
            <Item> <BankAccountsCard /> </Item>
          </Grid>

        </Grid>
      </Grid>
      {/* {addAthenticationOpen &&
        < AthenticationDialog addAthenticationOpen={addAthenticationOpen} setaddAthenticationOpen={setaddAthenticationOpen} />
      } */}


      <ThemeProvider theme={customTheme} >

        <Dialog
          fullWidth
          maxWidth="xs"
          open={addAthenticationOpen}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={() => navigate("/accounts")} /></Box>
          </DialogTitle>
          <form onSubmit={handleSubmitCode}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography className='addpartner' sx={{ mb: 2 }}>Authorization required</Typography>
                <Typography className='otpsent' sx={{ mb: 6 }}>OTP sent to you registered email ********200@gmail.com</Typography>
                <Stack direction={"column"} spacing={2}>
                  <TextField required fullWidth type='number' name='code' value={code} onChange={handleCodeChange} variant='outlined' size='small' placeholder='Enter OTP here' />
                </Stack>
                <Typography className='otpsent' mt={2}>Didn’t received the code? <span style={{ color: "#F8595D", fontSize: "12px", fontWeight: 600, cursor: "pointer" }} onClick={sendAdminEmail}>Resend</span></Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: "20px", mb: "15px" }}>
              <Button variant='contained'
                type='submit'
                sx={{
                  backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                  color: 'white',
                }} fullWidth>
                Verify
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>

    </div>
  )
}

export default MyBank