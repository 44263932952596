import { Box, CircularProgress, Grid, Paper, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import chartimg from '../../../Assests/images/Chart.png'
import './index.css'
import AdRevenueGraph from './AdRevenueGraph';
import GrowthRateGraph from './GrowthRateGraph';
import Couponsactivity from './Couponsactivity';
import PaymentsChart from './PaymentsChart';
import GiftDistributionChart from './giftDistributionChart';
import SalesOverviewGraph from './SalesOverviewGraph';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AccountsApi from '../../../apis/Accounts';
import { Item } from '../../../style/commonStyle';

const AccountsDashboard = () => {
    const navigate = useNavigate()
    const [AccountsData, setAccountsData] = useState({})
    const [revenueData, setReveneData] = useState({})
    const [giftDistributionData, setGiftDistributionData] = useState({})
    const [paymentGraphData, setPaymentGraphData] = useState({})
    const [paymentGraphDate, setPaymentGraphDate] = useState(null)
    const [date, setDate] = useState(null)
    const [giftGraphDate, setGiftGraphDate] = useState(null)
    const [revenueDate, setReveneDate] = useState(null)
    const [accountGrowthDate, setAccountGrowthDate] = useState(null)
    const [province, setProvince] = useState("Ontario");
    const [paymentStatus, setPaymentStatus] = useState({
        settled: false,
        pending: false
    });
    const [accountGrowthGraph, setAccountGrowthGraphData] = useState({})

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getDashBoardAccounts()
    }, [date])

    useEffect(() => {
        getGiftDistibution()
    }, [giftGraphDate])

    useEffect(() => {
        getAddRevenue()
    }, [revenueDate])

    useEffect(() => {
        getAccountGrowthGraph()
    }, [accountGrowthDate])

    useEffect(() => {
        getPaymentGraph()
    }, [paymentGraphDate, paymentStatus])



    // useEffect(() => {
    //     getDashBoardAccounts()
    //     getAddRevenue()
    //     getGiftDistibution()
    //     getPaymentGraph()
    //     getAccountGrowthGraph()
    // }, [])

    const token = localStorage.getItem("token")

    const getDashBoardAccounts = () => {
        let payload = ""
        if (date) {
            payload = `?selected_date=${date}`
        }
        AccountsApi.getAccounts(payload, token)
            .then(response => {
                setAccountsData(response);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.detail, { autoClose: 1400 });
                    navigate("/");
                } else {
                    console.log("Error:", error);
                }
            })
    };

    const getAddRevenue = () => {
        let paylaod = ""
        if (revenueDate) {
            paylaod = `?selected_date=${revenueDate}`
        }
        AccountsApi.getRevenue(paylaod, token)
            .then(response => {
                setReveneData(response);
            })
            .catch(error => {

            })
    };

    const getGiftDistibution = () => {
        let paylaod = ""
        if (giftGraphDate) {
            paylaod = `?selected_date=${giftGraphDate}`
        }
        AccountsApi.getGiftDistribution(paylaod, token)
            .then(response => {
                setGiftDistributionData(response);
            })
            .catch(error => {

            })
    };

    const getPaymentGraph = () => {
        let payload = "";
        if (paymentGraphDate) {
            payload = `?selected_date=${paymentGraphDate}`;
        }
        if (paymentStatus.settled) {
            payload = `?status=settled`;
        }
        if (paymentStatus.pending) {
            payload = `?status=pending`;
        }
        AccountsApi.getPaymentsGraph(payload, token)
            .then(response => {
                console.log("Response:", response);
                if (response && response.payment_graph) {
                    setPaymentGraphData(response);
                } else {
                    console.error("Invalid response format:", response);
                }
            })
            .catch(error => {
                console.error("Error fetching payment graph data:", error);
                setLoading(false);
            });
    };



    const getAccountGrowthGraph = () => {
        let paylaod = ""
        if (accountGrowthDate) {
            paylaod = `?selected_date=${accountGrowthDate}`
        }
        AccountsApi.getAccountGrowthRateGraph(paylaod, token)
            .then(response => {
                setAccountGrowthGraphData(response);
                setLoading(false);
            })
            .catch(error => {

            })
    };





    return (
        <div className='maininformation'>
            <Typography className='infromation' sx={{ mb: 2 }}>Gift account</Typography>
            <Grid container direction={"row"} spacing={2}>
                <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div class="totaluser">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Total amount</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">$ {AccountsData.total_amount}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </div>
                </Grid>

                <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div className="provineces">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Distributed gifts</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">$ {AccountsData.distributed_gifts}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </div>


                </Grid>

                <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div className="activeuser">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Pending payments</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">$ {AccountsData.pending_payments}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>

                    </div>
                </Grid>


                <Grid item lg={3} md={4} sm={12} xs={12}>
                    <div className="partners">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Ads revenue</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">$ {AccountsData.ads_revenue}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </div>
                </Grid>

            </Grid>
            <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
                    <Grid item xs={12}>
                        <Item>
                            <SalesOverviewGraph AccountsData={AccountsData} date={date} setDate={setDate} />
                        </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item>
                            <AdRevenueGraph revenueData={revenueData} revenueDate={revenueDate} setReveneDate={setReveneDate} />
                        </Item>
                    </Grid>

                </Grid>


                {/* Right Section */}
                <Grid item container xs={12} rowGap={2} sm={6} md={4} >
                    <Grid item xs={12}>
                        <Item> <GrowthRateGraph accountGrowthGraph={accountGrowthGraph} accountGrowthDate={accountGrowthDate} setAccountGrowthDate={setAccountGrowthDate} /> </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item> <Couponsactivity /> </Item>
                    </Grid>

                </Grid>
            </Grid>





            <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>


                <Grid item container xs={12} rowGap={2} sm={6} md={4} >
                    <Grid item xs={12}>
                        <Item>
                            <GiftDistributionChart giftDistributionData={giftDistributionData} giftGraphDate={giftGraphDate} setGiftGraphDate={setGiftGraphDate} />
                        </Item>
                    </Grid>
                </Grid>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
                    <Grid item xs={12}>
                        <Item>
                            <PaymentsChart paymentGraphData={paymentGraphData} paymentGraphDate={paymentGraphDate} setPaymentGraphDate={setPaymentGraphDate} province={province} setProvince={setProvince} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} />
                        </Item>
                    </Grid>
                </Grid>


                {/* Right Section */}

            </Grid>
        </div >
    )
}

export default AccountsDashboard