import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import TablePagination from '../../TablePagination';
import { CustomSelect, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const AdvertisementsCard = ({ advertisement, advertisementData, advertisementDate, setAdvertisementDate, Page, setPage, PerPage, provinces, selectedprovince, setSelectedProvince }) => {
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const handleDateChange = (e) => {
        setAdvertisementDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Advertisements Data', 10, 10);
        const tableData = advertisementData?.results?.map(row => [
            row.storename ? row.storename : 'N/A',
            row.address,
            row.budget,
        ]) || [];

        doc.autoTable({
            startY: 20,
            head: [['Store Name', 'Location', 'Payment']],
            body: tableData,
        });

        doc.save('Advertisements.pdf');
    };


    return (
        <div style={{ padding: "10px 20px 20px 20px" }}>


            <Grid container className="graphinner-div">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography className='parttnershead'>Advertisements</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <div onClick={generatePDF} style={{ cursor: "pointer", marginRight: "10px" }}>
                            <img src={pdficon} alt="img" />
                        </div>
                        <div>
                            <CustomSelect
                                sx={{ width: "110px", mr: 1 }}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedprovince}
                                onChange={handleChangeProvince}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Select a province
                                </MenuItem>
                                {provinces.map((item, index) => (
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                ))}
                            </CustomSelect>
                        </div>

                        <TextField type="date" name='advertisementDate' value={advertisementDate} onChange={handleDateChange} sx={{
                            width: "140px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />

                    </div>
                </Grid>
            </Grid>

            <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                <Table aria-label="customized table dense">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ paddingLeft: 3 }}>Store Name</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Payment</StyledTableCell>
                            <StyledTableCell align="left">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {advertisement?.map((row, i) => (
                            <>
                                <StyledTableRow key={i} sx={{ width: "100%" }}>
                                    <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, fontWeight: 600 }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <img width={32} height={32} src={row.image} alt={"logo"} />
                                            <Stack direction={'column'} >
                                                <Typography className='storename'>{row.storename}</Typography>
                                                <Typography className='partnerdate'>{row.ad_date}</Typography>
                                            </Stack>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left"><Typography className='location'>{row.address}</Typography></StyledTableCell>
                                    <StyledTableCell >
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <CheckCircleIcon sx={{ color: "green" }} />
                                            <Typography className='location'>${row.budget}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                        <IconButton
                                        // onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                        // anchorEl={anchorEl}
                                        // open={open}
                                        // onClose={handleClose}
                                        >


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={props.handleEdit}
                                            >Edit</MenuItem>


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={() => handleSelect("delete")}
                                            >Delete</MenuItem>

                                        </Menu>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                {/* <Pagination count={2} variant="outlined" shape="rounded" /> */}
                <TablePagination
                    totalItems={advertisementData}
                    itemsPerPage={PerPage}
                    page={Page}
                    onPageChange={handlePageChange}
                />
            </Box>

        </div>
    )
}

export default AdvertisementsCard