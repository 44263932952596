import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputAdornment, OutlinedInput, Paper, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import storeimg from '../../../Assests/images/partnerimg.png'
import { Stack } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import editicon from '../../../Assests/icons/editicon.svg'
import BlockIcon from '@mui/icons-material/Block';
import Pagination from '@mui/material/Pagination';
import UsersApi from '../../../apis/Users';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FaqsApi from '../../../apis/FaqsApi';
import { toast } from 'react-toastify';
import ProvincesApi from '../../../apis/provincesApi';
import TablePagination from '../../TablePagination';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ReactFileReader from 'react-file-reader';
import moment from 'moment';
import { BootstrapDialog, CustomizedTextField, Item, StyledTableCell, StyledTableRow, customTheme } from '../../../style/commonStyle';


const Provinces = () => {
  const [provinces, setProvinces] = useState([])
  const [provincesData, setProvincesData] = useState({})
  const [loading, setLoading] = useState(false)
  const [createProvinceOpen, setCreateProvinceOpen] = useState(false)
  const [updateProvinceOpen, setUpdateProvinceOpen] = useState(false)
  const [deleteDialog, setDeleteDialoge] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [updateProvince, setUpdateProvince] = useState({})
  const [createprovince, setCreateProvince] = useState({
    banner_file: null,
    banner_name: ""
  })

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleClose = () => {
    setCreateProvinceOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    getAllProvinces()

  }, [rowsPerPage, currentPage])


  const handleUpdateProvincesChange = (event) => {
    const { name, value } = event.target;
    setUpdateProvince(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleCreateProvincesChange = (event) => {
    const { name, value } = event.target;
    setCreateProvince(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const token = localStorage.getItem("token")

  const getAllProvinces = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
        setProvincesData(response)
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };


  const OpenCreateDialoge = () => {
    setCreateProvinceOpen(true)
  }


  const handleEditProvinceDialog = (id) => {
    setSelectedId(id)
    setUpdateProvinceOpen(true)
    let rowid = `?id=${id}`
    ProvincesApi.getSingleProvince(rowid, token)
      .then(response => {
        setUpdateProvince(response.detail)
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  }

  const handleDeleteProvince = () => {
    setLoadingDelete(true);
    let payload = `?id=${selectedId}`;
    ProvincesApi.deleteProvince(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllProvinces()
        setLoadingDelete(false);
        setDeleteDialoge(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingDelete(false);
      });

  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialoge(false)
  }

  const handleCloseUpdateProvince = () => {
    setUpdateProvinceOpen(false)
  }

  const handleDeleteProvinceDiaoge = (id) => {
    setDeleteDialoge(true)
    setSelectedId(id)
  }

  const handleUpdateProvinceDialog = () => {
    const formData = new FormData();
    formData.append("name", updateProvince.name)
    formData.append('image', updateProvince.banner_file);
    let rowid = `?id=${selectedId}`;
    ProvincesApi.UpdateProvince(rowid, formData, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllProvinces()
        setLoadingUpdate(false);
        setUpdateProvinceOpen(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }


  const handleCreateProvince = () => {
    const formData = new FormData();
    formData.append("name", createprovince.name)
    formData.append('image', createprovince.banner_file);
    ProvincesApi.createProvince(formData, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllProvinces()
        setCreateProvince({})
        // setLoadingCreate(false);
        setCreateProvinceOpen(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }
  const uploadBGImage = (files) => {
    const bgfile = files.fileList[0];
    setCreateProvince((prevState) => ({
      ...prevState,
      banner_name: bgfile.name,
      banner_file: bgfile,
      background_img: URL.createObjectURL(bgfile),
    }));

    setUpdateProvince((prevState) => ({
      ...prevState,
      banner_name: bgfile.name,
      banner_file: bgfile,
      background_img: URL.createObjectURL(bgfile),
    }));

  };



  const startIndex = (currentPage - 1) * rowsPerPage;

  return (
    <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
        <Typography className='infromation' >Provinces</Typography>

        <Button
          variant='contained'
          sx={{
            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
            color: 'white',
            fontSize: "12px"
          }}
          onClick={OpenCreateDialoge}
        >
          Add Provinces
        </Button>
      </Stack>

      <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
        <Grid item xs={12}>
          <Item>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress sx={{ color: "#F96064" }} />
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                <Table aria-label="customized table dense">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                      <StyledTableCell align="left">Name</StyledTableCell>
                      <StyledTableCell align="left">Image</StyledTableCell>
                      <StyledTableCell align="left">Created at</StyledTableCell>
                      <StyledTableCell align="left">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {provinces && provinces.length > 0 ? (
                      provinces.map((row, i) => (
                        <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                          <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, }}>
                            {startIndex + i + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: "25%" }}>{row.name}</StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: "35%" }}>
                            <img width={100} height={50} src={row.image} alt='image' />
                          </StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: "50%" }}>  {moment(row.created_at).format('MMM. D, YYYY, h:mm a')}</StyledTableCell>
                          <StyledTableCell>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                              <Box>
                                <img style={{ cursor: "pointer" }} src={editicon} alt="editicon" onClick={() => handleEditProvinceDialog(row.id)} />
                              </Box>
                              <Box sx={{ color: "red", cursor: "pointer" }}>
                                <DeleteOutlineOutlinedIcon onClick={() => handleDeleteProvinceDiaoge(row.id)} />
                              </Box>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow >
                        <TableCell colSpan={5} align="center" sx={{ height: "200px" }}>
                          <Typography sx={{ fontSize: "16px", fontFamily: "Poppins" }}> Data not found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            )}


            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
              <TablePagination
                totalItems={provincesData}
                itemsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePageChange}
              />
            </Box>
          </Item>
        </Grid>

      </Grid>

      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={createProvinceOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Create Province
          </DialogTitle>
          <DialogContent dividers sx={{ width: "500px" }}>
            <Stack direction={"column"} spacing={2}>
              <CustomizedTextField value={createprovince.name} name='name' onChange={handleCreateProvincesChange} fullWidth variant='outlined' size='small' placeholder='name' />
              <FormControl size='small' variant="outlined">
                <OutlinedInput
                  disabled
                  id="outlined-adornment-password"
                  value={createprovince.image}
                  startAdornment={
                    <InputAdornment position="start">
                      <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadBGImage}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                          <Box sx={{ display: "flex" }}>
                            <DriveFolderUploadIcon />
                          </Box>
                          {createprovince.banner_name ? createprovince.banner_name : <Typography className='nofile'>No file chosen</Typography>}
                        </Stack>
                      </ReactFileReader>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"

              }} disabled={loadingCreate} onClick={handleCreateProvince}>
              {loadingCreate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Create"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          // fullWidth
          // maxWidth="xs"
          onClose={handleCloseUpdateProvince}
          aria-labelledby="customized-dialog-title"
          open={updateProvinceOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Update Province
          </DialogTitle>
          <DialogContent dividers sx={{ width: "500px" }}>
            <Stack direction={"column"} spacing={2}>
              <CustomizedTextField value={updateProvince?.name} name='name' onChange={handleUpdateProvincesChange} fullWidth variant='outlined' size='small' placeholder='name' />
              {/* <CustomizedImageTextField value={updateProvince?.image} name='image' accept="image/*" required type='file' onChange={handleUpdateProvincesChange} fullWidth variant='outlined' size='small' placeholder='description' /> */}
              <FormControl size='small' variant="outlined">
                <OutlinedInput
                  disabled
                  id="outlined-adornment-password"
                  value={updateProvince?.image}
                  startAdornment={
                    <InputAdornment position="start">
                      <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadBGImage}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                          <Box sx={{ display: "flex" }}>
                            <DriveFolderUploadIcon />
                            {/* <Typography className='choosefile'>Choose file</Typography> */}
                          </Box>
                          {updateProvince.banner_name ? updateProvince.banner_name : <Typography className='nofile'>No file chosen</Typography>}
                        </Stack>
                      </ReactFileReader>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseUpdateProvince}>
              Close
            </Button>
            <Button variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"

              }} disabled={loadingCreate} onClick={handleUpdateProvinceDialog}>
              {loadingCreate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Update"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="xs"
          onClose={handleCloseDeleteDialog}
          open={deleteDialog}
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Delete
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you want to delete?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }}
              onClick={handleDeleteProvince}
              disabled={loadingDelete}
            >
              {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>

    </Box>
  )
}

export default Provinces