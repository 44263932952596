import Axios from "axios";
import { backendUrl } from "../utils";

let getPaymentListApi = "/dashboard/listPayments";
let getTotalExpenseGraphApi = '/dashboard/totalExpanseGraph'
let getExpenseRateApi = "/dashboard/expanseRate"
let getAdsExpenseGrowthApi = "/dashboard/adsExpanseGrowth"
let getAdsExpenseApi = "/dashboard/adsExpanseGraph"
let getexpenseGrowthRateApi = "/dashboard/partnerSattlementGrowthRateApi"
let getexpenseRateApi = "/dashboard/partnerSattlementGraph"


const PaymentsApi = {

    getPaymentsList(params, token) {
        return Axios({
            url: backendUrl + getPaymentListApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getTotalExpense(params, token) {
        return Axios({
            url: backendUrl + getTotalExpenseGraphApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getExpenseRate(params, token) {
        return Axios({
            url: backendUrl + getExpenseRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getAdsExpenseGrowthApi(params, token) {
        return Axios({
            url: backendUrl + getAdsExpenseGrowthApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getAdsExpenseApi(params, token) {
        return Axios({
            url: backendUrl + getAdsExpenseApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getexpenseRateGrowthApi(params, token) {
        return Axios({
            url: backendUrl + getexpenseGrowthRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getsettlementExpenseRate(params, token) {
        return Axios({
            url: backendUrl + getexpenseRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },




}

export default PaymentsApi;
