import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box, Typography, TextField, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { customTheme } from '../../../style/commonStyle';


const AthenticationDialog = ({ addAthenticationOpen, setaddAthenticationOpen }) => {

    const handleClose = () => {
        setaddAthenticationOpen(false);
    };

    return (
        <ThemeProvider theme={customTheme} >

            <Dialog
                fullWidth
                maxWidth="xs"
                open={addAthenticationOpen}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {/* <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box> */}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography className='addpartner' sx={{ mb: 2 }}>Authorization required</Typography>
                        <Typography className='otpsent' sx={{ mb: 6 }}>OTP sent to you registered email ********200@gmail.com</Typography>
                        <Stack direction={"column"} spacing={2}>
                            <TextField fullWidth type='number' variant='outlined' size='small' placeholder='Enter OTP here' />
                        </Stack>
                        <Typography className='otpsent' mt={2}>Didn’t received the code? <span style={{ color: "#F8595D", fontSize: "12px", fontWeight: 600, cursor: "pointer" }}>Resend</span></Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                    <Button variant='contained'
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                        }} onClick={handleClose} fullWidth>
                        Verify
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default AthenticationDialog;
