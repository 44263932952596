import React, { useState } from 'react'
import pdficon from '../../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { Badge, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect } from '../../../../style/commonStyle';

const TotalExpenses = ({ totalExpenseData, provinces, selectedprovince, setSelectedProvince, expenseRateDate, setExpenseRateDate }) => {

    console.log("totalExpenseData?????????????", totalExpenseData)
    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };


    const handleDateChange = (e) => {
        setExpenseRateDate(e.target.value)
    }

    const monthsNames = totalExpenseData?.partner_graph_data?.arr_of_month

    var areaOptions = {
        series: [
            {
                name: 'Desktops',
                data: totalExpenseData?.partner_graph_data?.arr_of_count_per_month,
            },
            {
                name: 'Laptops',
                data: totalExpenseData?.partner_graph_data?.arr_of_count_per_month2,

            },
        ],
        // ... (rest of the options remain unchanged
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#EFCDFF', '#45A843'],
            width: 3
        },
        grid: {

            show: false
        },
        xaxis: {
            categories: monthsNames,
        },
        tooltip: {
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = areaOptions.stroke.colors[seriesIndex]; // Get the color of the current series
                return (
                    '<div class="custom-tooltip" style="color: ' + color + '">' +
                    '<span class="number">' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                    '<div class="tooltip-line" style="background-color: ' + color + '"></div>' +
                    '<span class="units">' + series[seriesIndex][dataPointIndex] + ' expense </span>' +
                    '</div>'
                );
            },
        }, legend: {
            show: false, // Set show to false to hide the legend
        },
        fill: {
            colors: ['#FF5733', '#3399FF'], // Set colors for the area under the line chart
        },

    };

    const generatePDF = () => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(18);
        doc.text('Total Expenses Overview', 10, 10);

        doc.setFontSize(12);
        doc.text(`Total Settled Expenses: $${totalExpenseData.settled_payments}`, 10, 20);
        doc.text(`Total Pending Expenses: $${totalExpenseData.pending_payments}`, 10, 30);

        const monthsNames = totalExpenseData?.partner_graph_data?.arr_of_month;
        const pendingAmounts = totalExpenseData?.partner_graph_data?.arr_of_count_per_month;
        const settledAmounts = totalExpenseData?.partner_graph_data?.arr_of_count_per_month2;

        const formattedChartData = monthsNames.map((month, index) => ({
            month,
            pending: pendingAmounts[index],
            settled: settledAmounts[index],
        }));

        const tableData = formattedChartData.map(({ month, pending, settled }) => [month, pending, settled]);

        const tableHeaders = ['Month', 'Pending Amount', 'Settled Amount'];

        doc.text('Total Expenses:', 10, 40);
        doc.autoTable({
            startY: 50,
            head: [tableHeaders],
            body: tableData,
        });

        doc.save('total_expenses_overview.pdf');
    }


    return (
        <div>
            <div className="graph-div">
                <Grid container className="graphinner-div" alignItems={"center"}>
                    {/* <div className="dstextdiv">
                        <h3 className="growthrate" style={{ margin: "0px" }}>Sales overview</h3>
                    </div> */}
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Typography className='parttnershead'> Total expenses <span style={{ color: "Red", fontSize: "16px", fontWeight: "600", fontFamily: "Poppins" }}>({totalExpenseData?.total_amount})</span></Typography>
                            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                <Badge badgeContent="" variant="dot" color='success' />
                                <span style={{ color: "#949494", fontSize: "12px" }}>${totalExpenseData?.settled_payments}(Settled)</span>
                            </Stack>

                            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                <Badge badgeContent="" variant="dot" color="error" sx={{ '& .MuiBadge-badge': { backgroundColor: '#F1D4FF' } }} />
                                <span style={{ color: "#949494", fontSize: "12px" }}>${totalExpenseData?.pending_payments}(Pending))</span>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <div onClick={generatePDF} style={{ cursor: "pointer", marginRight: "10px" }}>
                                <img src={pdficon} alt="img" />
                            </div>
                            <div>
                                <CustomSelect
                                    sx={{ width: "110px", mr: 1 }}
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={selectedprovince}
                                    onChange={handleChangeProvince}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        Select a province
                                    </MenuItem>
                                    {provinces && provinces.map((item, index) => (
                                        <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                    ))}
                                </CustomSelect>
                            </div>
                            <TextField type="date" name='expenseRateDate' value={expenseRateDate} onChange={handleDateChange} sx={{
                                width: "130px",
                                border: "none",
                                outline: "none",
                                "& .MuiInputBase-input": {
                                    padding: "5px 5px", // Adjust this value as needed
                                }
                            }} />
                        </div>
                    </Grid>
                </Grid>
                <div id="linechart">
                    <ReactApexChart options={areaOptions} series={areaOptions.series} type="line" height={390} />
                </div>
            </div>
        </div >
    )
}

export default TotalExpenses