import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { Badge, Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, styled } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomSelect } from '../../../style/commonStyle';

const UserCard = ({ userGraphData, loading, setSelectedProvince, selectedprovince, provinces, userStatus, setUserStatus }) => {

    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };

    const WeeksDay = userGraphData?.user_graph_data?.arr_of_days || [];

    var options = {
        series: [{
            data: userGraphData?.user_graph_data?.arr_of_count_per_day,
        }],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }],
        xaxis: {
            categories: WeeksDay,
        },
        fill: {
            opacity: 1
        },
        grid: {
            row: {
                opacity: 0.5
            },
            show: false
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        colors: ['#F65256']
    };

    const handleUserStatusChange = (status) => {
        if (status === 'all') {
            setUserStatus({ all: true, active: false });
        } else if (status === 'active') {
            setUserStatus({ all: false, active: true });
        }
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Users Detail', 10, 10);
        const chartData = userGraphData?.user_graph_data?.arr_of_count_per_day;
        const categories = userGraphData?.user_graph_data?.arr_of_days;

        const formattedChartData = chartData.map((value, index) => ({
            x: categories[index],
            y: value
        }));

        doc.autoTable({
            startY: 30,
            head: [['Days', 'UsersData']],
            body: formattedChartData.map(({ x, y }) => [x, y, ''])
        });
        doc.save('userData.pdf');
    };


    return (
        <div>

            <div class="graph-div">
                {loading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box> :
                    <>
                        <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <Grid container direction={"row"} spacing={1}>
                                <Grid item lg={8} md={8} xs={12} sm={12}>
                                    <Stack direction={"row"} spacing={1}>
                                        <CustomSelect
                                            sx={{ width: "110px" }}
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={selectedprovince}
                                            onChange={handleChangeProvince}
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>
                                                Select a province
                                            </MenuItem>
                                            {provinces.map((item, index) => (
                                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                            ))}
                                        </CustomSelect>


                                        <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                            <Badge badgeContent="" variant="dot" color='success' />
                                            <span style={{ color: "#949494", fontSize: "12px" }}>{userGraphData?.total_users}(total users)</span>
                                        </Stack>

                                        <Button size='small' variant="outlined" color={userStatus?.all ? "error" : "inherit"} name="all"
                                            value={userStatus?.all}
                                            onClick={() => handleUserStatusChange("all")} sx={{ textTransform: "capitalize", fontSize: "12px" }}>All users</Button>
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            sx={{ textTransform: "capitalize", fontSize: "12px" }}
                                            color={userStatus?.active ? "error" : "inherit"}
                                            name="active"
                                            value={userStatus?.active}
                                            onClick={() => handleUserStatusChange("active")}
                                        >
                                            Active users
                                        </Button>
                                    </Stack>
                                </Grid>

                                <Grid item lg={4} md={4} xs={12} sm={12} display={"flex"} justifyContent={"flex-end"}>
                                    <Stack direction={"row"} spacing={1.5} alignItems={"center"}>

                                        <div class="right-div">
                                            <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                                                <img src={pdficon} alt="img" />
                                            </div>

                                            <TextField type="date" sx={{
                                                width: "130px",
                                                border: "none",
                                                outline: "none",
                                                "& .MuiInputBase-input": {
                                                    padding: "5px 5px",
                                                }
                                            }} />

                                        </div>
                                    </Stack>

                                </Grid>
                            </Grid>

                            {/* </div> */}

                        </Box>
                        {/* </div> */}
                        <div id="stackedchart">
                            <ReactApexChart options={options} series={options.series} type="bar" height={300} />
                        </div>
                    </>
                }

            </div>
        </div >
    )
}

export default UserCard