import Axios from "axios";
import { backendUrl } from "../utils";

let uploadImage = "/dashboard/apkvideo/";
let allvideoApi = "/dashboard/apkvideo_list/"



const UploadVideoApi = {

    getAllVideo(params, token) {
        return Axios({
            url: backendUrl + allvideoApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getSingleVideo(rowid, token) {
        return Axios({
            url: backendUrl + uploadImage + rowid,
            method: "get",
            headers: {
                "content-type": "application/json",
                Authorization: `token ${token}`,
            },
        }).then((response) => {
            return response.data;
        });
    },

    UploadVideo(params, token) {
        return Axios({
            url: backendUrl + uploadImage,
            method: "post",
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            },
        }).then((response) => {
            return response.data;
        });
    },

    UpdateVideo(rowid, params, token) {
        return Axios({
            url: backendUrl + uploadImage + rowid,
            method: "patch",
            data: params,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `token ${token}`,
            },
        }).then((response) => {
            return response.data;
        });
    },


    deleteVideo(params, token) {
        return Axios({
            url: backendUrl + uploadImage ,
            data:params,
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },










}

export default UploadVideoApi;
