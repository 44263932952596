import React from 'react'
import Sidebar from '../../../components/Sidbar'
import Coupons from '../../../components/Information/coupons'
import { Box } from '@mui/material'

const CouponsPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <Coupons />
            </Box>
        </div>
    )
}

export default CouponsPage