import React from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { TextField } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Couponscard = ({ informationData, date, setDate }) => {
    const categories = informationData?.coupan_graph_data?.arr_of_days;
    var areaOptions = {
        series: [{
            name: "Desktops",
            data: informationData?.coupan_graph_data?.arr_of_count_per_day,
        }],
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 7,
                opacityFrom: 6,
                opacityTo: 6,
                stops: [0, 60],
                colorStops: [
                    {
                        offset: 0,
                        color: '#DDE0FB'
                    },
                    {
                        offset: 100,
                        color: '#FFFFFF'
                    }
                ]
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#ff0000'],
            width: 2

        },
        grid: {
            row: {
                opacity: 0.5
            },
            show: false
        },
        xaxis: {
            categories: categories,
        },
        tooltip: {
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    '<div class="custom-tooltip">' +
                    '<div class="tooltip-line"></div>' +
                    '<span class="units">' + series[seriesIndex][dataPointIndex] + ' Coupens</span>' +
                    '</div>'
                );
            }
        }

    };

    const HandleDateChange = (e) => {
        setDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Coupons Detail', 10, 10);
        const chartData = informationData?.coupan_graph_data?.arr_of_count_per_day;
        const categories = informationData?.coupan_graph_data?.arr_of_days;

        const formattedChartData = chartData.map((value, index) => ({
            x: categories[index],
            y: value
        }));

        doc.autoTable({
            startY: 30,
            head: [['Days', 'Coupons']],
            body: formattedChartData.map(({ x, y }) => [x, y, ''])
        });
        doc.save('coupons.pdf');
    };


    return (
        <div>
            <div className="graph-div">
                <div className="graphinner-div">
                    <div className="dstextdiv">
                        <h3 className="growthrate" style={{ margin: "0px" }}>Coupons</h3>
                    </div>
                    <div className="right-div">
                        <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                            <img src={pdficon} alt="img" />
                        </div>

                        <TextField type="date" name='date' value={date} onChange={HandleDateChange} sx={{
                            width: "130px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />

                    </div>
                </div>
                <div id="linechart">
                    <ReactApexChart options={areaOptions} series={areaOptions.series} type="area" height={350} />
                </div>
            </div>
        </div>
    )
}

export default Couponscard