import React from 'react'
import Payments from '../../../components/Accounts/payments'
import { Box } from '@mui/material'
import Sidebar from '../../../components/Sidbar'

const PaymentsPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <Payments />
            </Box>
        </div>
    )
}

export default PaymentsPage