// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  background-color: #F9F9F9 !important;
  font-family: 'Poppins', sans-serif;
}

body::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: none;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #EF6165;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,oCAAoC;EACpC,kCAAkC;AACpC;;AAEA;EACE,WAAW,gBAAgB,kCAAkC;AAC/D;;AAEA;EACE,gBAAgB,SAAS,+BAA+B;AAC1D;;AAEA;EACE,yBAAyB,KAAK,8BAA8B;EAC5D,mBAAmB,QAAQ,kCAAkC;;AAE/D;;AAEA;;;GAGG","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  background-color: #F9F9F9 !important;\n  font-family: 'Poppins', sans-serif;\n}\n\nbody::-webkit-scrollbar {\n  width: 10px;               /* width of the entire scrollbar */\n}\n\nbody::-webkit-scrollbar-track {\n  background: none;        /* color of the tracking area */\n}\n\nbody::-webkit-scrollbar-thumb {\n  background-color: #EF6165;    /* color of the scroll thumb */\n  border-radius: 20px;       /* roundness of the scroll thumb */\n  \n}\n\n/* code {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
