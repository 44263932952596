import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputAdornment, OutlinedInput, Paper, Select, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactFileReader from 'react-file-reader';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import { MenuItem } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import PaymentRecipetApi from '../../../apis/PaymentReciept';
import { BootstrapDialog, CustomizedTextField, Item, StyledTableCell, StyledTableRow, customTheme } from '../../../style/commonStyle';
import moment from 'moment';
const PaymentsReceipt = () => {
  console.log(window.location.hostname)
  const navigate = useNavigate()
  const [payments, setPayments] = useState([])
  const [paymentsData, setPaymentsData] = useState({})
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDialoge, setImageDialoge] = useState(false);
  const [loading, setLoading] = useState(false)
  const [createAcceptOpen, setCreateAcceptDialogOpen] = useState(false)
  const [rejectDialog, setRejectDialog] = useState(false)
  const [deleteDialog, setDeleteDialoge] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  console.log(selectedId)
  const [rejectPayment, setRejectPayment] = useState({})
  const [selectedValue, setSelectedValue] = useState("Pending")
  const [createAccept, setCreateAccept] = useState({
    file: null,
    file_name: ""
  })
  const [openDialog, setOpenDialog] = useState(false);
  const [addAcceptdialogOpen, setAcceptDialogeOpen] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [dropDownValue, setDropdownValue] = useState("")
  console.log("dropDownValue>>>>>>>>>>>>")


  const handleClickImage = (imageSrc) => {
    setSelectedImage(imageSrc);
    setImageDialoge(true);
  };

  const handleCloseImage = () => {
    setImageDialoge(false);
  };


  const handleValueChange = (rowId, selectvalue) => {

    const updatedValues = [...selectedValue];
    const rowIndex = payments.findIndex(row => row.id === rowId);
    if (rowIndex !== -1) {
      updatedValues[rowIndex] = selectvalue;
      setSelectedValue(selectvalue);
      setSelectedId(rowId);

      if (selectvalue === "Accepted") {
        setCreateAcceptDialogOpen(true);
      } else if (selectvalue === "Rejected") {
        setRejectDialog(true);
      }
    }
  };


  const handleInTransitClick = () => {
    let payload = {
      payment_proof_status: selectedValue,
      payment_proof: selectedId,

    };
    console.log(payload)
    PaymentRecipetApi.addPaymentProof(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllPaymentRecipet()
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }






  const handleCloseDialog = () => {
    setOpenDialog(false);
  };



  const handleClose = () => {
    setCreateAcceptDialogOpen(false);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialog(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    getAllPaymentRecipet()

  }, [rowsPerPage, currentPage])


  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setRejectPayment(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleCreateAcceptChange = (event) => {
    const { name, value } = event.target;
    setCreateAccept(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const token = localStorage.getItem("token")

  const getAllPaymentRecipet = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    PaymentRecipetApi.getAllPayments(payload, token)
      .then(response => {
        setPayments(response.results);
        setPaymentsData(response)
        const paymentStatuses = response.results.map(payment => payment.payment_proof_status);
        console.log("paymentStatuses>>>>>>>>>>>>", paymentStatuses)
        setDropdownValue(paymentStatuses);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      });
  };


  const OpenAcceptDialoge = () => {
    setAcceptDialogeOpen(true)
  }


  // const handleEditFaqsDialog = (id) => {
  //   handleReject(true)
  //   let rowid = `?id=${id}`
  //   FaqsApi.getSingleFaq(rowid, token)
  //     .then(response => {
  //       setRejectPayment(response.detail)
  //     })
  //     .catch(error => {
  //       console.error("Something went wrong:", error);
  //       setLoading(false);
  //     });
  // }

  const handleDeletePayment = () => {
    setLoadingDelete(true);
    let payload = `${selectedId}`;
    PaymentRecipetApi.deletePayments(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllPaymentRecipet()
        setLoadingDelete(false);
        setDeleteDialoge(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingDelete(false);
      });

  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialoge(false)
  }

  const handleDeletePaymentDiaoge = (id) => {
    setDeleteDialoge(true)
    setSelectedId(id)
  }

  const handleRejectPayments = (e) => {
    e.preventDefault()
    // setLoadingUpdate(true);
    let payload = {
      payment_proof_status: selectedValue,
      message: rejectPayment.question,
      payment_proof: selectedId,

    };
    console.log(payload)
    PaymentRecipetApi.addPaymentProof(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllPaymentRecipet()
        setLoadingUpdate(false);
        setRejectDialog(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }


  const handleCreateAccept = (e) => {
    e.preventDefault()
    const formData = new FormData();

    formData.append("message", createAccept.message)
    formData.append("transaction_id", createAccept.transit_no)
    formData.append("payment_proof_status", selectedValue)
    formData.append("payment_proof", selectedId)
    formData.append('proof_photo', createAccept.file);
    PaymentRecipetApi.addPaymentProof(formData, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        getAllPaymentRecipet()
        setCreateAccept({})
        setLoadingCreate(false);
        setCreateAcceptDialogOpen(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingUpdate(false);
      });
  }


  const uploadBGImage = (files) => {
    const bgfile = files.fileList[0];
    setCreateAccept((prevState) => ({
      ...prevState,
      file_name: bgfile.name,
      file: bgfile,
      background_img: URL.createObjectURL(bgfile),
    }));
  };


  const startIndex = (currentPage - 1) * rowsPerPage;

  return (
    <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>

      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
        <Typography className='infromation' >Payments Receipt</Typography>

      </Stack>

      <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
        <Grid item xs={12}>
          <Item>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress sx={{ color: "#F96064" }} />
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                <Table aria-label="customized table dense">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                      <StyledTableCell align="left">Full Name</StyledTableCell>
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="left">Bill Copy</StyledTableCell>
                      <StyledTableCell align="left">Payment Proof</StyledTableCell>
                      <StyledTableCell align="left">Store Name</StyledTableCell>
                      <StyledTableCell align="left">Bank Name</StyledTableCell>
                      <StyledTableCell align="left">Account Number</StyledTableCell>
                      <StyledTableCell align="left">Transit Number</StyledTableCell>
                      <StyledTableCell align="left">Insitiution Number</StyledTableCell>
                      <StyledTableCell align="left">Interac e-Transfer ID</StyledTableCell>
                      <StyledTableCell align="left">Date</StyledTableCell>
                      <StyledTableCell align="left">Address</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                      <StyledTableCell align="left">Action</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments ? (
                      payments.length > 0 ? (
                        payments.map((row, i) => (
                          <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                              {startIndex + i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{}}>{row.full_name}</StyledTableCell>
                            <StyledTableCell align="left" sx={{}}>{row.email}</StyledTableCell>
                            <StyledTableCell align="left" sx={{ cursor: "pointer" }} onClick={() => handleClickImage(row.bill_copy)}>
                              <img src={row.bill_copy} width={50} height={40} alt="Banner" />
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{ cursor: "pointer" }} onClick={() => handleClickImage(row.payment_copy)}>
                              <img src={row.payment_copy} width={50} height={40} alt="Invoice" />
                            </StyledTableCell>
                            <StyledTableCell align="left" sx={{}}>{row.store_name}</StyledTableCell>
                            <StyledTableCell align="left" sx={{}}>{row.bank?.name}</StyledTableCell>
                            <StyledTableCell align="left">{row.bank?.account_no}</StyledTableCell>
                            <StyledTableCell align="left">{row.bank?.transit_number}</StyledTableCell>
                            <StyledTableCell align="left">{row.bank?.institution_number}</StyledTableCell>
                            <StyledTableCell align="left">{row.bank?.email}</StyledTableCell>
                            <StyledTableCell align="left" sx={{ width: "100px !important" }}>{moment(row.created_at).format('l')}</StyledTableCell>
                            <StyledTableCell align="left">{row.bank?.address}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Select
                                sx={{ width: "120px" }}
                                size='small'
                                value={dropDownValue[i] || selectedValue[i]}
                                onChange={(event) => handleValueChange(row.id, event.target.value)}
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="In Transitive" sx={{ color: "orange" }} onClick={handleInTransitClick}>In Transit</MenuItem>
                                <MenuItem value="Accepted" sx={{ color: "green" }}>Successfull</MenuItem>
                                <MenuItem value="Rejected" sx={{ color: "red" }}>Cancelled</MenuItem>
                                <MenuItem value="Pending" sx={{ color: "orange" }}>Requested</MenuItem>


                              </Select>
                            </StyledTableCell>
                            <StyledTableCell >
                              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Box sx={{ color: "red", cursor: "pointer" }}>
                                  <DeleteOutlineOutlinedIcon onClick={() => handleDeletePaymentDiaoge(row.id)} />
                                </Box>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            Data not found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>

                </Table>
              </TableContainer>
            )}


            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
              <Pagination
                count={Math.ceil(paymentsData.count / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Item>
        </Grid>

      </Grid>

      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={createAcceptOpen}
        >
          <form onSubmit={handleCreateAccept}>
            <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
              Accept Payment
            </DialogTitle>
            <DialogContent dividers sx={{ width: "500px" }}>
              <Stack direction={"column"} spacing={2}>
                <FormControl size='small' variant="outlined">
                  <OutlinedInput
                    disabled
                    required
                    id="outlined-adornment-password"
                    value={createAccept.image}
                    startAdornment={
                      <InputAdornment position="start">
                        <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadBGImage}>
                          <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Box sx={{ display: "flex" }}>
                              <DriveFolderUploadIcon />
                              {/* <Typography className='choosefile'>Choose file</Typography> */}
                            </Box>
                            {createAccept.file_name ? createAccept.file_name : <Typography className='nofile'>No file chosen</Typography>}
                          </Stack>
                        </ReactFileReader>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <CustomizedTextField required value={createAccept.message} name='message' onChange={handleCreateAcceptChange} fullWidth variant='outlined' size='small' placeholder='Message' />
                <CustomizedTextField required value={createAccept.transit_no} name='transit_no' onChange={handleCreateAcceptChange} fullWidth variant='outlined' size='small' placeholder='Transit No' />

              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleClose}>
                Close
              </Button>
              <Button variant='contained'
                type='submit'
                sx={{
                  backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                  color: 'white',
                  textTransform: "capitalize"

                }} disabled={loadingCreate} >
                {loadingCreate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Save"}
              </Button>
            </DialogActions>
          </form>

        </BootstrapDialog>
      </ThemeProvider>




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="xs"
          onClose={handleCloseRejectDialog}
          open={rejectDialog}
        >
          <form onSubmit={handleRejectPayments}>
            <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
              Reject Payment
            </DialogTitle>
            <DialogContent dividers>
              <Stack direction={"column"} spacing={2}>
                <TextField required value={rejectPayment.question} name='question' onChange={handleUpdateChange} fullWidth variant='outlined' size='small' placeholder='Message' />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseRejectDialog}>
                Close
              </Button>
              <Button
                type='submit'
                variant='contained'
                sx={{
                  backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                  color: 'white',
                  textTransform: "capitalize"
                }}
                disabled={loadingUpdate}
              >
                {loadingUpdate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Update"}
              </Button>
            </DialogActions>
          </form>

        </BootstrapDialog>
      </ThemeProvider>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Accept Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to accept?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={addAcceptdialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography className='addpartner' sx={{ mb: 4 }}>Accept</Typography>
            <Stack direction={"column"} spacing={2}>
              <CustomizedTextField fullWidth variant='outlined' size='small' placeholder='Your Name here' />
              <FormControl size='small' variant="outlined">
                <OutlinedInput
                  disabled
                  id="outlined-adornment-password"
                  value={createAccept.image}
                  startAdornment={
                    <InputAdornment position="start">
                      <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadBGImage}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                          <Box sx={{ display: "flex" }}>
                            <DriveFolderUploadIcon />
                            {/* <Typography className='choosefile'>Choose file</Typography> */}
                          </Box>
                          {createAccept.file_name ? createAccept.file_name : <Typography className='nofile'>No file chosen</Typography>}
                        </Stack>
                      </ReactFileReader>
                    </InputAdornment>
                  }
                />
              </FormControl>

            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "20px", mb: "15px" }}>
          {/* <Button variant='contained'
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                        }} onClick={} fullWidth>
                        Next
                    </Button> */}
        </DialogActions>
      </Dialog>




      <ThemeProvider theme={customTheme} >

        <BootstrapDialog
          fullWidth
          maxWidth="xs"
          onClose={handleCloseDeleteDialog}
          open={deleteDialog}

        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
            Delete
          </DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you want to delete?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                textTransform: "capitalize"
              }}
              onClick={handleDeletePayment}
              disabled={loadingDelete}
            >
              {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </ThemeProvider>

      <Dialog fullWidth maxWidth="sm" open={imageDialoge} onClose={handleCloseImage}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <DialogTitle>Image Preview</DialogTitle>
          <Box sx={{ cursor: "pointer", marginRight: "15px" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleCloseImage} /></Box>
        </Stack>
        <DialogContent>
          {selectedImage && <img width="100%" height={"400"} src={selectedImage} alt="Preview" />}
        </DialogContent>
      </Dialog>

    </Box>
  )
}

export default PaymentsReceipt