import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Paper, Switch, TextField, ThemeProvider, Typography, createTheme, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UserSlots from './UserSlots';
import CoupenApi from '../../../apis/couponsApi';
import TablePagination from '../../TablePagination';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import { BootstrapDialog, Item, StyledTableCell, StyledTableRow, customTheme } from '../../../style/commonStyle';

const Coupons = () => {
  const navigate = useNavigate()
  const [couponsData, setCouponsData] = useState([])
  const [coupons, setCoupons] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [showSingleSlot, setShowSingleSlot] = useState(false)
  const [createCoupenOpen, setCreateCoupenOpen] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [userSlotID, setUserSlotID] = useState(null)
  const [loading, setLoading] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [automationStatus, setAutomationStatus] = useState(null)
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: 'Grocery', checked: false, value: 1 },
    { id: 2, label: 'ticket', checked: false, value: 1 },
    { id: 3, label: 'Mobile', checked: false, value: 1 },
    { id: 4, label: 'Gold', checked: false, value: 1 },
    { id: 5, label: 'LED TV', checked: false, value: 1 },

  ]);
  const [newCheckboxLabel, setNewCheckboxLabel] = useState("");

  const handleInputChange = (id) => (event) => {
    // Get the new value from the input field
    const newValue = event.target.value;
    // Update the state based on the ID
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.map(checkbox =>
        checkbox.id === id ? { ...checkbox, value: newValue } : checkbox
      )
    );
  };

  const handleAddNewCheckbox = () => {
    if (!newCheckboxLabel.trim()) {
      toast.error("Please enter a value for the new checkbox");
      return;
    }
    const newId = checkboxes.length + 1;
    const newCheckbox = {
      id: newId,
      label: newCheckboxLabel,
      checked: false,
      value: 1
    };
    setCheckboxes(prevCheckboxes => [...prevCheckboxes, newCheckbox]);
    setNewCheckboxLabel("");
  };


  const handleMinusValue = (id) => {
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.map(checkbox =>
        checkbox.id === id ? { ...checkbox, value: Math.max(1, checkbox.value - 1) } : checkbox
      )
    );
  };

  const handleAddValue = (id) => {
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.map(checkbox =>
        checkbox.id === id ? { ...checkbox, value: checkbox.value + 1 } : checkbox
      )
    );
  };

  const handleCheckboxChange = (id) => {
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.map(checkbox =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
  };

  const handleRemove = (id) => {
    setCheckboxes(prevCheckboxes =>
      prevCheckboxes.filter(checkbox => checkbox.id !== id)
    );
  };



  const rowsPerPage = 40;


  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const OpenCoupenDialoge = () => {
    setCreateCoupenOpen(true)
  }

  const handleClose = () => {
    setCreateCoupenOpen(false);
  };


  const handleCloseApproveDialog = () => {
    setApproveDialog(false)
  }

  const handleApproveDialoge = (id) => {
    setApproveDialog(true)
    setUserSlotID(id);
  }

  const handleUserSlots = (id) => {
    setUserSlotID(id);
    setShowSingleSlot(true)
  }

  const token = localStorage.getItem("token")

  const handleAutomation = () => {
    setLoadingApprove(true)
    let rowid = `/${userSlotID}/`;
    CoupenApi.automationStatus(rowid, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        setApproveDialog(false)
        getAllCoupens();
        setLoadingApprove(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingApprove(false);
      });
  }


  const handleAutomationChanged = (id, newBlockStatus) => {
    setUserSlotID(id)
    setApproveDialog(true)
    setAutomationStatus(newBlockStatus)


  };


  const handleCraeteCoupons = () => {

    setLoadingCreate(true)


    const checkedCheckboxes = checkboxes.filter(checkbox => checkbox.checked);

    if (checkedCheckboxes.length === 0) {
      toast.error("Please select at least one  value");
      setLoadingCreate(false);
      return;
    }
    const payload = {
      data: checkboxes
        .filter(checkbox => checkbox.checked)
        .map(checkbox => ({
          title: checkbox?.label.toLowerCase(),
          slot_draw: checkbox?.value
        }))
    };

    CoupenApi.CreateCoupon(payload, token)
      .then(response => {
        toast.success(response.detail, { autoClose: 1400 });
        setCreateCoupenOpen(false)
        getAllCoupens();
        const updatedCheckboxes = checkboxes.map(checkbox => ({
          ...checkbox,
          checked: false
        }));
        setCheckboxes(updatedCheckboxes);
        setLoadingCreate(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoadingCreate(false);
      });
  }



  const getAllCoupens = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    CoupenApi.getAllCouponsList(payload, token)
      .then(response => {
        setCouponsData(response.results);
        setCoupons(response)
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      });
  };

  useEffect(() => {
    getAllCoupens()
  }, [currentPage, rowsPerPage])

  const startIndex = (currentPage - 1) * rowsPerPage;
  return (
    <>
      {showSingleSlot ?
        <UserSlots setShowSingleSlot={setShowSingleSlot} userSlotID={userSlotID} /> :
        <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography className='infromation' sx={{ mb: 2 }}>Coupons</Typography>
            <Button
              size='small'
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                color: 'white',
                fontSize: "12px"
              }}
              onClick={OpenCoupenDialoge}
            >
              Create
            </Button>
          </Stack>

          <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
            <Grid item xs={12}>
              <Item>
                {loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress sx={{ color: "#F96064" }} />
                  </Box>
                ) : (
                  <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                    <Table aria-label="customized table dense">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                          <StyledTableCell align="left">Title</StyledTableCell>
                          <StyledTableCell align="left" width={20}>Start Date</StyledTableCell>
                          <StyledTableCell align="left">End Date</StyledTableCell>
                          <StyledTableCell align="left">Automatic</StyledTableCell>
                          <StyledTableCell align="left">Slots</StyledTableCell>
                          <StyledTableCell align="center">Coupon counts</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          couponsData.map((row, i) => (
                            <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                              <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                {startIndex + i + 1}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.title}</StyledTableCell>
                              <StyledTableCell align="left" style={{ width: "17%" }}>  {moment(row.start_date).format('MMM. D, YYYY, h:mm a')}</StyledTableCell>
                              <StyledTableCell align="left">  {moment(row.end_date).format('MMM. D, YYYY, h:mm a')}</StyledTableCell>
                              {/* <StyledTableCell align="left"><span style={{ backgroundColor: "#DAE6D1", color: "#45A843", padding: "3px 10px", borderRadius: "4px", fontWeight: "600", fontSize: "12px" }}>{row.is_auto.toString()}</span></StyledTableCell> */}
                              <StyledTableCell>
                                <Switch
                                  checked={row.is_auto}
                                  onChange={(e) => handleAutomationChanged(row.id, e.target.checked)}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                      color: '#45A843',
                                    },
                                    '& .MuiSwitch-track': {
                                      backgroundColor: '#45A843',
                                    },
                                  }}
                                />

                              </StyledTableCell>
                              <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                <span style={{ cursor: "pointer" }} onClick={() => handleUserSlots(row.id)}>
                                  <VisibilityIcon color='primary' />
                                </span>
                              </StyledTableCell>
                              <StyledTableCell align='center'>
                                {row.slot_count}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        }
                      </TableBody>

                    </Table>
                  </TableContainer>
                )}


                <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                  <TablePagination
                    totalItems={coupons}
                    itemsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handlePageChange}
                  />
                </Box>
              </Item>
            </Grid>

          </Grid>



          <BootstrapDialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={createCoupenOpen}
            sx={{ padding: "30px" }}
          >

            <DialogContent dividers sx={{ padding: "30px" }} >
              <Grid container direction={"row"} alignItems={"center"}>
                <Grid item xs={3}>
                  <Button variant='outlined' sx={{
                    color: "#AFAFAF", textTransform: "capitalize", borderColor: "#808080", '&:hover': {
                      borderColor: "#808080"
                    }
                  }} size='small'> Object</Button>
                </Grid>

                <Grid container item xs={9} alignItems={"center"}>
                  {Array.isArray(checkboxes) && checkboxes.map(checkbox => (
                    <>
                      <Grid item xs={2.4} alignItems={"center"}>
                        <FormControlLabel
                          label={checkbox.label}
                          control={
                            <Checkbox
                              size='small'
                              color="default"
                              checked={checkbox.checked}
                              onChange={() => handleCheckboxChange(checkbox.id)}
                              inputProps={{ 'aria-label': checkbox.label }}
                            />
                          }
                        />
                        {checkbox.checked && (
                          <Stack direction={"row"} alignItems={"center"} sx={{ border: "1px solid #D9D9D9", borderRadius: "8px", justifyContent: "center", mr: 6, p: .5 }}>
                            <button style={{ border: "none", background: "none", cursor: "pointer" }} onClick={() => handleMinusValue(checkbox.id)}>-</button>
                            <input style={{ width: "40px", border: "none" }} type="number" value={checkbox.value} onChange={handleInputChange(checkbox.id)} />
                            <button style={{ border: "none", background: "none", cursor: "pointer" }} onClick={() => handleAddValue(checkbox.id)}>+</button>
                          </Stack>
                        )}

                      </Grid>
                      <Grid item xs={.5}>
                        <CancelIcon onClick={() => handleRemove(checkbox.id)} />
                      </Grid>
                    </>
                  ))}

                </Grid>
                <Divider orientation='horizontal' sx={{ width: "100%", mb: 2, mt: 2 }} />
                <Grid xs={3}>
                  <Stack direction={"row"} spacing={1} alignItems={"end"}>
                    <TextField variant='standard' label="Create Object" color='error' name='create'
                      value={newCheckboxLabel} onChange={(e) => setNewCheckboxLabel(e.target.value)} />
                    <Button variant='outlined' color='error' size='small' sx={{ textTransform: "capitalize", width: "80px" }} onClick={handleAddNewCheckbox}>Add</Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>

              <Button variant='contained'

                sx={{
                  backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                  color: 'white',
                  width: "100px",
                  textTransform: "capitalize"

                }} disabled={loadingCreate} onClick={handleCraeteCoupons}>
                {loadingCreate ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Apply"}
              </Button>
            </DialogActions>
          </BootstrapDialog>


          <ThemeProvider theme={customTheme} >

            <BootstrapDialog
              fullWidth
              maxWidth="xs"
              onClose={handleCloseApproveDialog}
              open={approveDialog}

            >

              <DialogContent>

                <Typography sx={{ textAlign: "center", p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }}>Are you sure you want to change setting from {automationStatus === true ? 'manual to automatic' : "automatic to manual "}?</Typography>

              </DialogContent>
              <DialogActions>
                <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseApproveDialog}>
                  No
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                    color: 'white',
                    textTransform: "capitalize"
                  }}
                  onClick={handleAutomation}
                  disabled={loadingApprove}
                >
                  {loadingApprove ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Change"}
                </Button>
              </DialogActions>

            </BootstrapDialog>
          </ThemeProvider>

        </Box >
      }

    </>
  )
}

export default Coupons