import Axios from "axios";
import { backendUrl } from "../utils";

const AuthApi = {
  signin(params) {
    params.screenResolution = window.outerWidth + "x" + window.outerHeight;
    return Axios({
      url: backendUrl + '/dashboard/login', // Update the endpoint
      method: 'post',
      data: params,
      headers: { 'content-type': 'application/json' }
    }).then(response => {
      return response.data;
    });
  },
  signout(params) {
    return Axios({
      url: backendUrl + '/dashboardapi/logout',
      method: 'post',
      data: params,
      headers: {
        'content-type': 'application/json',
        // Authorization: 'Bearer ' + localStorage.getItem('token') 
      }
    }).then(response => {
      return response.data;
    });
  },
};

export default AuthApi;
