import React, { useState } from 'react'
import bannerimg from '../../../../Assests/images/bannerimg.png'
import pdficon from '../../../../Assests/images/pdf.png'
import { Badge, Box,Divider, Grid, MenuItem,Stack, Typograph, Typography } from '@mui/material'
import { CustomSelect } from '../../../../style/commonStyle'

const bannersdata = [
    {
        id: 1, pic: bannerimg, payment: "Payment to AB", date: "Date- 01/01/2024,10:24AM", value: 25,
    },

    {
        id: 2, pic: bannerimg, payment: "Payment to AB", date: "Date- 01/01/2024,10:24AM", value: 25,
    },
    {
        id: 3, pic: bannerimg, payment: "Payment to AB", date: "Date- 01/01/2024,10:24AM", value: 25,
    },
    {
        id: 4, pic: bannerimg, payment: "Payment to AB", date: "Date- 01/01/2024,10:24AM", value: 25,
    },
    {
        id: 5, pic: bannerimg, payment: "Payment to AB", date: "Date- 01/01/2024,10:24AM", value: 25,
    },

]

const ExpensesCard = () => {
    const [expensesData, setExpensesData] = useState(bannersdata)
    const [province, setProvince] = useState("Ontario");

    const handleChange = (event) => {
        setProvince(event.target.value);
    };

    return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
            {/* <div className="testimonialhead">
                <div>
                    <h3 className="growthrate">All partners</h3>
                </div>
                <div>
                    <div className="datediv">
                        <input type="date" style={{ border: "none", outline: "none" }} />

                        <i className="fas fa-calendar"></i>
                    </div>
                </div>
            </div> */}

            <Grid container direction={"row"} mt={1.5} mb={3}>
                <Grid item xs={4}>
                    <Typography className='parttnershead'>All partners</Typography>
                </Grid>

                <Grid item xs={8}>

                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <CustomSelect
                            sx={{ width: "110px" }}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={province}
                            onChange={handleChange}
                        >
                            <MenuItem value={"Newfoundland and Labrador"}>Newfoundland and Labrador</MenuItem>
                            <MenuItem value={"Prince Edward Island"}>Prince Edward Island</MenuItem>
                            <MenuItem value={"Nova Scotia"}>Nova Scotia</MenuItem>
                            <MenuItem value={"New Brunswick"}>New Brunswick</MenuItem>
                            <MenuItem value={"Quebec"}>Quebec</MenuItem>
                            <MenuItem value={"Ontario"}>Ontario</MenuItem>
                            <MenuItem value={"Manitoba"}>Manitoba</MenuItem>
                            <MenuItem value={"Saskatchewan"}>Saskatchewan</MenuItem>
                            <MenuItem value={"Alberta"}>Alberta</MenuItem>
                            <MenuItem value={"British Columbia"}>British Columbia</MenuItem>
                        </CustomSelect>
                        <img width={20} height={20} src={pdficon} alt='pdf' />
                        <div className="datediv">
                            <input type="date" style={{ border: "none", outline: "none" }} />
                            <i className="fas fa-calendar"></i>
                        </div>
                    </Stack>
                </Grid>
            </Grid>
            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"} mb={2}>
                <Badge badgeContent="" variant="dot" color='error' />
                <span style={{ color: "#949494", fontSize: "12px" }}>$32K(Total expenses)</span>
            </Stack>
            <Grid container direction={"row"} alignItems={"center"} mt={2}>
                {expensesData.map((banner) => (
                    <React.Fragment key={banner.id}>
                        <Grid item xs={8}>
                            <Stack direction={"row"} spacing={2}>
                                <Box sx={{ borderRadius: "50%" }}>
                                    <img width={44} height={44} style={{ borderRadius: "50%" }} src={banner.pic} alt="" />
                                </Box>

                                <Stack direction={"column"} spacing={0.5}>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "15px", color: "#000000", fontWeight: "500" }}>
                                        {banner.payment}
                                    </Typography>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                        {banner.date}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#F44345", fontWeight: "500" }}>
                                $ - {banner.value}
                            </Typography>
                        </Grid>

                        <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 2 }} />

                    </React.Fragment>
                ))}

            </Grid>

        </div>
    )
}

export default ExpensesCard