// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.province-select .MuiOutlinedInput-notchedOutline {
    border-color: #FFC0CB !important;
}

.province-select .MuiOutlinedInput-notchedOutline:hover {
    border-color: red !important;
}

.main-div {
    /* padding: "25px", width: '100%', overflow: 'auto'  */
    padding: 25px !important;
    width: 100% !important;
    overflow: auto !important;
}


@media only screen and (max-width: 768px) {
    .main-div {
        padding: 0px !important;
        padding-top: 80px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Information/User/index.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,sDAAsD;IACtD,wBAAwB;IACxB,sBAAsB;IACtB,yBAAyB;AAC7B;;;AAGA;IACI;QACI,uBAAuB;QACvB,4BAA4B;IAChC;AACJ","sourcesContent":[".province-select .MuiOutlinedInput-notchedOutline {\n    border-color: #FFC0CB !important;\n}\n\n.province-select .MuiOutlinedInput-notchedOutline:hover {\n    border-color: red !important;\n}\n\n.main-div {\n    /* padding: \"25px\", width: '100%', overflow: 'auto'  */\n    padding: 25px !important;\n    width: 100% !important;\n    overflow: auto !important;\n}\n\n\n@media only screen and (max-width: 768px) {\n    .main-div {\n        padding: 0px !important;\n        padding-top: 80px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
