import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge, Box, Stack, TextField, Typography } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import TablePagination from '../../TablePagination';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { StyledTableCell, StyledTableRow } from '../../../style/commonStyle';


const TransactionCard = ({ transactionCardData, transactionCardDate, setTransactionCardDate, provinces, selectedprovince, setSelectedProvince, Page, setPage, rowsPerPage }) => {

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeProvince = (event) => {
        setSelectedProvince(event.target.value);
    };


    const handleDateChange = (e) => {
        setTransactionCardDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Transaction Card Data', 10, 10);
        const tableData = transactionCardData?.results?.map(row => [
            row.store_name ? row.store_name : 'N/A',
            row.transaction_status,
            row.transaction_type ? row.transaction_type : "N/A",
            row.contact ? row.contact : "N/A",
            row.amount
        ]) || [];

        doc.autoTable({
            startY: 20,
            head: [['Store Name', 'Transaction Status', 'Transaction Type', 'Contact', 'Payment']],
            body: tableData,
        });

        doc.save('transaction_card_data.pdf');
    };

    return (
        <div style={{ padding: "10px 20px 20px 20px" }}>


            <div className="graphinner-div">
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography className='parttnershead'>Transactions</Typography>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                        <Badge badgeContent="" variant="dot" color='success' />
                        <span style={{ color: "#949494", fontSize: "12px" }}>{transactionCardData?.count} members</span>
                    </Stack>
                </Stack>
                <div className="right-div">
                    <div onClick={generatePDF} style={{ cursor: "pointer" }}>
                        <img width={20} height={20} src={pdficon} alt="img" />
                    </div>
                    <div>
                     
                    </div>
                    <div>
                        <TextField type="date" name='transactionCardDate' value={transactionCardDate} onChange={handleDateChange} sx={{
                            width: "120px",
                            border: "none",
                            outline: "none",
                            "& .MuiInputBase-input": {
                                padding: "5px 5px",
                            }
                        }} />
                    </div>
                </div>
            </div>

            <TableContainer component={Paper} elevation={0} scroll className='dbTable' >
                <Table aria-label="customized table dense">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ paddingLeft: 3 }}>Store Name</StyledTableCell>
                            <StyledTableCell align="left">Transaction Status</StyledTableCell>
                            <StyledTableCell align="left">Transaction Type</StyledTableCell>
                            <StyledTableCell align="left">Contact</StyledTableCell>
                            <StyledTableCell align="left">Payment</StyledTableCell>
                            <StyledTableCell align="left">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionCardData?.results?.map((row, i) => (
                            <>
                                <StyledTableRow key={i} sx={{ width: "100%" }}>
                                    <StyledTableCell component="th" scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3, fontWeight: 600 }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <img width={32} height={32} src={row.storeimg} alt={row.storeimg} />
                                            <Stack direction={'column'} >
                                                <Typography className='storename'>{row.store_data?.name}</Typography>
                                                <Typography className='partnerdate'>{moment(row.created_at).format('MMMM DD YYYY')}</Typography>
                                            </Stack>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left"><Typography className='location'>{row.transaction_status}</Typography></StyledTableCell>
                                    <StyledTableCell align="left"><Typography className='location'>{row.transaction_type}</Typography></StyledTableCell>
                                    <StyledTableCell align="left"><Typography className='location'>{row.contact}</Typography></StyledTableCell>
                                    <StyledTableCell >
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <CheckCircleIcon sx={{ color: "green" }} />
                                            <Typography className='location'>${row.amount}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                        <IconButton
                                        // onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                        >


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={props.handleEdit}
                                            >Edit</MenuItem>


                                            <MenuItem className="my_menu_item_imp"
                                            // onClick={() => handleSelect("delete")}
                                            >Delete</MenuItem>

                                        </Menu>
                                    </StyledTableCell>
                                </StyledTableRow>

                            </>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                <TablePagination
                    totalItems={transactionCardData}
                    itemsPerPage={rowsPerPage}
                    page={Page}
                    onPageChange={handlePageChange}
                />
            </Box>


        </div>
    )
}

export default TransactionCard