import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import TestomonialApi from '../../../apis/TestomonialApi';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog, CustomizedTextField, Item, StyledTableCell, StyledTableRow, customTheme } from '../../../style/commonStyle';

const Testomonial = () => {
    const [testomonial, setTestomonial] = useState([])
    const [testomonialData, setTestomonialData] = useState({})
    const [loading, setLoading] = useState(false)
    const [deleteDialog, setDeleteDialoge] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [showVideoDialoge, setShowVideoDialoge] = useState(false)
    const [approveDialog, setApproveDialog] = useState(false)
    const [loadingApprove, setLoadingApprove] = useState(false)
    const [rowID, setRowID] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [videoId, setVideoId] = useState("")
    const [text, setText] = useState("");
    const [rejectdialoge, setRejectDialoge] = useState(false);



    const rowsPerPage = 40;
    const token = localStorage.getItem("token")


    const handleNotificationChange = (e) => {
        setText(e.target.value);
    };

    const handleClose = () => {
        setRejectDialoge(false);
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };


    useEffect(() => {
        getAllTestamonial()
    }, [rowsPerPage, currentPage])


    const handleSendNotification = () => {
        // setLoading(true);
        // let payload = {
        //   text: text,
        // };

        // UsersApi.sendNotification(payload, token)
        //   .then((response) => {
        //     toast.success("Sent Successfully", { autoClose: 1400 });
        //     setLoading(false);
        //     setText("")
        //   })
        //   .catch((error) => {
        //     console.error("Something went wrong:", error);
        //     setLoading(false);
        //   });
    };




    const getAllTestamonial = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        TestomonialApi.getAllTestamonialList(payload, token)
            .then(response => {
                setTestomonial(response.results);
                setTestomonialData(response)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };


    const handleDeleteTestamonial = () => {
        setLoadingDelete(true);
        let payload = `?id=${selectedId}`;
        TestomonialApi.deleteTestamonial(payload, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                getAllTestamonial()
                setLoadingDelete(false);
                setDeleteDialoge(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoadingDelete(false);
            });

    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialoge(false)
    }

    const handleCloseApproveDialog = () => {
        setApproveDialog(false)
    }

    const handleCloseVideoDialog = () => {
        setShowVideoDialoge(false)
    }

    const handleDeleteTestamonialDiaoge = (id) => {
        setDeleteDialoge(true)
        setSelectedId(id)
    }


    const handleApproveDialoge = (id) => {
        setApproveDialog(true)
        setRowID(id);
    }
    const handleRejectDialoge = (id) => {
        setRejectDialoge(true)
        setRowID(id);
    }



    const handleApproveTestomonial = () => {
        setLoadingApprove(true)
        let payload = `?status=${"true"}&id=${rowID}`
        TestomonialApi.approveTestamonial(payload, token)
            .then(response => {
                toast.success("Approve Successfully", { autoClose: 1400 });
                setLoadingApprove(false)
                setApproveDialog(false)
                getAllTestamonial()
            })
            .catch(error => {
                console.error("Something went wrong:", error);
            });
    }

    const handleRejectTestomonial = (e) => {
        e.preventDefault()
        setLoadingApprove(true)
        let payload = {
            message: text,
            id: rowID
        }
        TestomonialApi.rejectTestamonial(payload, token)
            .then(response => {
                toast.success("Rejected Successfully", { autoClose: 1400 });
                setLoadingApprove(false)
                setRejectDialoge(false)
                getAllTestamonial()
            })
            .catch(error => {
                console.error("Something went wrong:", error);
            });
    }



    const handleDownloadVideo = () => {
        const link = document.createElement('a');
        link.href = videoId;
        link.download = 'video.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const startIndex = (currentPage - 1) * rowsPerPage;

    return (
        <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
                <Typography className='infromation' >Testimanials</Typography>

                {/* <Button
                    variant='contained'
                    sx={{
                        backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                        color: 'white',
                        fontSize: "12px"
                    }}
                    onClick={OpenBannerDialoge}
                >
                    Create Testamonial
                </Button> */}
            </Stack>

            <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                <Grid item xs={12}>
                    <Item>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <CircularProgress sx={{ color: "#F96064" }} />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                <Table aria-label="customized table dense">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                            <StyledTableCell align="left">Full Name</StyledTableCell>
                                            <StyledTableCell align="left">Email</StyledTableCell>
                                            <StyledTableCell align="left">Video</StyledTableCell>
                                            <StyledTableCell align="left">Image</StyledTableCell>
                                            <StyledTableCell align="left">Approve</StyledTableCell>
                                            <StyledTableCell align="left">DisApprove</StyledTableCell>
                                            <StyledTableCell align="left">Created at</StyledTableCell>
                                            <StyledTableCell align="left">Action</StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {testomonial ? (
                                            testomonial.length > 0 ? (
                                                testomonial.map((row, i) => (
                                                    <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                        <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                            {startIndex + i + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" >{row.first_name + row.last_name}</StyledTableCell>
                                                        <StyledTableCell align="left" >{row.email}</StyledTableCell>
                                                        <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                                            <span style={{ cursor: "pointer" }} onClick={() => { setShowVideoDialoge(true); setVideoId(row.video) }}>
                                                                <VisibilityIcon color='primary' />
                                                            </span>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" >
                                                            <img src={row.image} width={100} height={50} alt='image' />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.is_approved === false ?
                                                                <Button variant='text' color='secondary' size='small' onClick={() => handleApproveDialoge(row.id)}>approve</Button> :
                                                                <span style={{ backgroundColor: "#DAE6D1", color: "#45A843", padding: "3px 10px", borderRadius: "4px", fontWeight: "600", fontSize: "12px" }}>{row.is_approved.toString()}</span>
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left"><Button variant='text' onClick={() => handleRejectDialoge(row.id)}>Reject</Button></StyledTableCell>
                                                        <StyledTableCell align="left"> {moment(row.created_at).format('MMM. D, YYYY, h:mm a')}</StyledTableCell>
                                                        <StyledTableCell >
                                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                <Box sx={{ color: "red", cursor: "pointer" }}>
                                                                    <DeleteOutlineOutlinedIcon onClick={() => handleDeleteTestamonialDiaoge(row.id)} />
                                                                </Box>
                                                            </Stack>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={8} align="center">
                                                        Data not found
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={8} align="center">
                                                    Loading...
                                                </TableCell>
                                            </TableRow>
                                        )}

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        )}


                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                            <Pagination
                                count={Math.ceil(testomonialData.count / rowsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>
                    </Item>
                </Grid>

            </Grid>

            <ThemeProvider theme={customTheme} >

                <BootstrapDialog
                    fullWidth
                    maxWidth="xs"
                    onClose={handleCloseApproveDialog}
                    open={approveDialog}

                >
                    <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
                        Approve
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography>Are you sure you want to Approve?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseApproveDialog}>
                            No
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                color: 'white',
                                textTransform: "capitalize"
                            }}
                            onClick={handleApproveTestomonial}
                            disabled={loadingApprove}
                        >
                            {loadingApprove ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Yes"}
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </ThemeProvider>




            <ThemeProvider theme={customTheme} >

                <BootstrapDialog
                    fullWidth
                    maxWidth="xs"
                    onClose={handleCloseDeleteDialog}
                    open={deleteDialog}

                >
                    <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
                        Delete
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography>Are you sure you want to delete?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
                            Close
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                color: 'white',
                                textTransform: "capitalize"
                            }}
                            onClick={handleDeleteTestamonial}
                            disabled={loadingDelete}
                        >
                            {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </ThemeProvider>



            <ThemeProvider theme={customTheme} >

                <BootstrapDialog
                    fullWidth
                    maxWidth="xs"
                    onClose={handleCloseVideoDialog}
                    open={showVideoDialoge}

                >
                    <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
                        Video
                    </DialogTitle>
                    <DialogContent dividers>
                        <video width="100%" height="300" controls>
                            <source src={videoId} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseVideoDialog}>
                            Close
                        </Button>
                        <Button startIcon={<CloudDownloadIcon />} variant='contained'
                            sx={{
                                backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                color: 'white',
                                fontSize: "12px"
                            }} onClick={handleDownloadVideo}>
                            Download
                        </Button>

                    </DialogActions>
                </BootstrapDialog>
            </ThemeProvider>


            <Dialog
                fullWidth
                maxWidth="xs"
                open={rejectdialoge}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={handleRejectTestomonial}>

                    <DialogTitle id="alert-dialog-title">
                        <Box
                            sx={{ cursor: "pointer" }}
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <Typography className="addpartner" sx={{ mb: 3 }}>
                                Message
                            </Typography>
                            <CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} />
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText id="alert-dialog-description">


                            <CustomizedTextField
                                sx={{ mb: 2 }}
                                fullWidth
                                variant="outlined"
                                name="text"
                                multiline
                                rows={4}
                                value={text}
                                required
                                onChange={handleNotificationChange}
                                size="small"
                                placeholder="Message"
                            />


                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                        <Button
                            type='submit'
                            variant="contained"
                            sx={{
                                backgroundImage:
                                    "linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)",
                                color: "white",
                            }}
                            fullWidth
                        >
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>


        </Box>
    )
}

export default Testomonial