import Axios from "axios";
import { backendUrl } from "../utils";

let getBalanceApi = "/dashboard/bankGrowthRate";
let adbankAccountApi = "/dashboard/bankView";
let getBankApi = "/dashboard/listBanks";
let getUserBankDetailApi = '/dashboard/userbank_detail/'
let adminEmailCodeApi = "/dashboard/admin_email_code/"
let verfiyAdminCodeApi = "/dashboard/verify_admin_code/"

const BankPageApi = {

    getBalance(params, token) {
        return Axios({
            url: backendUrl + getBalanceApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    addBankDetails(params, token) {
        return Axios({
            url: backendUrl + adbankAccountApi,
            method: 'post',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },

    sendAdminEmailCode(token) {
        return Axios({
            url: backendUrl + adminEmailCodeApi,
            method: 'post',
            // data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },


    verfyAdminCode(params, token) {
        return Axios({
            url: backendUrl + verfiyAdminCodeApi,
            method: 'post',
            data: params,
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }

        }).then(response => {
            return response.data
        })
    },



    getBankDetail(token) {
        return Axios({
            url: backendUrl + getBankApi,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },
    getUserBankDetail(params, token) {
        return Axios({
            url: backendUrl + getUserBankDetailApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },





    // deleteBanner(params, token) {
    //     return Axios({
    //         url: backendUrl + deleteBannerApi + params,
    //         method: 'delete',
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `token ${token}`,
    //         }
    //     }).then(response => {
    //         return response.data
    //     })
    // },


}

export default BankPageApi;
