import React from 'react'
import Sidebar from '../../../components/Sidbar'
import { Box } from '@mui/material'
import TotalExpenses from '../../../components/Accounts/totalexpenses'

const TotalExpensesPage = () => {
    return (
        <div>
            <Sidebar />
            <Box className="main-box">
                <TotalExpenses />
            </Box>
        </div>
    )
}

export default TotalExpensesPage