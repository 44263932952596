import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom';
import FaqsApi from '../apis/FaqsApi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './helppage.css'
import { toast } from 'react-toastify';

const GenralSettings = () => {
    const { general } = useParams();
    console.log(general)
    const [editorHtml, setEditorHtml] = useState('');
    const [loading, setLoading] = useState(false);
    const [createLoading, setLoadingCreate] = useState(false)

    const token = localStorage.getItem("token")
    const theme = useTheme();
    const isXsOrSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        getGeneralApi()
    }, [general])


    const getGeneralApi = () => {
        setLoading(true);
        let payload = `?text_type=${general}`
        FaqsApi.getGeneralSetting(payload)
            .then(response => {
                setEditorHtml(response.text);
                setLoading(false);
            })
            .catch(error => {
                console.log("Error:", error);
            })
    };
    const modules = {
        toolbar: false, // Hide toolbar
    };

    const handleSave = () => {
        setLoading(true)
        let payload = {
            text_type: general,
            text: editorHtml,
        };
        FaqsApi.PostGeneralSettings(payload, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                setLoading(false);

            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);

            });
    }

    return (
        <Box sx={{ padding: { lg: "10px", xs: "0px" }, paddingTop: "80px !important" }}>
            <Container >
                <Paper elevation={3} sx={{ padding: { lg: "40px", xs: "10px" } }}>
                    {/* <Typography sx={{ fontSize: "15px", fontWeight: "600", fontFamily: "Poppins" }}>{general} </Typography> */}
                    {loading ? (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <CircularProgress color='primary' />
                        </Box>
                    ) : (
                        <Box px={isXsOrSmScreen ? 0 : 8} py={isXsOrSmScreen ? 0 : 4}>
                            <ReactQuill
                                theme="snow"
                                value={editorHtml}
                                placeholder='Write Here...'
                                onChange={setEditorHtml}
                                className="no-border"
                            />
                        </Box>
                    )}
                    <Button variant='contained' color='error' sx={{ textTransform: "capitalize" }} onClick={handleSave}>Save</Button>
                </Paper>
            </Container>
        </Box >
    )
}

export default GenralSettings