import React from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import jsPDF from 'jspdf';
import 'apexcharts';
import { Badge, Grid, Stack, TextField, Typography } from '@mui/material';
import 'jspdf-autotable';

const SalesOverviewGraph = ({ AccountsData, date, setDate }) => {

    let catagories = AccountsData?.partner_graph_data?.arr_of_month
    var areaOptions = {
        series: [
            {
                name: 'Desktops',
                data: AccountsData?.partner_graph_data?.arr_of_count_per_month,
            },
            {
                name: 'Laptops',
                data: AccountsData?.partner_graph_data?.arr_of_count_per_month2,

            },
        ],
        // ... (rest of the options remain unchanged
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#EFCDFF', '#F75659'], // Set different colors for each series
            width: 3
        },
        grid: {

            show: false
        },
        xaxis: {
            categories: catagories,
        },
        tooltip: {
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = areaOptions.stroke.colors[seriesIndex]; // Get the color of the current series
                return (
                    '<div class="custom-tooltip" style="color: ' + color + '">' +
                    '<span class="number">' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                    '<div class="tooltip-line" style="background-color: ' + color + '"></div>' +
                    '<span class="units">' + series[seriesIndex][dataPointIndex] + ' K</span>' +
                    '</div>'
                );
            },
        }, legend: {
            show: false, // Set show to false to hide the legend
        },
        fill: {
            colors: ['#FF5733', '#3399FF'], // Set colors for the area under the line chart
        },

    };

    const HandleDateChange = (e) => {
        setDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(18);
        doc.text('Sales Overview', 10, 10);

        // Add today's amount
        doc.setFontSize(12);
        doc.text(`Today's Amount: $${AccountsData.partner_graph_data.today_amount}`, 10, 20);

        // Add this week's amount
        doc.text(`This Week's Amount: $${AccountsData.partner_graph_data.week_amount}`, 10, 30);

        // Add chart data
        const chartData = AccountsData.partner_graph_data.arr_of_count_per_month;
        const categories = AccountsData.partner_graph_data.arr_of_month;

        // Format chart data for PDF
        const formattedChartData = chartData.map((value, index) => ({
            x: categories[index],
            y: value
        }));

        // Add chart
        doc.text('Sales Overview Chart:', 10, 40);
        doc.autoTable({
            startY: 50,
            head: [['Month', 'Sales']],
            body: formattedChartData.map(({ x, y }) => [x, y])
        });

        // Save the PDF
        doc.save('account_data.pdf');
    }


    return (
        <div>
            <div className="graph-div">
                <Grid container className="graphinner-div">
                    {/* <div className="dstextdiv">
                        <h3 className="growthrate" style={{ margin: "0px" }}>Sales overview</h3>
                    </div> */}
                    <Grid item lg={6} xs={12} md={12}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Typography className='parttnershead'>Sales overview</Typography>
                            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                <Badge badgeContent="" variant="dot" color='error' />
                                <span style={{ color: "#949494", fontSize: "12px" }}>$ {AccountsData?.partner_graph_data?.today_amount}(Today)</span>
                            </Stack>

                            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                <Badge badgeContent="" variant="dot" color="success" sx={{ '& .MuiBadge-badge': { backgroundColor: '#F1D4FF' } }} />
                                <span style={{ color: "#949494", fontSize: "12px" }}>$ {AccountsData?.partner_graph_data?.week_amount}(This week)</span>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <div onClick={generatePDF} style={{ cursor: "pointer",marginRight:"10px" }}>
                                <img src={pdficon} alt="img" />
                            </div>
                            <TextField type="date" name='date' placeholder="Today" onChange={HandleDateChange} value={date} sx={{
                                width: "140px",
                                border: "none",
                                outline: "none",
                                "& .MuiInputBase-input": {
                                    padding: "5px 5px",
                                },

                            }} />

                        </div>
                    </Grid>
                </Grid>
                <div id="linechart">
                    <ReactApexChart options={areaOptions} series={areaOptions.series} type="line" height={390} />
                </div>
            </div>
        </div >
    )
}

export default SalesOverviewGraph