import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import SelectUser from 'react-select';
import { MenuItem, Select } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  styled,
  Button,
  CircularProgress,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import logoimg from "../Assests/images/logo.png";
import { useNavigate } from "react-router-dom";
import bagwhite from "../Assests/icons/bagwhite.png";
import couponimg from "../Assests/icons/coupenicon.svg";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import gifticon from "../Assests/icons/gifticon.svg";
import paymneticon from "../Assests/icons/dollaricon-.svg";
import adsicon from "../Assests/icons/adsicon.svg";
import settingicon from "../Assests/icons/settingicon.svg";
import bagicon from "../Assests/icons/bagicon.svg";
import bagfilledicon from "../Assests/icons/bagfilled.png";
import profileicon from "../Assests/icons/profileicon.svg";
import profilefilledicon from "../Assests/icons/userfilledicon.svg";
import couponfilled from "../Assests/icons/couponfilled.svg";
import adsfilled from "../Assests/icons/adsfilled.svg";
import dollarfilled from "../Assests/icons/dollarfilled.svg";
import giftfilled from "../Assests/icons/giftfilled.svg";
import partnericon from "../Assests/icons/partner.svg";
import helpicon from "../Assests/icons/helpicon.svg";
import signouticon from "../Assests/icons/SignOut.svg";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import QuizIcon from "@mui/icons-material/Quiz";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import TryIcon from "@mui/icons-material/Try";
import Topbar from "./Topbar";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import ExpensesApi from "../apis/ExpensesApi";
import UsersApi from "../apis/Users";
import { toast } from "react-toastify";
import { CustomizedTextField } from "../style/commonStyle";

const tabData = [
  "Payment process",
  "Redeem amount",
  "Payment to grocery store",
  "Notification",
  "Faq",
  "Privacy policy",
  "Terms and conditions",
  "About us",
  "Afffiliate",
  "Promote business",
  "Banner add",
  "Non affliated store",
  "Earn and refrence",
  "help&support",
];

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const drawerWidth = 230;

function Sidebar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedLinks, setSelectedLinks] = useState(null);
  // const [isCollapse, setIsCollapse] = useState(true);
  const [isCollapse, setIsCollapse] = useState(true);
  const [isGeneralCollapes, setIsGeneralCollaps] = useState(false);
  const [notificationdialoge, setNotificationDialoge] = useState(false);
  // const [isAccountCollapse, setAccountCollapse] = useState(true);
  const [isAccountCollapse, setAccountCollapse] = useState(true);
  const [loading, setLoading] = useState(false);
  const isActiveLink = (pathname) => location.pathname === pathname;
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedCatagory, setSelectedCatogry] = useState("")
  const [text, setText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChangeSelected = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setCurrentPage(5)
  };


  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const sortedOptions = [...selectedOptions];
    const [removed] = sortedOptions.splice(oldIndex, 1);
    sortedOptions.splice(newIndex, 0, removed);
    setSelectedOptions(sortedOptions);
    setCurrentPage(newIndex)
  };

  const debounce = (func, delay) => {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };


  const handleInputChange = debounce((inputValue) => {
    if (inputValue) {
      setLoading(true);
      let payload = `?limit=${rowsPerPage}&email=${inputValue}`;
      UsersApi.getUsersList(payload, token)
        .then(response => {
          const usersOptions = response.results.map(user => ({
            value: user.id,
            label: user.email
          }));
          setUsers(usersOptions);
          setLoading(false);
        })
        .catch(error => {
          console.log("Error:", error);
        });
    } else {
      getUsersList(); // If no input value, fetch all users
    }
  }, 500);

  const getUsersList = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    UsersApi.getUsersList(payload, token)
      .then(response => {
        const usersOptions = response.results.map(user => ({
          value: user.id,
          label: user.email
        }));
        setUsers(usersOptions); //
        setLoading(false);
      })
      .catch(error => {
        console.log("Error:", error);
      })
  };


  const handleNotificationChange = (e) => {
    setText(e.target.value);
  };

  const handleNotificationDialogeOpen = () => {
    getUsersList()
    setNotificationDialoge(true);
  };

  const handleClose = () => {
    setNotificationDialoge(false);
  };
  
  // const handleCollapse = () => {
  //   setIsCollapse(!isCollapse);
  //   setAccountCollapse(false);
  // };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse); 
  };

  const handleGenaralCollapse = () => {
    setIsCollapse(false);
    setAccountCollapse(false);
    setIsGeneralCollaps(!isGeneralCollapes);
  };

  const handleAccountCollapse = () => {
    setAccountCollapse(!isAccountCollapse);
    setIsCollapse(false);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  useEffect(() => {
    getUsersList();
  }, [currentPage, rowsPerPage]);

  // const getSingleVideo = () => {
  //   setLoading(true);
  //   UsersApi.getSingleVideo(token)
  //     .then(response => {
  //       console.log(response)
  //       setVideoUrl(response.video);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       console.log("Error:", error);
  //     })
  // };


  const handleCatagoryChange = (event) => {
    setSelectedCatogry(event.target.value);

  };



  const handleStackClick = (stackName) => {
    setSelectedLinks(stackName);
  };

  Topbar.propTypes = {
    setMobileOpen: PropTypes.func.isRequired,
    mobileOpen: PropTypes.bool.isRequired,
    isClosing: PropTypes.bool.isRequired,
  };

  let token = localStorage.getItem("token");
  let admin = localStorage.getItem("is_admin")
  console.log("admin___________", admin)
  let role = localStorage.getItem("role")


  // const handleLogOut = () => {
  //     localStorage.removeItem('token');
  //     navigate('/')
  //     const token = localStorage.getItem('token');
  //     let payload = {
  //         "token": localStorage.getItem("token")
  //     }

  //     AuthApi.signout(payload)
  //         .then((response) => {
  //             toast.success("Log Out Successfully", { autoClose: 1400 });
  //             setLoading(false);
  //             navigate('/');
  //         })
  //         .catch((e) => {
  //             console.log("e*************", e)
  //             if (e.response && e.response) {
  //                 toast.error(e.response.data.detail, { autoClose: 1400 });
  //             }
  //             setLoading(false);
  //         });
  // };


  const handleSendNotification = () => {
    setLoading(true);
    let payload = {
      text: text,
      users: selectedOptions,
      category: selectedCatagory
    };

    UsersApi.sendNotification(payload, token)
      .then((response) => {
        toast.success("Sent Successfully", { autoClose: 1400 });
        setLoading(false);
        setText("")
        setNotificationDialoge(false)
        setSelectedOptions([])
        selectedCatagory(null)
      })
      .catch((error) => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };

  const handleLogOut = () => {
    setLoading(true);

    setTimeout(() => {
      localStorage.removeItem("token");

      navigate("/");

      setLoading(false);
    }, 1000);
  };

  const handleClickLink = (text) => {
    navigate(`/generalsetting/${text}`);
  };

  const tabData = [
    "Payment process",
    "Redeem amount",
    "Payment to grocery store",
    "Notification",
    "Faq",
    "Privacy policy",
    "Terms and conditions",
    "About us",
    "Afffiliate",
    "Promote business",
    "Banner add",
    "Non affliated store",
    "Earn and refrence",
    "Reward",
    "User Mannual",
    "Help & Support",
  ];

  const drawer = (
    <div>
      <Box
        sx={{
          height: "80px",
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logoimg} alt="logoimg" height={44} width={57} />
      </Box>
      <Box
        className="main"
        sx={{
          display: {
            sm: "block",
            background: "linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)",
            height: "100vh",
            maxHeight: "80vh",
            overflow: "auto",
            overflowX: "hidden",
          },
        }}
      >
        {admin.toString() === "true" ?
          <>
            <Box sx={{ pt: 4 }}>


              <List>
                <ListItem
                  disablePadding
                  component={Link}
                  to="/accounts"
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/accounts") ? "10px" : "0px",
                    margin: isActiveLink("/accounts") ? "0px 10px 0px 10px" : "0px",
                    backgroundColor: isActiveLink("/accounts")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton
                    onClick={handleAccountCollapse}
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: "center",
                      }}
                    >
                      {isActiveLink("/accounts") ? (
                        <img src={bagfilledicon} alt="bag" />
                      ) : (
                        <img src={bagwhite} alt="bag" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Accounts"
                      sx={{
                        color: isActiveLink("/accounts") ? "#F06368" : "#FFFFFF",
                      }}
                    />
                    {isAccountCollapse ? (
                      <ExpandLessIcon
                        sx={{
                          color: isActiveLink("/accounts") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{
                          color: isActiveLink("/accounts") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              </List>

              <List>
                <Collapse in={isAccountCollapse} timeout="auto" unmountOnExit>
                  <ListItem
                    disablePadding
                    component={Link}
                    to="/gifts"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/gifts") ? "10px" : "0px",
                      margin: isActiveLink("/gifts") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/gifts")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/gifts") ? (
                          <img src={giftfilled} alt="profileicon" />
                        ) : (
                          <img src={gifticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Gift Wallet"
                        sx={{
                          color: isActiveLink("/gifts") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/expenses"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/expenses") ? "10px" : "0px",
                      margin: isActiveLink("/expenses")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/expenses")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/expenses") ? (
                          <img src={dollarfilled} alt="adsfilled" />
                        ) : (
                          <img src={paymneticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Expenses"
                        sx={{
                          color: isActiveLink("/expenses") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/totalexpenses"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/totalexpenses") ? "10px" : "0px",
                      margin: isActiveLink("/totalexpenses")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/totalexpenses")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/totalexpenses") ? (
                          <img src={dollarfilled} alt="adsfilled" />
                        ) : (
                          <img src={paymneticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Customer Expenses"
                        sx={{
                          color: isActiveLink("/totalexpenses")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/payments"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/payments") ? "10px" : "0px",
                      margin: isActiveLink("/payments")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/payments")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/payments") ? (
                          <img src={dollarfilled} alt="profileicon" />
                        ) : (
                          <img src={paymneticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Payments"
                        sx={{
                          color: isActiveLink("/payments") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/paymentsreceipt"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/paymentsreceipt") ? "10px" : "0px",
                      margin: isActiveLink("/paymentsreceipt")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/paymentsreceipt")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/paymentsreceipt") ? (
                          <img src={dollarfilled} alt="profileicon" />
                        ) : (
                          <img src={paymneticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Payment Receipt"
                        sx={{
                          color: isActiveLink("/paymentsreceipt") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/affiliates"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/affiliates") ? "10px" : "0px",
                      margin: isActiveLink("/affiliates")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/affiliates")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/affiliates") ? (
                          <img src={adsfilled} alt="adsfilled" />
                        ) : (
                          <img src={adsicon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Affiliates"
                        sx={{
                          color: isActiveLink("/affiliates")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/mybank"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/mybank") ? "10px" : "0px",
                      margin: isActiveLink("/mybank") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/mybank")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/mybank") ? (
                          <img src={dollarfilled} alt="adsfilled" />
                        ) : (
                          <img src={paymneticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="My Bank"
                        sx={{
                          color: isActiveLink("/mybank") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/userbank"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/userbank") ? "10px" : "0px",
                      margin: isActiveLink("/userbank")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/userbank")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/userbank") ? (
                          <img src={dollarfilled} alt="adsfilled" />
                        ) : (
                          <img src={paymneticon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="User Bank"
                        sx={{
                          color: isActiveLink("/userbank") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/partners"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/partners") ? "10px" : "0px",
                      margin: isActiveLink("/partners")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/partners")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/partners") ? (
                          <img src={adsfilled} alt="adsfilled" />
                        ) : (
                          <img src={partnericon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Partners"
                        sx={{
                          color: isActiveLink("/partners") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    component={Link}
                    to="/ads"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/ads") ? "10px" : "0px",
                      margin: isActiveLink("/ads") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/ads")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/ads") ? (
                          <img src={adsfilled} alt="adsfilled" />
                        ) : (
                          <img src={adsicon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Ads"
                        sx={{ color: isActiveLink("/ads") ? "#F06368" : "#FFFFFF" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={handleNotificationDialogeOpen}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <CircleNotificationsIcon sx={{ color: "#FFFFFF" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Send Notification"
                        sx={{ color: "#FFFFFF" }}
                      />
                    </ListItemButton>
                  </ListItem>

                  
                  <ListItem
                    disablePadding
                    component={Link}
                    to="/uploadvideo"
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/uploadvideo") ? "10px" : "0px",
                      margin: isActiveLink("/uploadvideo") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/uploadvideo")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/uploadvideo") ? (
                        <CloudUploadIcon sx={{ color: "#F06368" }} />
                        ) : (
                          <CloudUploadIcon sx={{ color: "#FFFFFF" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Upload Video"
                        sx={{ color: isActiveLink("/uploadvideo") ? "#F06368" : "#FFFFFF" }}
                      />
                    </ListItemButton>
                  </ListItem>


                  {/* <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={handleUploadDialogeOpen}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <CloudUploadIcon sx={{ color: "#FFFFFF" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Upload Video"
                        sx={{ color: "#FFFFFF" }}
                      />
                    </ListItemButton>
                  </ListItem> */}

                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => handleStackClick("settings")}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <img src={settingicon} alt="profileicon" />
                      </ListItemIcon>
                      <ListItemText primary="Settings" sx={{ color: "#FFFFFF" }} />
                    </ListItemButton>
                  </ListItem>
                </Collapse>
              </List>

              <List
                style={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  position: "relative",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItem
                  disablePadding
                  component={Link}
                  to="/information"
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/information") ? "10px" : "0px",
                    margin: isActiveLink("/information")
                      ? "0px 10px 0px 10px"
                      : "0px",
                    backgroundColor: isActiveLink("/information")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton
                    onClick={handleCollapse}
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: "center",
                      }}
                    >
                      {isActiveLink("/information") ? (
                        <img src={bagfilledicon} alt="bag" />
                      ) : (
                        <img src={bagicon} alt="bag" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Information"
                      sx={{
                        color: isActiveLink("/information") ? "#F06368" : "#FFFFFF",
                      }}
                    />
                    {isCollapse ? (
                      <ExpandLessIcon
                        sx={{
                          color: isActiveLink("/information")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{
                          color: isActiveLink("/information")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              </List>

              <List>
                <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                  <ListItem
                    component={Link}
                    to="/users"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/users") ? "10px" : "0px",
                      margin: isActiveLink("/users") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/users")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/users") ? (
                          <img src={profilefilledicon} alt="profileicon" />
                        ) : (
                          <img src={profileicon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="User"
                        sx={{
                          color: isActiveLink("/users") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/provinces"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/provinces") ? "10px" : "0px",
                      margin: isActiveLink("/provinces")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/provinces")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {/* <img src={provinceimg} alt='profileicon' /> */}
                        <LocationCityIcon
                          sx={{
                            color: isActiveLink("/provinces")
                              ? "#F06368"
                              : "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Provinces"
                        sx={{
                          color: isActiveLink("/provinces") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/faqs"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/faqs") ? "10px" : "0px",
                      margin: isActiveLink("/faqs") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/faqs")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <QuizIcon
                          sx={{
                            color: isActiveLink("/faqs") ? "#F06368" : "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="FAQ"
                        sx={{
                          color: isActiveLink("/faqs") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/banner"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/banner") ? "10px" : "0px",
                      margin: isActiveLink("/banner") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/banner")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <CollectionsBookmarkIcon
                          sx={{
                            color: isActiveLink("/banner") ? "#F06368" : "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Banner"
                        sx={{
                          color: isActiveLink("/banner") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/coupons"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/coupons") ? "10px" : "0px",
                      margin: isActiveLink("/coupons")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/coupons")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/coupons") ? (
                          <img src={couponfilled} alt="profileicon" />
                        ) : (
                          <img src={couponimg} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Coupons"
                        sx={{
                          color: isActiveLink("/coupons") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/testomonial"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/testomonial") ? "10px" : "0px",
                      margin: isActiveLink("/testomonial")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/testomonial")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <TryIcon
                          sx={{
                            color: isActiveLink("/testomonial")
                              ? "#F06368"
                              : "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Testimonials"
                        sx={{
                          color: isActiveLink("/testomonial")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/walltes"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/walltes") ? "10px" : "0px",
                      margin: isActiveLink("/walltes")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/walltes")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <WalletOutlinedIcon
                          sx={{
                            color: isActiveLink("/walltes") ? "#F06368" : "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Wallets"
                        sx={{
                          color: isActiveLink("/walltes") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    component={Link}
                    to="/rewards"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/rewards") ? "10px" : "0px",
                      margin: isActiveLink("/rewards")
                        ? "0px 10px 0px 10px"
                        : "0px",
                      backgroundColor: isActiveLink("/rewards")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        <StarsOutlinedIcon
                          sx={{
                            color: isActiveLink("/rewards") ? "#F06368" : "#FFFFFF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Rewards"
                        sx={{
                          color: isActiveLink("/rewards") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Collapse>
              </List>
            </Box>
            <Box sx={{ mt: 1 }}>
              <List
                style={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  position: "relative",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItem
                  disablePadding
                  // component={Link}
                  // to="/information"
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/generalsetting") ? "10px" : "0px",
                    margin: isActiveLink("/generalsetting")
                      ? "0px 10px 0px 10px"
                      : "0px",
                    backgroundColor: isActiveLink("/generalsetting")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton
                    onClick={handleGenaralCollapse}
                    sx={{
                      minHeight: 48,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: "center",
                      }}
                    >
                      {isActiveLink("/generalsetting") ? (
                        <img src={bagfilledicon} alt="bag" />
                      ) : (
                        <img src={bagicon} alt="bag" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Genral Setting"
                      sx={{
                        color: isActiveLink("/generalsetting")
                          ? "#F06368"
                          : "#FFFFFF",
                      }}
                    />
                    {isGeneralCollapes ? (
                      <ExpandLessIcon
                        sx={{
                          color: isActiveLink("/generalsetting")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{
                          color: isActiveLink("/generalsetting")
                            ? "#F06368"
                            : "#FFFFFF",
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              </List>

              <List>
                <Collapse in={isGeneralCollapes}>
                  {tabData.map((tab, index) => (
                    <ListItem
                      key={index}
                      // component={Link}
                      // to={`/${tab}`}
                      disablePadding
                      sx={{
                        display: "block",
                        borderRadius: isActiveLink(`/${tab}`) ? "10px" : "0px",
                        // margin: isActiveLink(`/${tab.toLowerCase().replace(/\s+/g, '-')}`) ? '0px 10px 0px 10px' : '0px',
                        backgroundColor: isActiveLink(`/${tab}`)
                          ? "white"
                          : "transparent",
                      }}
                    >
                      <ListItemButton
                        sx={{ minHeight: 48, px: 2.5 }}
                        onClick={() => handleClickLink(tab)}
                      >
                        <ListItemText
                          primary={tab}
                          sx={{
                            color: isActiveLink(`/${tab}`) ? "#F06368" : "#FFFFFF",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Collapse>
              </List>

              <List>
                <ListItem
                  onClick={handleLogOut}
                  disablePadding
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/logout") ? "10px" : "0px",
                    margin: isActiveLink("/logout") ? "0px 10px 0px 10px" : "0px",
                    backgroundColor: isActiveLink("/logout")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                    <ListItemIcon
                      sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                    >
                      {isActiveLink("/logout") ? (
                        <img src={signouticon} alt="helpicon" />
                      ) : (
                        <img src={signouticon} alt="helpicon" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Log Out"
                      sx={{
                        color: isActiveLink("/logout") ? "#F06368" : "#FFFFFF",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </>
          : role === "Staff" ?
            <div style={{ marginTop: "20px" }}>
              <List
                style={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  position: "relative",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItem
                  disablePadding
                  component={Link}
                  to="/information"
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/information") ? "10px" : "0px",
                    margin: isActiveLink("/information")
                      ? "0px 10px 0px 10px"
                      : "0px",
                    backgroundColor: isActiveLink("/information")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton
                    onClick={handleCollapse}
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: "center",
                      }}
                    >
                      {isActiveLink("/information") ? (
                        <img src={bagfilledicon} alt="bag" />
                      ) : (
                        <img src={bagicon} alt="bag" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Information"
                      sx={{
                        color: isActiveLink("/information") ? "#F06368" : "#FFFFFF",
                      }}
                    />

                  </ListItemButton>
                </ListItem>
              </List>
              <List>
                <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                  <ListItem
                    component={Link}
                    to="/users"
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/users") ? "10px" : "0px",
                      margin: isActiveLink("/users") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/users")
                        ? "white"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/users") ? (
                          <img src={profilefilledicon} alt="profileicon" />
                        ) : (
                          <img src={profileicon} alt="profileicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="User"
                        sx={{
                          color: isActiveLink("/users") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                </Collapse>
              </List>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={handleNotificationDialogeOpen}
              >
                <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                  <ListItemIcon
                    sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                  >
                    <CircleNotificationsIcon sx={{ color: "#FFFFFF" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Send Notification"
                    sx={{ color: "#FFFFFF" }}
                  />
                </ListItemButton>
              </ListItem>
              <List>
                <ListItem
                  onClick={handleLogOut}
                  disablePadding
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/logout") ? "10px" : "0px",
                    margin: isActiveLink("/logout") ? "0px 10px 0px 10px" : "0px",
                    backgroundColor: isActiveLink("/logout")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                    <ListItemIcon
                      sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                    >
                      {isActiveLink("/logout") ? (
                        <img src={signouticon} alt="helpicon" />
                      ) : (
                        <img src={signouticon} alt="helpicon" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Log Out"
                      sx={{
                        color: isActiveLink("/logout") ? "#F06368" : "#FFFFFF",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>

            </div> : role === "HR" ?
              <div style={{ marginTop: "20px" }}>
                <ListItem
                  disablePadding
                  component={Link}
                  to="/expenses"
                  sx={{
                    display: "block",
                    borderRadius: isActiveLink("/expenses") ? "10px" : "0px",
                    margin: isActiveLink("/expenses")
                      ? "0px 10px 0px 10px"
                      : "0px",
                    backgroundColor: isActiveLink("/expenses")
                      ? "#FFFFFF"
                      : "transparent",
                  }}
                >
                  <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                    <ListItemIcon
                      sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                    >
                      {isActiveLink("/expenses") ? (
                        <img src={dollarfilled} alt="adsfilled" />
                      ) : (
                        <img src={paymneticon} alt="profileicon" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Expenses"
                      sx={{
                        color: isActiveLink("/expenses") ? "#F06368" : "#FFFFFF",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <List>
                  <ListItem
                    onClick={handleLogOut}
                    disablePadding
                    sx={{
                      display: "block",
                      borderRadius: isActiveLink("/logout") ? "10px" : "0px",
                      margin: isActiveLink("/logout") ? "0px 10px 0px 10px" : "0px",
                      backgroundColor: isActiveLink("/logout")
                        ? "#FFFFFF"
                        : "transparent",
                    }}
                  >
                    <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                      <ListItemIcon
                        sx={{ mr: 1, minWidth: 0, justifyContent: "center" }}
                      >
                        {isActiveLink("/logout") ? (
                          <img src={signouticon} alt="helpicon" />
                        ) : (
                          <img src={signouticon} alt="helpicon" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary="Log Out"
                        sx={{
                          color: isActiveLink("/logout") ? "#F06368" : "#FFFFFF",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </div> : null
        }
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <CssBaseline />
      <Topbar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        isClosing={isClosing}
      />
      <Box
        component="nav"
        sx={{
          backgroundColor: "red",
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            backgroundColor: "red",
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              mt: 6,
              ml: 3,
              borderRadius: "32px",
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={notificationdialoge}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{ cursor: "pointer" }}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography className="addpartner" sx={{ mb: 3 }}>
              Notification
            </Typography>
            <CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: "400px" }}>
          <DialogContentText id="alert-dialog-description">


            <CustomizedTextField
              sx={{ mb: 2 }}
              fullWidth
              variant="outlined"
              name="text"
              multiline
              rows={4}
              value={text}
              onChange={handleNotificationChange}
              size="small"
              placeholder="Notification"
            />

            <Select
              sx={{ mb: 2 }}
              size='small'
              value={selectedCatagory}
              onChange={handleCatagoryChange}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">Select Category</MenuItem>
              <MenuItem value="scratch">Not Scratch</MenuItem>
              <MenuItem value="notclamed">Not Claimed</MenuItem>
              <MenuItem value="pending">How to use gift ?</MenuItem>
              <MenuItem value="playstore">Play Store</MenuItem>

            </Select>
            {selectedCatagory ?
              null :
              <SelectUser
                defaultValue={selectedOption}
                onChange={handleChangeSelected}
                options={users}
                isMulti
                onSortEnd={handleSortEnd}
                useDragHandle
                isSearchable={true}
                onInputChange={handleInputChange}
              />
            }




          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "20px", mb: "15px" }}>
          <Button
            variant="contained"
            onClick={handleSendNotification}
            sx={{
              backgroundImage:
                "linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)",
              color: "white",
            }}
            fullWidth
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
