import React from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { Badge, Grid, Stack, TextField, Typography } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const AdRevinue = ({ revenueData, date, setDate }) => {

    const weekDays = revenueData?.ads_graph?.ads_day
    var areaOptions = {
        series: [{
            name: "Desktops",
            data: revenueData?.ads_graph?.ads_count

        }],
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 3,
                opacityFrom: 1,
                opacityTo: 2,
                stops: [0, 60],
                colorStops: [
                    {
                        offset: 0,
                        color: '#DDE0FB'
                    },
                    {
                        offset: 80,
                        color: '#FFFFFF'
                    }
                ]
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#ff0000'],
            width: 2

        },
        grid: {
            row: {
                opacity: 0.5
            },
            show: false
        },
        xaxis: {
            categories: weekDays,
        },
        tooltip: {
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    '<div class="custom-tooltip">' +
                    '<span class="number">' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                    '<div class="tooltip-line"></div>' +
                    '<span class="units">' + series[seriesIndex][dataPointIndex] + '   </span>' +
                    '</div>'
                );
            }
        }

    };


    const handleDateChange = (e) => {
        setDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Ads Revenue', 10, 10);
        const chartData = revenueData?.ads_graph?.ads_count;
        const categories = revenueData?.ads_graph?.ads_day;

        const formattedChartData = chartData.map((value, index) => ({
            x: categories[index],
            y: value
        }));

        doc.autoTable({
            startY: 30,
            head: [['Days', 'Reveue']],
            body: formattedChartData.map(({ x, y }) => [x, y, ''])
        });
        doc.save('ads_revenue.pdf');
    };

    return (
        <div>
            <div className="graph-div">
                <Grid container className="graphinner-div">
                    <Grid item xs={12} md={6} sm={12} lg={6}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Typography className='parttnershead'>Ads Revenue</Typography>
                            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                <Badge badgeContent="" variant="dot" color='error' />
                                <span style={{ color: "#949494", fontSize: "12px" }}>$ 12K(Today)</span>
                            </Stack>

                            <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                                <Badge badgeContent="" variant="dot" color="success" sx={{ '& .MuiBadge-badge': { backgroundColor: '#F1D4FF' } }} />
                                <span style={{ color: "#949494", fontSize: "12px" }}>$ 83K(This week)</span>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6}>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <div onClick={generatePDF} style={{ cursor: "pointer", marginRight: "10px" }}>
                                <img src={pdficon} alt="img" />
                            </div>

                            <TextField type="date" name='date' value={date} onChange={handleDateChange} sx={{
                                width: "140px",
                                border: "none",
                                outline: "none",
                                "& .MuiInputBase-input": {
                                    padding: "5px 5px",
                                }
                            }} />

                        </div>
                    </Grid>
                </Grid>
                <div id="linechart">
                    <ReactApexChart options={areaOptions} series={areaOptions.series} type="area" height={350} />
                </div>
            </div>
        </div>
    )
}

export default AdRevinue