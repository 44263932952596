import React, { useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { Badge, Box,  Grid,  Stack, TextField, Typography,  } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const GiftDistributionChart = ({ giftDistributionData, giftGraphDate, setGiftGraphDate }) => {

    const handleChangeDate = (event) => {
        setGiftGraphDate(event.target.value);
    }

    const distributedPrizes = giftDistributionData?.distributed_prizes || 0;
    const pendingPayments = giftDistributionData?.pending_payments || 0;

    const barColor1 = '#9ACA9B'; 
    const barColor2 = '#EA4D50'; 

    var options = {
        series: [{
            data: [distributedPrizes, pendingPayments]
        }],
        chart: {
            height: 350,
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                }
            },
            toolbar: {
                show: false, 
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '80%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                ['Distributed prizes'],
                ['Pending payments'],
            ],
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        colors: [barColor1, barColor2],
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(18);
        doc.text('Gift Distribution Report', 10, 10);

        doc.text(`Distributed Prizes: ${distributedPrizes}`, 10, 130);
        doc.text(`Pending Payments: ${pendingPayments}`, 10, 140);

        doc.save('gift_distribution_report.pdf');
    }


    return (
        <div>
            <div class="graph-div">
                {/* <div class="graphinner-div"> */}
                <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between", width: "100%" }}>
                    {/* <div class="dstextdiv"> */}
                    <Grid container direction={"row"} spacing={1} alignItems={"flex-start"}>
                        <Grid item lg={6} md={6} xs={6} sm={6}>
                            <Stack direction={"column"} spacing={1}>
                                <Typography className='parttnershead'>Gift distribution</Typography>
                                <Typography sx={{ fontSize: "30px", fontWeight: "600", fontFamily: "Poppins", color: "#000000" }}> ${distributedPrizes + pendingPayments}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item lg={6} md={6} xs={6} sm={6} display={"flex"} justifyContent={"flex-end"}>

                            <div class="right-div">
                                <div onClick={generatePDF}>
                                    <img src={pdficon} alt="img" />
                                </div>
                                <TextField type="date" name="giftGraphDate" value={giftGraphDate} onChange={handleChangeDate} sx={{
                                    width: "140px",
                                    border: "none",
                                    outline: "none",
                                    "& .MuiInputBase-input": {
                                        padding: "5px 5px",
                                    }
                                }} />
                            </div>

                        </Grid>
                    </Grid>

                    {/* </div> */}

                </Box>
                {/* </div> */}
                <div id="stackedchart">
                    <ReactApexChart options={options} series={options.series} type="bar" height={300} />
                </div>

                <Grid container direction={"row"} alignItems={"center"}>
                    <Grid item xs={6}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                            <Badge badgeContent="" variant="dot" color='success' />
                            <span style={{ color: "#686868", fontSize: "14px" }}>Distributed prizes</span>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                        <Typography className='parttnershead'>${distributedPrizes}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction={"row"} alignItems={"center"} spacing={1}>
                    <Grid item xs={6}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"} fontFamily={"Poppins"}>
                            <Badge badgeContent="" variant="dot" color='error' />
                            <span style={{ color: "#686868", fontSize: "14px" }}>Pending payments </span>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                        <Typography className='parttnershead'>${pendingPayments}</Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default GiftDistributionChart