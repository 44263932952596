import Axios from "axios";
import { backendUrl } from "../utils";
let getInfromationApi = "/dashboard/information";
let getUserGraphApi = '/dashboard/userGraph'
let getGrowthRateApi = "/dashboard/coupansGrowthrate"


const InformationApi = {

    getInformation(params, token) {
        return Axios({
            url: backendUrl + getInfromationApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getGrowthRate(params, token) {
        return Axios({
            url: backendUrl + getGrowthRateApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getUserGraph(params, token) {
        return Axios({
            url: backendUrl + getUserGraphApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },
}

export default InformationApi;
