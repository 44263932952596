import { Box, CircularProgress, Grid, Paper, Typography, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, TextField, InputAdornment, OutlinedInput, IconButton, FormControl, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import ExpensesApi from '../../../apis/ExpensesApi';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import editicon from '../../../Assests/icons/editicon.svg'
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ReactFileReader from 'react-file-reader';
import { toast } from 'react-toastify';
import moment from 'moment';
import './expenses.css'
import { BootstrapDialog, CustomizedTextField, Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';
import jsPDF from 'jspdf';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UsersApi from '../../../apis/Users';
import UploadVideoApi from '../../../apis/uploadVideoApi';



const UploadVideo = () => {
    let token = localStorage.getItem("token")
    const [expensesList, setExpensesList] = useState([])
    const [expensesListData, setExpensesListData] = useState({})
    const [loading, setLoading] = useState(false)
    const [deleteDialog, setDeleteDialoge] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [updateExpenses, setUpdateExpenses] = useState({});
    const [selectedId, setSelectedId] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [imageDialoge, setImageDialoge] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadVideodialoge, setUploadDialoge] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [description, setDescription] = useState("")
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [allVideos, setAllVideo] = useState();
    const [updateVideo, setUpdateVideo] = useState({})
    const [updateDialog, setUpdateDialog] = useState(false)
    console.log("allvideos???????????????", updateVideo)

    const rowsPerPage = 30;
    const perpage = 1000;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        setSelectedVideo(file);
        setVideoUrl(URL.createObjectURL(file));
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value)
    }

    // const handleCategoryChange = (event) => {
    //     const selectedValue = event.target.value;
    //     setSelectedCategory(selectedValue);
    //     if (selectedValue === 'advertisement') {
    //         setShowSocialMediaDropdown(true);
    //     } else {
    //         setShowSocialMediaDropdown(false);
    //     }
    // };

    // const handleSocialMediaChange = (event) => {
    //     setSelectedSocialMedia(event.target.value);
    // };



    const handleClickImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setImageDialoge(true);
    };

    const handleCloseImage = () => {
        setImageDialoge(false);
    };



    const startIndex = (currentPage - 1) * rowsPerPage;



    const handleDeleteDialoge = (id) => {
        setDeleteDialoge(true)
        setSelectedId(id)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialoge(false)
    }



    const handleEditVideoDialog = (id) => {
        setSelectedId(id)
        setLoadingUpdate(true)
        setUpdateDialog(true)
        let rowid = `?id=${id}`
        UploadVideoApi.getSingleVideo(rowid, token)
            .then(response => {
                setUpdateVideo(response)
                setLoadingUpdate(false)

            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    }



    const handleUploadDialogeOpen = () => {
        setUploadDialoge(true);
        // getSingleVideo();
        setDescription("")
    };

    const handleVideoDialogeClose = () => {
        setUploadDialoge(false)
    }

    const handleCloseUpdateDialog = () => {
        setUpdateDialog(false)
    }

    const handleUpdateVideo = () => {
        setLoadingUpdate(true);
        const formData = new FormData();
        formData.append("description", updateVideo.description)
        formData.append('video', selectedVideo);

        let rowid = `?id=${selectedId}`;
        UploadVideoApi.UpdateVideo(rowid, formData, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                getAllVideos()
                setLoadingUpdate(false);
                setUpdateDialog(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoadingUpdate(false);
            });
    }



    const handleVideoUpload = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("description", description)
        formData.append('video', selectedVideo);
        UploadVideoApi.UploadVideo(formData, token)
            .then((response) => {
                toast.success("Successfully Upload", { autoClose: 1400 });
                setLoading(false);
            })
            .catch((error) => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });

    };
    useEffect(() => {
        getAllVideos()
    }, [])



    const getAllVideos = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}&from_admin=yes`;
        UploadVideoApi.getAllVideo(payload, token)
            .then(response => {
                setAllVideo(response);
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };


    const handleDeleteVideo = () => {
        setLoadingDelete(true);
        let payload = {
            id: selectedId
        }
        UploadVideoApi.deleteVideo(payload, token)
            .then(response => {
                toast.success(response.detail, { autoClose: 1400 });
                getAllVideos()
                setLoadingDelete(false);
                setDeleteDialoge(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoadingDelete(false);
            });

    }



    return (
        <>
            <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px", xs: "25px" }, width: '100%', overflow: 'auto' }}>
                <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                        <Typography className='infromation' sx={{ mb: 2 }}>Upload Video</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={10} display={"flex"} justifyContent={"flex-end"}>
                        {/* <TextField
                            type='text'
                            sx={{ width: { lg: "250px", xs: "160px" }, mr: 1 }}
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img width={18} src={searchicon} alt='locationsvg' />
                                    </InputAdornment>
                                ),
                            }}
                            size='small' variant='outlined' color='error'
                        /> */}
                        <Stack direction={"row"} spacing={1}>
                            <Button
                                variant='contained'
                                sx={{
                                    mb: 1,
                                    textTransform: "capitalize",
                                    width: "140px",
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }}
                                onClick={handleUploadDialogeOpen}
                            >
                                Upload Video
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                    <Grid item xs={12}>
                        <Item>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                    <CircularProgress sx={{ color: "#F96064" }} />
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }}>
                                        <Table aria-label="customized table dense">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                                    <StyledTableCell align="left">Video</StyledTableCell>
                                                    <StyledTableCell align="left">Description</StyledTableCell>
                                                    <StyledTableCell align="left">Date</StyledTableCell>
                                                    <StyledTableCell align="left">Actions</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allVideos ? (
                                                    allVideos.results?.length > 0 ? (
                                                        allVideos?.results?.map((row, i) => (
                                                            <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                                <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                                    {startIndex + i + 1}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left"> <video width="100" height="150" controls>
                                                                    <source src={row.video} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video></StyledTableCell>

                                                                <StyledTableCell align="left">{row.description}</StyledTableCell>
                                                                <StyledTableCell align="left">{moment(row.created_at).format("llll")}</StyledTableCell>

                                                                <StyledTableCell>
                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                                        <Box>
                                                                            <img style={{ cursor: "pointer" }} src={editicon} alt="editicon" onClick={() => handleEditVideoDialog(row.id)} />
                                                                        </Box>
                                                                        <Box sx={{ color: "red", cursor: "pointer" }}>
                                                                            <DeleteOutlineOutlinedIcon onClick={() => handleDeleteDialoge(row.id)} />
                                                                        </Box>
                                                                    </Stack>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={12} align="center">
                                                                Data not found
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={12} align="center">
                                                            Loading...
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>


                            )}

                            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                                <Pagination
                                    count={Math.ceil(expensesListData.count / rowsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>

                        </Item>
                    </Grid>

                </Grid>

            </Box>


            <Dialog
                fullWidth
                maxWidth="xs"
                open={updateDialog}
                onClose={handleCloseUpdateDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleCloseUpdateDialog} /></Box>
                </DialogTitle>
                {loadingUpdate ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <form onSubmit={handleUpdateVideo}>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography className="addpartner" sx={{ mb: 4 }}>
                                        Upload Video
                                    </Typography>

                                    <CustomizedTextField
                                        sx={{ mb: 2 }}
                                        fullWidth
                                        variant="outlined"
                                        name="description"
                                        value={updateVideo?.description}
                                        onChange={handleDescriptionChange}
                                        size="small"
                                        placeholder="Description"
                                    />


                                    <CustomizedTextField
                                        sx={{ mb: 2 }}
                                        fullWidth
                                        variant="outlined"
                                        name="selectedVideo"
                                        value={updateVideo?.selectedVideo}
                                        onChange={handleDescriptionChange}
                                        size="small"
                                        placeholder="Selected Video"

                                    />

                                    <input
                                        accept="video/*"
                                        type="file"
                                        onChange={handleVideoChange}
                                        style={{ display: 'none' }}
                                        id="video-upload"
                                        required
                                    />
                                    <label htmlFor="video-upload">
                                        <Button
                                            variant="contained"
                                            component="span"
                                            sx={{ mb: 2 }}
                                            fullWidth
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Choose Video
                                        </Button>
                                    </label>

                                </DialogContentText>


                            </DialogContent>
                            <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                                <Button type='submit' variant='contained'
                                    sx={{
                                        backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                        color: 'white',
                                    }} fullWidth>
                                    Update Video

                                </Button>
                            </DialogActions>
                        </form>
                    </>
                )}
            </Dialog>


            <BootstrapDialog
                fullWidth
                maxWidth="xs"
                onClose={handleCloseDeleteDialog}
                open={deleteDialog}

            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }} id="customized-dialog-title">
                    Delete
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>Are you sure you want to delete?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' sx={{ color: "#F65255", textTransform: "capitalize" }} onClick={handleCloseDeleteDialog}>
                        Close
                    </Button>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                            color: 'white',
                            textTransform: "capitalize"
                        }}
                        onClick={handleDeleteVideo}
                        disabled={loadingDelete}
                    >
                        {loadingDelete ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Delete"}
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <Dialog fullWidth maxWidth="sm" open={imageDialoge} onClose={handleCloseImage}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <DialogTitle>Image Preview</DialogTitle>
                    <Box sx={{ cursor: "pointer", marginRight: "15px" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleCloseImage} /></Box>
                </Stack>
                <DialogContent>
                    {selectedImage && <img width="100%" height={"500"} src={selectedImage} alt="Preview" />}
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="xs"
                open={uploadVideodialoge}
                onClose={handleVideoDialogeClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={handleVideoUpload}>
                    <DialogTitle id="alert-dialog-title">
                        <Box
                            sx={{ cursor: "pointer" }}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            <CloseIcon sx={{ color: "#F54B4E" }} onClick={handleVideoDialogeClose} />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography className="addpartner" sx={{ mb: 4 }}>
                                Upload Video
                            </Typography>

                            <CustomizedTextField
                                sx={{ mb: 2 }}
                                fullWidth
                                variant="outlined"
                                name="description"
                                value={description}
                                onChange={handleDescriptionChange}
                                size="small"
                                placeholder="Description"
                            />

                            <input
                                accept="video/*"
                                type="file"
                                onChange={handleVideoChange}
                                style={{ display: 'none' }}
                                id="video-upload"
                                required
                            />
                            <label htmlFor="video-upload">
                                <Button
                                    variant="contained"
                                    component="span"
                                    sx={{ mb: 2 }}
                                    fullWidth
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Choose Video
                                </Button>
                            </label>
                        </DialogContentText>
                        {videoUrl && (
                            <video width="100%" height="200" controls>
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                backgroundImage:
                                    "linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)",
                                color: "white",
                            }}
                            fullWidth
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </>

    )
}

export default UploadVideo