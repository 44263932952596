
import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import backgroundimg from '../Assests/images/loginbg.png'
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthApi from '../apis/AuthApi';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Drawer, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';


const customTheme = createTheme({
    palette: {
        primary: {
            main: '#EDACD0',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    border: "none",
                    '&:hover': {
                        borderColor: 'transparent !important',
                    },
                },
                focused: {
                    '&:active': {
                        backgroundColor: 'transparent !important',
                    },
                },
                focused: {},
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E273AD',
                    '& input': {
                        color: 'white',
                    },
                    borderRadius: '8px',
                },
            },
        },
    },
});

const links = {
    payment_process: "Pyamnet Process",
    redeem_amount: "Redeem amount",
    payent_to_grocery_store: "Payment to grocery store",
    notification: "Notification",
    faq: "Faq",
    privacy_policy: "Privacy policy",
    about_us: "About us",
    affliate: "Afffiliate",
    promote_business: "Promote business",
    banner_add: "Banner add",
    non_affliated_store: "Non affliated store",
    earn_and_refrence: "Earn and refrence",
    Reward: "Reward"

}

const tabData = [
    'Payment process',
    'Redeem amount',
    'Payment to grocery store',
    'Notification',
    'Faq',
    'Privacy policy',
    "Terms and conditions",
    'About us',
    'Afffiliate',
    'Promote business',
    'Banner add',
    'Non affliated store',
    'Earn and refrence',
    'Reward',
    "User Mannual",
]

export default function Login() {
    const navigate = useNavigate();
    const [login, setLogin] = useState({});
    const [token, setToken] = useState("")
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [open, setOpen] = React.useState(false);


    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };



    const handleShowPasswordToggler = () => {
        setShowPassword(!showPassword);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setLogin((prevLogin) => ({
            ...prevLogin,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('password', login.password);
        formData.append('username', login.email);

        AuthApi.signin(formData)
            .then((response) => {
                console.log(response.response.data)
                setToken(response.response.access_token)
                localStorage.setItem('token', response.response.access_token);
                localStorage.setItem('username', response.response.data.username);
                localStorage.setItem('role', response.response.data.role);
                localStorage.setItem('is_admin', response.response.data.is_admin);
                toast.success("Login Successfully", { autoClose: 1400 });
                setLoading(false);
                if (response.response.data.is_admin.toString() === "true") {
                    navigate('/accounts');
                } else if (response.response.data.role === "Staff") {
                    navigate("/information")
                } else if (response.response.data.role === "HR") {
                    navigate("/expenses")
                }
            })
            .catch((e) => {
                if (e.response && e.response) {
                    toast.error(e.response.data.detail[0], { autoClose: 1400 });
                } else {
                    toast.error("Something went Wrong", { autoClose: 1400 });
                }
                setLoading(false);
            });
    };

    const handleClickLink = (text) => {
        navigate(`/help/${text}`);
    };

    const handleHelpSupport = () => {
        navigate('/help&support')
    }

    return (
        <div
            style={{
                backgroundImage: `url(${backgroundimg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <IconButton
                onClick={toggleDrawer(true)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ ml: 2 }}
            >
                <MenuIcon />
            </IconButton>

            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" >
                    <List>
                        {tabData.map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton onClick={() => handleClickLink(text)}>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleHelpSupport}>
                                <ListItemText primary={"Help & Support"} />
                            </ListItemButton>
                        </ListItem>
                    </List>

                </Box>

            </Drawer>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
                textAlign: "center",
                // backgroundImage: `url(${backgroundimg})`,
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
                minHeight: '100vh',
            }}>
                <ThemeProvider theme={customTheme} >
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: "center",
                                textAlign: "center",
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                minHeight: '100vh',
                            }}
                        >

                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0 }}>
                                <TextField
                                    value={login.email}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    // type='email'
                                    placeholder='Username '
                                    sx={{ border: 'none', "& fieldset": { border: 'none' }, }}

                                />
                                <TextField
                                    value={login.password}
                                    onChange={handleChange}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder='Password'
                                    sx={{ border: 'none', "& fieldset": { border: 'none' }, }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"
                                            onClick={handleShowPasswordToggler}
                                            onMouseDown={handleShowPasswordToggler}
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </InputAdornment>,
                                    }}


                                />

                                <LoadingButton
                                    fullWidth
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        padding: 1.7,
                                        color: "#FFFFFF",
                                        backgroundColor: '#EDACD0',
                                        boxShadow: "none",
                                        '&:hover': {
                                            backgroundColor: '#EDACD0',
                                            boxShadow: "none"
                                        },
                                    }}
                                    type="submit"
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    <span>Login</span>
                                </LoadingButton>

                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider >
            </div>

        </ div>
    );
}