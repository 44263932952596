import { Box, CircularProgress, Grid, Paper, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import chartimg from '../../../Assests/images/Chart.png'
import './index.css'
import Couponscard from './Couponscard';
import GrowthRate from './GrowthRate';
import UserCard from './UserCard';
import Testomonialcard from './Testomonialcard';
import PartnerTable from './PartnerTable';
import AffliatesTable from './AffliatesTable';
import InformationApi from '../../../apis/information';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProvincesApi from '../../../apis/provincesApi';
import PartnersApi from '../../../apis/PartnersApi';
import { Item } from '../../../style/commonStyle';

const InformationDashboard = () => {
    const navigate = useNavigate();
    const [informationData, setInformationData] = useState({})
    const [userGraphData, setUserGraphData] = useState({})
    const [provinces, setProvinces] = useState([])
    const [selectedprovince, setSelectedProvince] = useState("")
    const [date, setDate] = useState("")
    const [storeListData, setStoreListData] = useState({})
    const [growthRateData, setGrowthRateData] = useState({})
    const [storePerPage, setStorePerPage] = useState(1);
    const storelimit = 10;
    const [userStatus, setUserStatus] = useState({
        all: false,
        active: false
    })
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 100;


    useEffect(() => {
        getDashBoardInfromation()
    }, [date])

    useEffect(() => {
        getUserGraph()
    }, [date, userStatus, storelimit, storePerPage])

    useEffect(() => {
        getDashBoardInfromation()
        getUserGraph()
        getAllProvinces()
        getStoreList()
        getGrowthRate()
    }, [])


    const token = localStorage.getItem("token")

    const getStoreList = () => {
        setLoading(true);
        let payload = `?limit=${storelimit}&page=${storePerPage}`
        PartnersApi.getListStorePartners(payload, token)
            .then(response => {
                setStoreListData(response);
                setLoading(false);
            })
            .catch(error => {
                console.log("Error:", error);
            })
    };

    const getDashBoardInfromation = () => {
        setLoading(true);
        let paylaod = ""
        if (date) {
            paylaod = `?selected_date=${date}`
        }
        InformationApi.getInformation(paylaod, token)
            .then(response => {
                setInformationData(response);
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.detail, { autoClose: 1400 });
                    navigate("/");
                } else {
                    console.log("Error:", error);
                }
            })
    };


    const getGrowthRate = () => {
        setLoading(true);
        let paylaod = ""
        if (date) {
            paylaod = `?selected_date=${date}`
        }
        InformationApi.getGrowthRate(paylaod, token)
            .then(response => {
                setGrowthRateData(response);
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    toast.error(error.response.data.detail, { autoClose: 1400 });
                    navigate("/");
                } else {
                    console.log("Error:", error);
                }
            })
    };

    const getUserGraph = () => {
        setLoading(true);
        let payload = "";
        if (date) {
            payload = `?selected_date=${date}`;
        } if (userStatus.all) {
            payload = `?status=all`;
        } if (userStatus.active) {
            payload = `?status=active`;
        }

        InformationApi.getUserGraph(payload, token)
            .then(response => {
                console.log("Response:", response);
                if (response && response.user_graph_data && response.user_graph_data.arr_of_days && response.user_graph_data.arr_of_count_per_day) {
                    setUserGraphData(response);
                } else {
                    console.error("Invalid response format:", response);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching user graph data:", error);
                setLoading(false);
            });
    };


    const getAllProvinces = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        ProvincesApi.getAllProvincesList(payload, token)
            .then(response => {
                setProvinces(response.results);
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };



    return (
        <div className='maininformation'>
            <Typography className='infromation' sx={{ mb: 2 }}>Information</Typography>
            <Grid container direction={"row"} spacing={2}>
                <Grid item lg={2.4} md={4} sm={12} xs={12}>
                    <div class="totaluser">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Total users</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">{informationData.total_users}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </div>
                </Grid>

                <Grid item lg={2.4} md={4} sm={12} xs={12}>
                    <div className="provineces">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Provinces</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">{informationData.total_provinces}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </div>

                </Grid>

                <Grid item lg={2.4} md={4} sm={12} xs={12}>
                    <div className="activeuser">
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Active users</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">{informationData.total_active_users}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>

                    </div>
                </Grid>


                <Grid item lg={2.4} md={4} sm={12} xs={12}>
                    <Box className="partners"  sx={{ textDecoration: "none" }}>
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Partners</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">{informationData.partners}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </Box>
                </Grid>

                <Grid item lg={2.4} md={4} sm={12} xs={12}>
                    <Box className="totaluser"  sx={{ textDecoration: "none" }}>
                        <div className="cardtext">
                            <div>
                                <h3 className="totaltext">Affiliates</h3>
                            </div>
                            {loading ?
                                <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                                </Box> :
                                <div>
                                    <h2 className="count">{informationData.total_affiliates}</h2>
                                </div>
                            }
                        </div>
                        <div className="cardimg">
                            <img width="85" height="47" src={chartimg} alt="line" />
                        </div>
                    </Box>
                </Grid>

            </Grid>
            <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

                <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
                    <Grid item xs={12}>
                        <Item>
                            <Couponscard informationData={informationData} date={date} setDate={setDate} />
                        </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item>
                            <UserCard userGraphData={userGraphData} loading={loading} setSelectedProvince={setSelectedProvince} selectedprovince={selectedprovince} provinces={provinces} userStatus={userStatus} setUserStatus={setUserStatus} />
                        </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item>
                            <PartnerTable storeListData={storeListData} storelimit={storelimit} storePerPage={storePerPage} setStorePerPage={setStorePerPage} />
                        </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item>
                            <AffliatesTable />
                        </Item>
                    </Grid>
                </Grid>



                {/* Right Section */}
                <Grid item container xs={12} rowGap={2} sm={6} md={4} >
                    <Grid item xs={12}>
                        <Item> <GrowthRate growthRateData={growthRateData} date={date} setDate={setDate} />  </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item> <Testomonialcard /> </Item>
                    </Grid>

                </Grid>
            </Grid>
        </div >
    )
}

export default InformationDashboard