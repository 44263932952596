import React, { useState, useEffect } from 'react'
import bannerimg from '../../../Assests/images/bannerimg.png'
import CloseIcon from '@mui/icons-material/Close';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ReactFileReader from 'react-file-reader';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, styled } from '@mui/material'
import ExpensesApi from '../../../apis/ExpensesApi';
import { toast } from 'react-toastify';
import { CustomizedTextField } from '../../../style/commonStyle';


const ExpensesCard = () => {
    let token = localStorage.getItem("token")
    const [expensesdialogOpen, setExpensisDialogOpen] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState("select");
    const [listcountries, setListCountries] = useState([])
    const [expensesList, setExpensesList] = useState([])
    const [expensesListData, setExpensesListData] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [date, setDate] = useState("")
    let perpage = 1000;
    const rowsPerPage = 5;
    const [showSocialMediaDropdown, setShowSocialMediaDropdown] = useState(false);
    const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
    const [addexpenses, setAddExpenses] = useState({
        invoice_number: "",
        amount: "",
        description: "",
        file_name: "",
        payment_file: null,
        name: "",
        date: "",
        country: "",
        payment_type: "",
    });

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const uploadImage = (files) => {
        const file = files.fileList[0];
        setAddExpenses(prevState => ({
            ...prevState,
            file_name: file.name,
            payment_file: file,
            background_img: URL.createObjectURL(file),
        }));

    }
    const handleChangeDate = (e) => {
        setDate(e.target.value)
    }


    const getAllCountries = () => {
        setLoading(true);
        let payload = `?limit=${perpage}&page=${currentPage}`;
        ExpensesApi.getListCountries(payload, token)
            .then(response => {
                setListCountries(response.results)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };



    const handleExpensesChange = (event) => {
        const { name, value } = event.target;
        setAddExpenses(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddExpenses = (e) => {
        e.preventDefault();
        setLoadingCreate(true)
        const formData = new FormData();
        formData.append("name", addexpenses.name);
        formData.append("amount", addexpenses.amount);
        formData.append("payment_proof", addexpenses.payment_file);
        formData.append("category", selectedCategory);
        formData.append("description", addexpenses.description);
        formData.append("country", addexpenses.country);
        formData.append("bank_name", addexpenses.payment_type);
        formData.append("invoice_no", addexpenses.invoice_number);
        formData.append("updated_at", addexpenses.date);
        ExpensesApi.addExpenses(formData, token)
            .then(response => {
                toast.success("Expenses Add  Successfully", { autoClose: 1400 });
                getAllExpenses()
                setLoadingCreate(false);
                setExpensisDialogOpen(false);
                setAddExpenses({})
            })
            .catch(error => {
                if (error.response) {
                    const { data } = error.response;
                    Object.values(data).forEach(messages => {
                        messages.forEach(message => {
                            toast.error(message, { autoClose: 1400 });
                        });
                    });
                } else {
                    toast.error("Something went wrong.", { autoClose: 1400 });
                }
                console.error("Something went wrong:", error);
            });
    }

    const getAllExpenses = () => {
        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        if (date) {
            payload += `&selected_date=${date}`
        }
        ExpensesApi.getListExpeses(payload, token)
            .then(response => {
                setExpensesList(response.results)
                setExpensesListData(response)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    const handleSocialMediaChange = (event) => {
        setSelectedSocialMedia(event.target.value);
    };

    useEffect(() => {
        getAllCountries();
        getAllExpenses()
    }, [date])


    const handleClose = () => {
        setExpensisDialogOpen(false);
    };

    return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
            <div className="testimonialhead">
                <div>
                    <h3 className="growthrate">Expenses</h3>
                </div>
                <div>
                    <TextField type="date" name='date' value={date} onChange={handleChangeDate} sx={{
                        width: "120px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px", // Adjust this value as needed
                        }
                    }} />

                    <i className="fas fa-calendar"></i>
                </div>
            </div>

            <Grid container direction={"row"} alignItems={"center"} mt={2}>
                {expensesList ? (
                    expensesList.length > 0 ? (
                        expensesList.map((banner, i) => (
                            <React.Fragment key={banner.id}>
                                <Grid item xs={8}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box sx={{ borderRadius: "50%" }}>
                                            <img width={44} height={44} style={{ borderRadius: "50%" }} src={banner.payment_proof} alt="" />
                                        </Box>

                                        <Stack direction={"column"} spacing={0.5}>
                                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#000000", fontWeight: "500" }}>
                                                {banner.description}
                                            </Typography>
                                            <Typography sx={{ fontFamily: "Poppins", fontSize: "12px", color: "#949494", fontWeight: "500" }}>
                                                {banner.updated_at}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>

                                <Grid item xs={4} display={"flex"} justifyContent={"flex-end"}>
                                    <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#F44345", fontWeight: "500" }}>
                                        $ - {banner.amount}
                                    </Typography>
                                </Grid>

                                <Divider orientation='horizontal' sx={{ width: "100%", mt: 1, mb: 2 }} />

                            </React.Fragment>
                        ))
                    ) : (

                        <Typography>Data not found</Typography>

                    )
                ) : (
                    <Typography>
                        Loading...
                    </Typography>

                )}
                <Box sx={{ width: "100%", mt: 5, display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center" }}>
                    <Button variant='outlined' color='error' size='small' onClick={() => setExpensisDialogOpen(true)}> Add Expenses</Button>
                </Box>




            </Grid>

            {/* <Dialog
                fullWidth
                maxWidth="xs"
                open={expensesdialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                </DialogTitle>
                {loadingCreate ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography className='addpartner' sx={{ mb: 4 }}>Add expenses</Typography>

                                <Stack direction={"column"} spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                        <Select
                                            name='category'
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            size='small'
                                            label="Category"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value="select" disabled>
                                                Select Category
                                            </MenuItem>
                                            <MenuItem value="office_expenses">Office expenses</MenuItem>
                                            <MenuItem value="salaries">salaries</MenuItem>
                                            <MenuItem value="traveling">traveling</MenuItem>
                                            <MenuItem value="miscellaneous">miscellaneous</MenuItem>

                                        </Select>
                                    </FormControl>
                                    <CustomizedTextField name="name" label="name" value={addexpenses.name} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Your Name here' />
                                    <CustomizedTextField name="invoice_number" label="invoice no" value={addexpenses.invoice_number} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Invoice number' />
                                    <CustomizedTextField name="amount" label="amount" value={addexpenses.amount} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Type amount' />
                                    <CustomizedTextField name="description" label="description" value={addexpenses.description} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Description' />
                                    <CustomizedTextField name="date" label="date" value={addexpenses.date} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' type='date' placeholder='Date' />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">country</InputLabel>
                                        <Select
                                            name='country'
                                            value={addexpenses.country}
                                            onChange={handleExpensesChange}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label='country'
                                            className='province-select'
                                            displayEmpty

                                        >
                                            {
                                                listcountries.map((item) => (
                                                    <MenuItem value={item.country_name}>{item.country_name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Payment Type</InputLabel>
                                        <Select
                                            name='payment_type'
                                            value={addexpenses.payment_type}
                                            onChange={handleExpensesChange}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label='Select Payment Type'
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value={addexpenses.payment_type} disabled>
                                                Select Payment Type
                                            </MenuItem>
                                            <MenuItem value="westrenunion">Western Union</MenuItem>
                                            <MenuItem value="local">Local</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        id="upload-payment"
                                        className='outlinedinput'
                                        value={addexpenses.file_name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadImage}>
                                                        <IconButton aria-label="upload payment proof">
                                                            <InsertPhotoOutlinedIcon />
                                                        </IconButton>
                                                    </ReactFileReader>
                                                </InputAdornment>
                                            ),
                                        }} fullWidth variant='outlined' size='small' label="upload payment" placeholder='Upload payment proof' />
                                </Stack>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                            <Button onClick={handleAddExpenses} variant='contained'
                                sx={{
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }} fullWidth>
                                Add expenses

                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog> */}

            <Dialog
                fullWidth
                maxWidth="xs"
                open={expensesdialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{ cursor: "pointer" }} display={"flex"} justifyContent={"flex-end"}><CloseIcon sx={{ color: "#F54B4E" }} onClick={handleClose} /></Box>
                </DialogTitle>
                {loadingCreate ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress sx={{ color: "#F96064" }} />
                    </Box>
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography className='addpartner' sx={{ mb: 4 }}>Add Expenses</Typography>

                                <Stack direction={"column"} spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                        <Select
                                            name='category'
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            size='small'
                                            label="Category"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value="select" disabled>
                                                Select Category
                                            </MenuItem>
                                            <MenuItem value="office_expenses">Office expenses</MenuItem>
                                            <MenuItem value="salaries">Salaries</MenuItem>
                                            <MenuItem value="traveling">Traveling</MenuItem>
                                            <MenuItem value="miscellaneous">Miscellaneous</MenuItem>
                                            <MenuItem value="advertisement">Advertisement</MenuItem>
                                            <MenuItem value="server">Server</MenuItem>
                                            <MenuItem value="domain">Domain</MenuItem>

                                        </Select>
                                        {showSocialMediaDropdown && (
                                            <FormControl fullWidth sx={{ mt: 2 }} >
                                                <InputLabel id="social-media-select-label">Social Media</InputLabel>

                                                <Select

                                                    name='socialMedia'
                                                    value={selectedSocialMedia}
                                                    onChange={handleSocialMediaChange}
                                                    size='small'
                                                    label="Social Media"
                                                    labelId="social-media-select-label"
                                                    id="social-media-select"
                                                    className='province-select'
                                                    displayEmpty
                                                >
                                                    <MenuItem value="facebook">Facebook</MenuItem>
                                                    <MenuItem value="twitter">Twitter</MenuItem>
                                                    <MenuItem value="instagram">Instagram</MenuItem>
                                                    <MenuItem value="linkedin">LinkedIn</MenuItem>
                                                    <MenuItem value="snapchat">Snapchat</MenuItem>
                                                    <MenuItem value="pinterest">Pinterest</MenuItem>
                                                    <MenuItem value="tiktok">TikTok</MenuItem>
                                                    <MenuItem value="reddit">Reddit</MenuItem>
                                                    <MenuItem value="youtube">YouTube</MenuItem>
                                                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    </FormControl>
                                    <CustomizedTextField name="name" label="Name" value={addexpenses.name} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Your Name here' />
                                    <CustomizedTextField name="invoice_number" label="Invoice no" value={addexpenses.invoice_number} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Invoice number' />
                                    <CustomizedTextField name="amount" label="Amount" value={addexpenses.amount} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Type amount' />
                                    <CustomizedTextField name="description" label="Description" value={addexpenses.description} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' placeholder='Description' />
                                    <CustomizedTextField name="date" label="Date" value={addexpenses.date || ""} onChange={handleExpensesChange} fullWidth variant='outlined' size='small' type='date' />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                        <Select
                                            name='country'
                                            value={addexpenses.country}
                                            onChange={handleExpensesChange}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label='Country'
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value={addexpenses.country} disabled>
                                                Select Country
                                            </MenuItem>
                                            <MenuItem value="canada">Canada</MenuItem>
                                            <MenuItem value="india">India</MenuItem>
                                            <MenuItem value="pakistan">Pakistan</MenuItem>


                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Payment Type</InputLabel>
                                        <Select
                                            name='payment_type'
                                            value={addexpenses.payment_type}
                                            onChange={handleExpensesChange}
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label='Select Payment Type'
                                            className='province-select'
                                            displayEmpty

                                        >
                                            <MenuItem value={addexpenses.payment_type} disabled>
                                                Select Payment Type
                                            </MenuItem>
                                            <MenuItem value="westrenunion">Western Union</MenuItem>
                                            <MenuItem value="local">Local</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        id="upload-payment"
                                        className='outlinedinput'
                                        value={addexpenses.file_name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} base64={true} handleFiles={uploadImage}>
                                                        <IconButton aria-label="upload payment proof">
                                                            <InsertPhotoOutlinedIcon />
                                                        </IconButton>
                                                    </ReactFileReader>
                                                </InputAdornment>
                                            ),
                                        }} fullWidth variant='outlined' size='small' label="Upload payment" placeholder='Upload payment proof' />
                                </Stack>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ padding: "20px", mb: "15px" }}>
                            <Button onClick={handleAddExpenses} variant='contained'
                                sx={{
                                    backgroundImage: 'linear-gradient(360deg, #F33F41 0%, #FB6D72 95%)',
                                    color: 'white',
                                }} fullWidth>
                                Add expenses

                            </Button>
                        </DialogActions>
                    </>
                )}

            </Dialog>

        </div>
    )
}

export default ExpensesCard