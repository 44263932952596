import Axios from "axios";
import { backendUrl } from "../utils";


let getRewardsApi = "/dashboard/listUserReward";




const RewardApi = {

    getAllRewards(params, token) {
        return Axios({
            url: backendUrl + getRewardsApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

}

export default RewardApi;
