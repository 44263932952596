// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-box {
    margin-left: 245px;
    margin-top: 100px;
}


@media screen and (max-width: 660px) {
    .main-box {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 80px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/pages.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;;AAGA;IACI;QACI,iBAAiB;QACjB,kBAAkB;QAClB,gBAAgB;IACpB;AACJ","sourcesContent":[".main-box {\n    margin-left: 245px;\n    margin-top: 100px;\n}\n\n\n@media screen and (max-width: 660px) {\n    .main-box {\n        margin-left: 15px;\n        margin-right: 15px;\n        margin-top: 80px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
