import { Grid, Paper, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AdRevinue from './AdRevinue';
import AdGrowthRateChart from './AdGrowthRateChart';
import BannersActivityCard from './BannersActivityCard';
import AdvertisementsCard from './AdvertisementsCard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdsApi from '../../../apis/AdsApi';
import ProvincesApi from '../../../apis/provincesApi';
import { Item } from '../../../style/commonStyle';

const ADs = () => {

  const navigate = useNavigate()
  const [revenueData, setAdsRevenueData] = useState({})
  const [growthRateData, setGrowthRateData] = useState({})
  const [advertisementData, setAdvertisementData] = useState({});
  const [advertisement, setAdvertisement] = useState([]);
  const [growthRateDate, setGrowthRateDate] = useState("")
  const [date, setDate] = useState("")
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 1000;
  const [Page, setPage] = useState(1);
  const PerPage = 30;
  const [selectedprovince, setSelectedProvince] = useState("")
  const [provinces, setProvinces] = useState([])
  const [advertisementDate, setAdvertisementDate] = useState("")



  useEffect(() => {
    getAdsRevenue()
  }, [date])

  useEffect(() => {
    getGrowthRate()
    getUserAdsList();
  }, [growthRateDate, advertisementDate, selectedprovince, PerPage, Page])

  useEffect(() => {
    getAdsRevenue()
    getGrowthRate()
    getAllProvinces()
    getUserAdsList();
  }, [])

  const token = localStorage.getItem("token")

  const getAdsRevenue = () => {
    setLoading(true);
    let payload = ""
    if (date) {
      payload = `?selected_date=${date}`
    }
    AdsApi.getAdsRevenue(payload, token)
      .then(response => {
        setAdsRevenueData(response);
        setLoading(false);
      })
      .catch(error => {
        console.log("Error:", error);
      })
  };

  const getGrowthRate = () => {
    setLoading(true);
    let paylaod = ""
    if (growthRateDate) {
      paylaod = `?selected_date=${growthRateDate}`
    }
    AdsApi.getAdsGrowthRate(paylaod, token)
      .then(response => {
        setGrowthRateData(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      })
  };


  const getUserAdsList = () => {
    setLoading(true);
    let paylaod = ""
    if (advertisementDate || selectedprovince) {
      paylaod = `?selected_date=${advertisementDate}&limit=${PerPage}&page=${Page}&province=${selectedprovince}`
    }
    AdsApi.getUserAdsList(paylaod, token)
      .then(response => {
        setAdvertisementData(response);
        setAdvertisement(response.results)
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      })
  };


  const getAllProvinces = () => {
    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };

  return (
    <div className='maininformation'>
      <Typography className='infromation' sx={{ mb: 2 }}>Ads</Typography>

      <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

        <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
          <Grid item xs={12}>
            <Item>
              <AdRevinue revenueData={revenueData} date={date} setDate={setDate} />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <AdvertisementsCard advertisement={advertisement} advertisementData={advertisementData} advertisementDate={advertisementDate} setAdvertisementDate={setAdvertisementDate} Page={Page} setPage={setPage} PerPage={PerPage} provinces={provinces} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} />
            </Item>
          </Grid>

          {/* <Grid item xs={12}>
            <Item>
              <PartnerTable />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <AffliatesTable />
            </Item>
          </Grid> */}
        </Grid>



        {/* Right Section */}
        <Grid item container xs={12} rowGap={2} sm={6} md={4} >
          <Grid item xs={12}>
            <Item> <AdGrowthRateChart growthRateData={growthRateData} growthRateDate={growthRateDate} setGrowthRateDate={setGrowthRateDate} />  </Item>
          </Grid>

          <Grid item xs={12}>
            <Item> <BannersActivityCard /> </Item>
          </Grid>

        </Grid>
      </Grid>


    </div>
  )
}

export default ADs