import Axios from "axios";
import { backendUrl } from "../utils";


let getAccountsApi = "/dashboard/accounts";
let getRevenueGraphApi = '/dashboard/adsRevnueGraph'
let getGiftDistributionApi = "/dashboard/giftDistributiongraph"
let getPaymentGraphApi = "/dashboard/paymentGraph"
let getAccountGrowthRate = "/dashboard/partnerGrowthRate"







const AccountsApi = {

    getAccounts(params, token) {
        // const { token } = useContext(AuthContext);
        return Axios({
            url: backendUrl + getAccountsApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getRevenue(params, token) {
        return Axios({
            url: backendUrl + getRevenueGraphApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    getGiftDistribution(params, token) {
        return Axios({
            url: backendUrl + getGiftDistributionApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getPaymentsGraph(params, token) {
        return Axios({
            url: backendUrl + getPaymentGraphApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },


    getAccountGrowthRateGraph(params, token) {
        return Axios({
            url: backendUrl + getAccountGrowthRate + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },

    



    // postTradingLevel1(params, token) {
    //     return Axios({
    //         url: backendUrl + tradinglevel1API,
    //         method: 'post',
    //         data: params,
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //         }

    //     }).then(response => {
    //         return response.data
    //     })
    // },


}

export default AccountsApi;
