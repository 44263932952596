import { Button, Grid, Paper, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import chartimg from '../../../../Assests/images/Chart.png'
import '../index.css'

import TotalExpenses from './TotalExpenses';
import TransactionsCard from './TransactionsCard';
import ExpenseRate from './ExpenseRate';
import ExpensesCard from './ExpensesCard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaymentsApi from '../../../../apis/PaymentsApi';
import ProvincesApi from '../../../../apis/provincesApi';
import { Item } from '../../../../style/commonStyle';


const PartnersSettlements = ({ addpartner, setAddPartners }) => {
  const navigate = useNavigate()
  const [totalExpensesData, setTotalExpenseData] = useState({});
  const [expensesRateData, setExpenseERateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [adsExpenseGrowthGraphDate, setAdsExpenseGrowthGraphDate] = useState(null)
  const [expenses, setExpenses] = useState("");
  const [expenseRateDate, setExpenseRateDate] = useState(null)
  const [provinces, setProvinces] = useState([])
  const [selectedprovince, setSelectedProvince] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const limits = 1000;
  const [paymentsList, setPaymentsList] = useState([])
  const [transactiondate, setTransactionDate] = useState("")
  const [Page, setPage] = useState(1);
  const rowsPerPage = 30;



  const token = localStorage.getItem("token")

  useEffect(() => {
    getExpenseRateSettlement()
    getExepenserateGrowthGraph()
    getAllProvinces()
    getPaymentsLists()
  }, [expenseRateDate, selectedprovince, adsExpenseGrowthGraphDate, transactiondate])




  const getAllProvinces = () => {
    setLoading(true);
    let payload = `?limit=${limits}&page=${currentPage}`;
    ProvincesApi.getAllProvincesList(payload, token)
      .then(response => {
        setProvinces(response.results);
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };


  const getExpenseRateSettlement = () => {
    setLoading(true);
    let paylaod = ""
    if (expenseRateDate || selectedprovince) {
      paylaod = `?selected_date=${expenseRateDate}&province=${selectedprovince}`
    }
    PaymentsApi.getsettlementExpenseRate(paylaod, token)
      .then(response => {
        setTotalExpenseData(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      })
  };

  const getExepenserateGrowthGraph = () => {
    setLoading(true);
    let paylaod = ""
    if (adsExpenseGrowthGraphDate || expenses) {
      paylaod = `?selected_date=${adsExpenseGrowthGraphDate}&expanse_type=${expenses}`
    }
    PaymentsApi.getexpenseRateGrowthApi(paylaod, token)
      .then(response => {
        setExpenseERateData(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Error:", error);
        }
      })
  };


  const getPaymentsLists = () => {
    setLoading(true);
    let payload = ""
    if (transactiondate || selectedprovince) {
      payload = `?limit=${rowsPerPage}&selected_date=${transactiondate}&province=${selectedprovince}`
    }
    PaymentsApi.getPaymentsList(payload, token)
      .then(response => {
        setPaymentsList(response);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail, { autoClose: 1400 });
          navigate("/");
        } else {
          console.log("Somthing went Wrong", error);
        }
      })
  };

  return (
    <div className='maininformation'>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography className='infromation' sx={{ mb: 2 }}>Partner settlements</Typography>
        <Stack direction={"row"} spacing={2}>
          <Button variant='outlined' color='error'  sx={{ textTransform: "capitalize",width:"120px" }} onClick={() => setAddPartners(false)}>Payments</Button>
          <Button variant='contained' color='error'  sx={{ textTransform: "capitalize",width:"120px" }}>Add Partners</Button>
        </Stack>

      </Stack>
      <Grid container justifyContent={'space-between'} mt={2} spacing={2} alignItems='flex-start'>

        <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={8} >
          <Grid item xs={12}>
            <Item>
              <TotalExpenses totalExpenseData={totalExpensesData} provinces={provinces} expenseRateDate={expenseRateDate} setExpenseRateDate={setExpenseRateDate} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} />
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item>
              <TransactionsCard provinces={provinces} transactiondate={transactiondate} setTransactionDate={setTransactionDate} selectedprovince={selectedprovince} setSelectedProvince={setSelectedProvince} paymentsList={paymentsList} Page={Page} rowsPerPage={rowsPerPage} setPage={setPage} />
            </Item>
          </Grid>


        </Grid>


        {/* Right Section */}
        <Grid item container xs={12} rowGap={2} sm={6} md={4} >
          <Grid item xs={12}>
            <Item> <ExpenseRate expenses={expenses} setExpenses={setExpenses} expensesRateData={expensesRateData} adsExpenseGrowthGraphDate={adsExpenseGrowthGraphDate} setAdsExpenseGrowthGraphDate={setAdsExpenseGrowthGraphDate} /> </Item>
          </Grid>

          <Grid item xs={12}>
            <Item> <ExpensesCard /> </Item>
          </Grid>

        </Grid>
      </Grid>




    </div >
  )
}

export default PartnersSettlements