import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CoupenApi from '../../../apis/couponsApi';
import TablePagination from '../../TablePagination';
import Slots from './Slots';
import { Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const UserSlots = ({ setShowSingleSlot, userSlotID }) => {
    const [userSlotData, setUsersSlotData] = useState([]);
    const [usersSlot, setUsersSlot] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const rowsPerPage = 20;
    const [showSlot, setShowSlot] = useState(false)
    const [slotId, setSlotID] = useState(null)
    const [userID, setUserID] = useState(null)
   
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const token = localStorage.getItem("token")


    const getAllUsersSlots = () => {
        setLoading(true);
        let payload = `/${userSlotID}?limit=${rowsPerPage}&page=${currentPage}`;
        CoupenApi.getSlotUsers(payload, token)
            .then(response => {
                setUsersSlotData(response.results);
                setUsersSlot(response)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    const handleSlots = (bet_id, user_id) => {
        setSlotID(bet_id);
        setUserID(user_id)
        setShowSlot(true)
    }

    useEffect(() => {
        getAllUsersSlots()
    }, [currentPage, rowsPerPage])

    const startIndex = (currentPage - 1) * rowsPerPage;
    return (
        <>
            {showSlot ?
                <Slots setShowSlot={setShowSlot} userID={userID} slotId={slotId} />
                :
                <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: { lg: "20px !important", xs: "100px" }, width: '100%', overflow: 'auto' }}>

                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className='infromation' sx={{ mb: 2 }}>Slot users</Typography>
                        <Button variant="outlined" color='error' size='small' sx={{ mb: 2 }} onClick={() => setShowSingleSlot(false)}>Back</Button>
                    </Stack>
                    <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                        <Grid item xs={12}>
                            <Item>
                                {loading ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                        <CircularProgress sx={{ color: "#F96064" }} />
                                    </Box>
                                ) : (
                                    <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                        <Table aria-label="customized table dense">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                                                    <StyledTableCell align="left">Title(coupon)</StyledTableCell>
                                                    <StyledTableCell align="left">Full name</StyledTableCell>
                                                    <StyledTableCell align="left">Email</StyledTableCell>
                                                    <StyledTableCell align="left">Province</StyledTableCell>
                                                    <StyledTableCell align="left">Slots</StyledTableCell>
                                                    <StyledTableCell align="left">View</StyledTableCell>    
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userSlotData && userSlotData.length > 0 ? (
                                                    userSlotData.map((row, i) => (
                                                        <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                                {startIndex + i + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.bet.title}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.user.full_name}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.user.email}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.province}</StyledTableCell>
                                                            <StyledTableCell>{row.count}</StyledTableCell>
                                                            <StyledTableCell align="left" sx={{ paddingRight: 0 }}>
                                                                <span style={{ cursor: "pointer" }} onClick={() => handleSlots(row.bet.id, row.user.id)}>
                                                                    <VisibilityIcon color='primary' />
                                                                </span>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <StyledTableCell colSpan={7} align="center">
                                                            No data found
                                                        </StyledTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}

                                <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                                    <TablePagination
                                        totalItems={usersSlot}
                                        itemsPerPage={rowsPerPage}
                                        page={currentPage}
                                        onPageChange={handlePageChange}
                                    />
                                </Box>
                            </Item>
                        </Grid>

                    </Grid>

                </Box>
            }
        </>

    )
}

export default UserSlots