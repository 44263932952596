import React, { useEffect, useState } from 'react'
import pdficon from '../../../Assests/images/pdf.png'
import ReactApexChart from 'react-apexcharts';
import 'apexcharts';
import { TextField } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const GrowthRateGraph = ({ accountGrowthGraph, accountGrowthDate, setAccountGrowthDate }) => {

    const [totalPercentage, setTotalPercentage] = useState(0);
    useEffect(() => {
        if (accountGrowthGraph?.growth_rate_data?.growth_rate_count) {
            const percentages = accountGrowthGraph?.growth_rate_data?.growth_rate_count;
            const total = percentages.reduce((acc, percent) => acc + percent, 0);
            const cappedTotal = Math.min(total, 100);

            setTotalPercentage(cappedTotal);
        }
    }, [accountGrowthGraph]);

    const remainingPercentage = totalPercentage;

    var options = {
        chart: {
            type: 'donut'
        },

        plotOptions: {
            pie: {
                expandOnClick: true,

            },

        },

        series: accountGrowthGraph?.growth_rate_data?.growth_rate_count ?? [],
        chartOptions: {
            labels: []
        },
        tooltip: {
            enabled: false
        },
        legend: {
            show: false
        }

    }

    const HandleDateChange = (e) => {
        setAccountGrowthDate(e.target.value)
    }

    const generatePDF = () => {
        const doc = new jsPDF();

        // Add title
        doc.setFontSize(18);
        doc.text('Growth Rate Report', 10, 10);

        // Add growth rate data table
        const growthRateData = accountGrowthGraph?.growth_rate_data?.growth_rate_count || [];
        const tableData = growthRateData.map((value, index) => [index + 1, `${value}`]);

        doc.autoTable({
            startY: 20,
            head: [['#', 'Growth Rate']],
            body: tableData,
        });

        // Save the PDF
        doc.save('growth_rate_report.pdf');
    }



    return (
        <div>
            <div className="rightpie-div">
                <div>
                    <h3 className="growthrate">Growth rate</h3>
                </div>
                <div className="calender-div">
                    <div style={{ marginRight: "10px" }} onClick={generatePDF}>
                        <img src={pdficon} alt="img" />
                    </div>
                    <TextField type="date" name='accountGrowthDate' onChange={HandleDateChange} value={accountGrowthDate} sx={{
                        width: "140px",
                        border: "none",
                        outline: "none",
                        "& .MuiInputBase-input": {
                            padding: "5px 5px",
                        }
                    }} />
                </div>

            </div>


            <div id="chart">
                <ReactApexChart options={options} series={options.series} type="donut" height={240} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: 'auto' }}>
                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>0%</p>
                </div>

                <div>
                    <p style={{ fontSize: '14px', color: '#808080' }}>{remainingPercentage}%</p>
                </div>
            </div>

            <h6 class="coupensold">{accountGrowthGraph?.coupans_str}</h6>
        </div >
    )
}

export default GrowthRateGraph