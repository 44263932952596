import Axios from "axios";
import { backendUrl } from "../utils";


let getTotalWalletsApi = "/dashboard/listWallets";




const UserWalletApi = {

    getTotalWallets(params, token) {
        return Axios({
            url: backendUrl + getTotalWalletsApi + params,
            method: 'get',
            headers: {
                'content-type': 'application/json',
                Authorization: `token ${token}`,
            }
        }).then(response => {
            return response.data
        })
    },




    // postTradingLevel1(params, token) {
    //     return Axios({
    //         url: backendUrl + tradinglevel1API,
    //         method: 'post',
    //         data: params,
    //         headers: {
    //             'content-type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //         }

    //     }).then(response => {
    //         return response.data
    //     })
    // },


}

export default UserWalletApi;
