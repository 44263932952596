import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Container, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom';
import FaqsApi from '../apis/FaqsApi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './helppage.css'

const HelpPage = () => {
    const { helpTopic } = useParams();
    const [editorHtml, setEditorHtml] = useState('');
    console.log("editorHtml*************", editorHtml)
    const [loading, setLoading] = useState(false)


    const theme = useTheme();
    const isXsOrSmScreen = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        getGeneralApi()
    }, [])


    const getGeneralApi = () => {
        setLoading(true);
        let payload = `?text_type=${helpTopic}`
        FaqsApi.getGeneralSetting(payload)
            .then(response => {
                setEditorHtml(response.text);
                setLoading(false);
            })
            .catch(error => {
                console.log("Error:", error);
            })
    };
    const modules = {
        toolbar: false,
    };

    return (
        <Box sx={{ padding: { lg: "20px", xs: "5px" } }}>
            <Container >
                <Paper elevation={3} sx={{ padding: { lg: "40px", xs: "10px" } }}>
                    {loading ? (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <CircularProgress color='primary' />
                        </Box>
                    ) : (
                        <Box px={isXsOrSmScreen ? 0 : 8} py={isXsOrSmScreen ? 0 : 4}>
                            {editorHtml === undefined ? ( // Check if editorHtml is undefined
                                <Typography variant="body1" sx={{textAlign:"center"}}>No data found</Typography>
                            ) : (
                                <ReactQuill
                                    theme="snow"
                                    value={editorHtml}
                                    readOnly={true}
                                    modules={modules}
                                    className="no-border"
                                />
                            )}
                        </Box>
                    )}
                </Paper>
            </Container>
        </Box >
    )
}

export default HelpPage