import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Paper, Stack, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import GiftApi from '../../../apis/gifts';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';



const Gifts = () => {
  const [giftList, setGiftList] = useState([])
  const [historyData, setGiftHistoryData] = useState([])
  const [historyloading, setHistoryLoading] = useState(false)
  const [giftListData, setGiftListData] = useState({})
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const rowsPerPage = 40;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


  const handleBalanceDialoge = (row) => {
    setSelectedRow(row.email); // Store the selected row data
    setIsDialogOpen(true); // Open the dialog
  };

  const startIndex = (currentPage - 1) * rowsPerPage;

  useEffect(() => {
    getAllGifts();
    if (selectedRow) {
      getGiftsHistory()
    }
  }, [rowsPerPage, currentPage, selectedRow])


  let token = localStorage.getItem("token")
  const getAllGifts = () => {

    setLoading(true);
    let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
    GiftApi.getGiftLists(payload, token)
      .then(response => {
        setGiftList(response.results)
        setGiftListData(response)
        setLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };

  const getGiftsHistory = () => {
    setHistoryLoading(true);
    let payload = `?email=${selectedRow}`;
    GiftApi.getGiftHistory(payload, token)
      .then(response => {
        console.log(response)
        setGiftHistoryData(response)
        setHistoryLoading(false);
      })
      .catch(error => {
        console.error("Something went wrong:", error);
        setLoading(false);
      });
  };


  return (
    <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: "20px !important", width: '100%', overflow: 'auto' }}>
      <Typography className='infromation' sx={{ mb: 2 }}>Gift Wallet</Typography>

      <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
        <Grid item xs={12}>
          <Item>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress sx={{ color: "#F96064" }} />
              </Box>
            ) : (
              <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                <Table aria-label="customized table dense">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>
                      <StyledTableCell align="left">Full Name</StyledTableCell>
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="center">Balance</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {giftList ? (
                      giftList.length > 0 ? (
                        giftList.map((row, i) => (
                          <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                            <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                              {startIndex + i + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.fullname}</StyledTableCell>
                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                            <StyledTableCell align="center" sx={{ cursor: "pointer" }} onClick={() => handleBalanceDialoge(row)}>{row.balance}</StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            Data not found
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    )}

                  </TableBody>

                </Table>
              </TableContainer>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
              <Pagination
                count={Math.ceil(giftListData.count / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Item>
        </Grid>

      </Grid>

      <Dialog fullWidth maxWidth="lg" open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
          <DialogTitle>Balance Details</DialogTitle>
          <CloseIcon sx={{ color: "#F54B4E", marginRight: "20px" }} onClick={() => setIsDialogOpen(false)} />
        </Stack>
        <DialogContent>
          {historyloading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <CircularProgress sx={{ color: "#F96064" }} />
            </Box>
          ) : (
            <TableContainer >
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ paddingLeft: 3, width: "20px" }}>Sr#</StyledTableCell>
                    <StyledTableCell align="left">Full Name</StyledTableCell>
                    <StyledTableCell align="left">Balance</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Date</StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyData ? (
                    historyData.length > 0 ? (
                      historyData.map((row, i) => (
                        <StyledTableRow key={i} sx={{ alignItems: "center" }}>
                          <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                            {startIndex + i + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left" width={""}>{row.user.full_name}</StyledTableCell>
                          <StyledTableCell align="left" sx={{ cursor: "pointer", width: "20%", color: row.payment_status === "Remove" ? "#d73838 !important" : "green !important" }}>{row.payment_status === "Remove" ? "-" : "+"}{row.amount}</StyledTableCell>
                          <StyledTableCell align="left">{selectedRow}</StyledTableCell>
                          <StyledTableCell align="left"> {moment(row.created_at).format('llll')}</StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          Data not found
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>

    </Box>
  )
}

export default Gifts