import { Box, CircularProgress, Grid, Paper, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import GiftApi from '../../../apis/gifts';
import BankPageApi from '../../../apis/BankPageApi';
import moment from 'moment';
import { Item, StyledTableCell, StyledTableRow } from '../../../style/commonStyle';

const UserBank = () => {
    const [userBankDetailList, setUserBankDetailList] = useState([])
    const [userBankListData, setUserBankListData] = useState({})
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 40;

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };



    const startIndex = (currentPage - 1) * rowsPerPage;

    useEffect(() => {
        getAllUserBankDetail()
    }, [rowsPerPage, currentPage])


    let token = localStorage.getItem("token")
    const getAllUserBankDetail = () => {

        setLoading(true);
        let payload = `?limit=${rowsPerPage}&page=${currentPage}`;
        BankPageApi.getUserBankDetail(payload, token)
            .then(response => {
                setUserBankDetailList(response.results)
                setUserBankListData(response)
                setLoading(false);
            })
            .catch(error => {
                console.error("Something went wrong:", error);
                setLoading(false);
            });
    };

    return (
        <Box sx={{ padding: { lg: "25px", xs: "0px" }, paddingTop: "20px !important", width: '100%', overflow: 'auto' }}>
            <Typography className='infromation' sx={{ mb: 2 }}>Bank Detail</Typography>

            <Grid container item justifyContent={'space-between'} rowGap={2} xs={12} sm={12} md={12} >
                <Grid item xs={12}>
                    <Item>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <CircularProgress sx={{ color: "#F96064" }} />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} elevation={0} scroll className='dbTable' style={{ width: '100%', overflow: 'auto' }} >
                                <Table aria-label="customized table dense">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ paddingLeft: 3 }}>Sr#</StyledTableCell>

                                            <StyledTableCell align="left">Account Number</StyledTableCell>
                                            <StyledTableCell align="left">Transit Number</StyledTableCell>
                                            <StyledTableCell align="left">Insitution Number</StyledTableCell>
                                            <StyledTableCell align="left">Interac e-Transfer ID</StyledTableCell>
                                            <StyledTableCell align="left">Address</StyledTableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userBankDetailList ? (
                                            userBankDetailList.length > 0 ? (
                                                userBankDetailList.map((row, i) => (
                                                    <StyledTableRow key={i} sx={{ width: "100%", alignItems: "center" }}>
                                                        <StyledTableCell scope="row" sx={{ textTransform: 'capitalize', paddingLeft: 3 }}>
                                                            {startIndex + i + 1}
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">{row.account_no}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.transit_number}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.institution_number ? row.institution_number : "N/A"}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.email?row.email:"N/A"}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.address}</StyledTableCell>
                                                    
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <TableRow sx={{ width: "100%", alignItems: "center" }}>
                                                    <TableCell colSpan={4} align="center">
                                                        Data not found
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    Loading...
                                                </TableCell>
                                            </TableRow>
                                        )}

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        )}
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
                            <Pagination
                                count={Math.ceil(userBankListData.count / rowsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Box>
                    </Item>
                </Grid>

            </Grid>

        </Box>
    )
}

export default UserBank